import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RuleItem from './RuleItem';
import { ReactComponent as DarkNotificationBackground } from '../../assets/images/smart-notification-dark.svg';
import { ReactComponent as NotificationBackground } from '../../assets/images/smart-notification.svg';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { DELETE_ALERT_RULE_BY_ID } from '../../graphql/mutations/alerts';
import { ALERT_RULES } from '../../graphql/queries/alerts';
import {
  defaultModalValue,
  ModalType,
} from '../../typescript/components/modal';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import { AlertProps } from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';
import { useTracker } from '../../utils/Tracker';
import { Loader } from '../elements/Loader';
import ConfirmationModal from '../modal/ConfirmationModal';

const ManageTable = function ManageTable() {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { trackDeleteAlerts } = useTracker();
  const { onTokenSave } = useContext(AuthContext) as AuthContextType;

  const { loading, error, data, refetch } = useQuery(ALERT_RULES, {
    fetchPolicy: 'network-only',
  });
  const [deleteObservation, deleteActions] = useMutation(
    DELETE_ALERT_RULE_BY_ID,
  );

  const [seletedAlert, setSeletedAlert] = useState<AlertProps | undefined>();

  // Confirmation Modal
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);

  // Navigate
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.message === 'Authentication hook unauthorized this request') {
      onTokenSave('');
      refetch();
    }
  }, [error, onTokenSave, refetch]);

  useEffect(() => {
    if (deleteActions.data) {
      toast.success(i18n.t('toast.success.deleted'), {
        autoClose: 500,
        onClose: async () => {
          await refetch();
          trackDeleteAlerts('delete_alert', {
            alertId: deleteActions.data.alert.id,
          });
          setConfirmationModal(defaultModalValue);
        },
      });
    }
    if (deleteActions.error) {
      toast.error(i18n.t('toast.error.something_went_wrong'), {
        autoClose: 500,
        onClose: () => {
          refetch();
          setConfirmationModal(defaultModalValue);
        },
      });
    }
  }, [deleteActions.data, deleteActions.error, refetch]);

  const handleShowConfirmation = (isTrue: boolean) => {
    if (isTrue) {
      const updateConfirmationModal = { ...confirmationModal };
      updateConfirmationModal.loading = true;
      setConfirmationModal(updateConfirmationModal);
      deleteObservation({
        variables: {
          id: seletedAlert?.id,
        },
      });
    } else {
      setConfirmationModal(defaultModalValue);
    }
  };

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  if (!loading && data.alert_rules.length === 0) {
    return (
      <div className="text-center m-auto align-self-center">
        {theme === 'light' ? (
          <NotificationBackground />
        ) : (
          <DarkNotificationBackground className="dark-image" />
        )}
        <h6 className="mt-24">
          {i18n.t('smart_notification.empty.list.title')}
        </h6>
        <p className="m-0">{i18n.t('smart_notification.empty.list.body')}</p>

        <Link
          to="/alerts/create"
          className="btn btn-primary btn-link-light rounded-pill mt-24"
        >
          {i18n.t('button.new_notifications_rule')}
        </Link>
      </div>
    );
  }
  return (
    <>
      <div className="d-flex flex-column align-items-end">
        <Table
          responsive={`sm w-100 alerts-table overflow-hidden border border-${theme} border-radius`}
          hover
          variant={theme}
          key="1"
        >
          <thead className="weight-500">
            <tr>
              <td>{i18n.t('td.alert')}</td>
              <td>{i18n.t('td.scenario')}</td>
              <td>{i18n.t('td.camera_name')}</td>
              <td>{i18n.t('td.channels')}</td>
              <td>{i18n.t('td.actions')}</td>
            </tr>
          </thead>
          <tbody>
            {data.alert_rules.map((alert: AlertProps) => (
              <RuleItem
                key={alert.id}
                alert={alert}
                onEdit={() => navigate(`/alerts/edit/${alert.id}`)}
                onDelete={() => {
                  setSeletedAlert(alert);
                  setConfirmationModal({
                    title: i18n.t('modal.delete_alert.title'),
                    body: `${i18n.t('modal.delete_alert.body')} "${alert.name}"`,
                    buttonType: 'danger',
                    doneText: i18n.t('modal.delete_observation.yes'),
                    cancelText: i18n.t('modal.delete_observation.no'),
                    show: true,
                  });
                }}
              />
            ))}
          </tbody>
        </Table>
      </div>
      <ConfirmationModal
        modalValue={confirmationModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />
    </>
  );
};

ManageTable.defaultProps = {};

export default ManageTable;
