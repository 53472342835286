import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { Theme, ThemeContextType } from '../typescript/context/theme';

export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined,
);

export const useThemeContext = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
};

type Props = PropsWithChildren<{
  overrideTheme?: Theme;
}>;

export function ThemeProvider({ overrideTheme, children }: Props) {
  const [theme, setTheme] = useState(overrideTheme || 'light');
  const [themeCookie, setThemeCookie] = useCookies(['themeValue']);

  useEffect(() => {
    if (!overrideTheme) {
      const { themeValue } = themeCookie;
      if (!themeValue) {
        setThemeCookie('themeValue', theme);
      } else {
        setTheme(themeCookie.themeValue);
      }
    }
  }, [overrideTheme, themeCookie, setThemeCookie, theme]);

  const ThemeProviderValue = useMemo(
    () => ({
      changeTheme: (value: Theme) => {
        setTheme(value);
        setThemeCookie('themeValue', value);
      },
      theme,
    }),
    [setThemeCookie, theme],
  );

  return (
    <ThemeContext.Provider value={ThemeProviderValue}>
      {children}
    </ThemeContext.Provider>
  );
}
