import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, FormCheck, Modal, Row } from 'react-bootstrap';
import {
  getAvailableVideos,
  startVideos,
} from '../../../apis/demp-api-request';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { ThemeContextType } from '../../../typescript/context/theme';
import { TitleAndValue } from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';

interface Props {
  show: boolean;
  onCloseClick: () => void;
  onNextClick: (name: string, ip: string) => void;
}

const DemoVideos: FC<Props> = function DemoVideos({
  show,
  onCloseClick,
  onNextClick,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const [videoNames, setVideoNames] = useState<Array<TitleAndValue>>([]);
  const [video, setVideo] = useState<TitleAndValue>();

  useEffect(() => {
    const subscribe = async () => {
      const videos = await getAvailableVideos();
      if (videos.data.length !== 0) {
        const updatedList = videos.data.map((item: string) => {
          const replaceDash = item.replaceAll('_', ' ');
          const title =
            replaceDash.charAt(0).toUpperCase() + replaceDash.slice(1);
          return { title: `Demo: ${title}`, value: item };
        });
        setVideoNames(updatedList);
      }
      return videos;
    };
    subscribe();
  }, []);

  const startVideosStream = async () => {
    if (video) {
      const videos = await startVideos(video.value);
      onNextClick(video.title, videos.data);
    }
  };

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={show}
      onHide={onCloseClick}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.edit_scenarios.title')}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={onCloseClick}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="scenario-tag">
            <p className="mb-1 weight-600">
              {i18n.t('active_scenarios.title')}
            </p>
            {videoNames.map((item: TitleAndValue) => (
              <FormCheck
                key={`${item}`}
                label={`${item.title}`}
                name="group1"
                type="radio"
                onClick={() => setVideo(item)}
              />
            ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          title={i18n.t('button.back')}
          className="button-with-loader"
          variant={`outline-${theme}`}
          type="button"
          onClick={onCloseClick}
        />
        <CustomButton
          title={i18n.t('button.next')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={() => startVideosStream()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DemoVideos;
