import React from 'react';
import Loader from '../components/elements/Loader';
import { useThemeContext } from '../contextapi/ThemeProvider';

export const LoadingPage = () => {
  const { theme } = useThemeContext();

  return (
    <div
      className={`${theme} d-flex justify-content-center align-items-center vh-100`}
    >
      <Loader main />
    </div>
  );
};
