/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { verifyOTP } from '../../apis/api-request';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import { CustomButton } from '../elements/CustomButton';

interface Props {
  show: boolean;
  onClose: () => void;
  onVerify: () => void;
}

export function OtpModal({ show, onClose, onVerify }: Props) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { userCookie } = useContext(AuthContext) as AuthContextType;
  const [code, setOtp] = useState('');
  const [loading, setLoading] = useState(false);

  if (!show) {
    return null;
  }

  const handleConfirmClick = async () => {
    setLoading(true);
    const response = await verifyOTP(userCookie.userToken, {
      otp_token: code,
    });

    if (response.success) {
      toast.success(response.data.message);
      onVerify();
    } else {
      toast.error(response.detail);
    }

    setLoading(false);
  };
  return (
    <Modal centered contentClassName={theme} show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{/* {title} */}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="ph-no-capture">
          <OtpInput
            containerStyle={{ justifyContent: 'center' }}
            inputStyle={{ width: 30, height: 40, fontWeight: 'bold' }}
            value={code}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
        <CustomButton
          title="Validate"
          className="button-with-loader mx-auto mt-24"
          variant="primary"
          type="submit"
          disabled={loading}
          loading={loading}
          onClick={handleConfirmClick}
        />
      </Modal.Body>
    </Modal>
  );
}
