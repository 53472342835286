import Konva from 'konva';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuthContext } from './AuthProvider';
import {
  addCameras,
  allCameras,
  getCameraById,
  updateCameras,
  urlTempImage,
} from '../apis/api-request';
import { CameraProps } from '../typescript/camera/camera';
import { CameraContextType, ExportProps } from '../typescript/context/camera';
import i18n from '../utils/i18n';

export const CameraUpdateContext = createContext<CameraContextType | undefined>(
  undefined,
);

export const useCameraUpdateContext = () => {
  const context = useContext(CameraUpdateContext);
  if (!context) {
    throw new Error(
      'useCameraUpdateContext must be used within a CameraUpdateProvider',
    );
  }

  return context;
};

export function CameraUpdateProvider({ children }: PropsWithChildren) {
  const { userCookie, onTokenSave, user } = useAuthContext();
  const [cameraList, setCameraList] = useState<Array<CameraProps>>([]);
  const [camera, setCamera] = useState<CameraProps | null | any>(null);
  const [imageExportParam, setImageExportParam] = useState<ExportProps>();

  // Loading
  const [loadingAllCamera, setLoadingAllCamera] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const loadAllCameras = useCallback(async () => {
    if (userCookie.userToken) {
      const loadCamera = await allCameras(userCookie.userToken);
      if (loadCamera.status === 401) {
        onTokenSave(null);
      } else if (loadCamera.status === 200) {
        const updatedList: Array<CameraProps> = loadCamera.data.message.map(
          (item: CameraProps) => ({
            ...item,
            isDemo: item.name.includes('Demo: '),
          }),
        );
        setCameraList(updatedList);
      }

      setLoadingAllCamera(false);
    }
  }, [userCookie.userToken, onTokenSave]);

  useEffect(() => {
    loadAllCameras();
  }, [user, loadAllCameras]);

  const getCamera = async (cameraId: string | undefined) => {
    const param = { cameraId };
    setCamera(null);
    const cameraDetail = await getCameraById(userCookie.userToken, param);
    if (cameraDetail.status === 401) {
      onTokenSave(null);
      return false;
    }
    if (!cameraDetail.data.message) {
      navigate(-1);
      return false;
    }
    setCamera(cameraDetail.data.message);
    return true;
  };

  async function onCameraAdd(param: { name: string; ip: string }) {
    setLoading(true);
    const addNewCamera = await addCameras(userCookie.userToken, param);
    setLoading(false);
    if (addNewCamera.status === 200) {
      if (param.name.includes('Demo: ')) {
        localStorage.setItem(`${param.name}`, '600');
      }
      toast.success(addNewCamera.data.message);
      loadAllCameras();
      return true;
    }
    if (addNewCamera.status === 401) {
      onTokenSave(null);
    }
    toast.error(addNewCamera.data.message);
    return false;
  }

  async function onCameraUpdate(updateCamera: any) {
    setLoading(true);
    const update = await updateCameras(userCookie.userToken, updateCamera);

    switch (update.status) {
      case 200:
        toast.success(update.data.message);
        setCamera({ ...updateCamera });
        break;

      case 401:
        toast.error(i18n.t('toast.error.token_expired'));
        onTokenSave(null);
        break;

      case 504:
        toast.error(i18n.t('toast.error.cannot_update'));
        break;
      default:
        toast.error(update.data.message);
        break;
    }
    return false;
  }

  // Load new camera url
  const getCameraImage = async () => {
    if (userCookie) {
      const param = { camera_id: camera?.sql_id };
      const getImageUrl = await urlTempImage(userCookie.userToken, param);
      if (getImageUrl.status === 401) {
        onTokenSave(null);
      }
      const updateCamera = {
        ...camera,
        imageUrl: getImageUrl.data.message,
      };
      setCamera(updateCamera);

      return true;
    }
    return false;
  };

  const handleImageExport = () => {
    if (imageExportParam) {
      const { stageRef, imageRef, scenarioName, dateRange } = imageExportParam;
      if (stageRef.current && imageRef.current) {
        const stageWidth = stageRef.current.width();
        const stageHeight = stageRef.current.height();
        const layer = stageRef.current?.findOne('Layer') as Konva.Layer;

        const padding = 10;
        const textHeight = 20;
        const containerHeight = textHeight + 4 * padding;

        const newCanvasHeight = stageRef.current.height() + containerHeight;
        stageRef.current.height(newCanvasHeight);

        const image = new Konva.Image({
          image: imageRef.current,
          width: stageWidth,
          height: stageHeight,
          fill: 'black',
        });
        const background = new Konva.Rect({
          x: 0,
          y: newCanvasHeight - 2 * textHeight - 2 * padding,
          width: stageWidth,
          height: containerHeight,
          fill: 'white',
        });

        const cameraText = new Konva.Text({
          text: `${i18n.t('heatmap_image.camera_name')}: ${camera.name},`,
          x: padding,
          y: newCanvasHeight - 2 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
        });
        const scenarioText = new Konva.Text({
          text: `${i18n.t('heatmap_image.scenario')}: ${scenarioName}`,
          x: cameraText.width() + 8 + padding,
          y: newCanvasHeight - 2 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
        });
        const dateText = new Konva.Text({
          text: `${i18n.t('heatmap_image.date_range')}: ${dateRange}`,
          x: padding,
          y: newCanvasHeight - 1 * textHeight - padding,
          fontSize: 12,
          fill: 'black',
          align: 'right',
        });

        layer.add(image);
        layer.add(background);
        layer.add(cameraText);
        layer.add(scenarioText);
        layer.add(dateText);
        layer.draw();
        const uri = stageRef.current.toDataURL({
          mimeType: 'image/png',
          quality: 1.0,
        });
        const link = document.createElement('a');
        link.download = 'camera-image';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        stageRef.current.height(stageHeight);
        image.destroy();
        background.destroy();
        cameraText.destroy();
        scenarioText.destroy();
        dateText.destroy();
        layer.draw();
      } else {
        console.warn('Konva stage not yet available');
      }
    }
  };

  const CameraUpdateProviderValue = useMemo(
    () => ({
      camera,
      cameraList,
      loading,
      loadingAllCamera,
      imageExportParam,
      setImageExportParam,
      loadAllCameras,
      getCamera,
      setCamera,
      onCameraAdd,
      onCameraUpdate,
      getCameraImage,
      setLoading,
      handleImageExport,
    }),
    [cameraList, camera, loading, loadingAllCamera, imageExportParam],
  );

  return (
    <CameraUpdateContext.Provider value={CameraUpdateProviderValue}>
      {children}
    </CameraUpdateContext.Provider>
  );
}
