import { Nullable } from '../../utils/typeUtils';

export const SUPPORTED_SCENARIOS = [
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.person.title',
    value: 'person',
    db_name: 'person_person',
    description: 'scenarios.person.description',
    isDangerZone: true,
    active: false,
    color: '#A9600E',
    colorLight: '#FEF7EC',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.ppe.title',
    value: 'ppe',
    db_name: 'ppe_ppe',
    description: 'scenarios.ppe.description',
    isDangerZone: true,
    active: false,
    color: '#B22B90',
    colorLight: '#F9E6F4',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.ppe.vest.title',
    value: 'ppe:vest',
    db_name: 'ppe_vest',
    description: 'scenarios.ppe.vest.description',
    isDangerZone: true,
    active: false,
    color: '#4B5BD1',
    colorLight: '#E3E6F8',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.ppe.hardhat.title',
    value: 'ppe:hardhat',
    db_name: 'ppe_hardhat',
    description: 'scenarios.ppe.hardhat.description',
    isDangerZone: true,
    active: false,
    color: '#B22B90',
    colorLight: '#F9E6F4',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.ppe_alt.hardhat.title',
    value: 'ppe_alt:hardhat',
    db_name: 'ppe_alt_hardhat',
    description: 'scenarios.ppe_alt.hardhat.description',
    isDangerZone: true,
    active: false,
    color: '#B22B90',
    colorLight: '#F9E6F4',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.ppe.bumpcap.title',
    value: 'ppe:bumpcap',
    db_name: 'ppe_bumpcap',
    description: 'scenarios.ppe.bumpcap.description',
    isDangerZone: true,
    active: false,
    color: '#05603A',
    colorLight: '#ECFDF3',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.nearhit.title',
    value: 'near-hit',
    db_name: 'near-hit_near-hit',
    description: 'scenarios.nearhit.description',
    isDangerZone: false,
    active: false,
    color: '#ab4bd1',
    colorLight: '#F0E7F8',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.nearhit.person.title',
    value: 'near-hit:person',
    db_name: 'near-hit_person',
    description: 'scenarios.nearhit.person.description',
    isDangerZone: false,
    active: false,
    color: '#ab4bd1',
    colorLight: '#efdaf7',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.nearhit.forklift.title',
    value: 'near-hit:forklift',
    db_name: 'near-hit_forklift',
    description: 'scenarios.nearhit.forklift.description',
    isDangerZone: false,
    active: false,
    color: '#A9600E',
    colorLight: '#FEF7EC',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.unattended_object.title',
    value: 'unattended-object',
    db_name: 'unattended-object_unattended-object',
    description: 'scenarios.unattended_object.description',
    isDangerZone: true,
    active: false,
    color: '#B22B90',
    colorLight: '#F9E6F4',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.down.title',
    value: 'down',
    db_name: 'down_down',
    description: 'scenarios.down.description',
    isDangerZone: true,
    active: false,
    color: '#05603A',
    colorLight: '#ECFDF3',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.forklift.title',
    value: 'forklift',
    db_name: 'forklift_forklift',
    description: 'scenarios.forklift.description',
    isDangerZone: true,
    active: false,
    color: '#4B5BD1',
    colorLight: '#E3E6F8',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.down_fall_trip.title',
    value: 'down-fall-trip',
    db_name: 'down_fall_trip-down_fall_trip',
    description: 'scenarios.down_fall_trip.description',
    isDangerZone: true,
    active: false,
    color: '#7C30C2',
    colorLight: '#F0E7F8',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.road_safety_equipment.title',
    value: 'road-safety-eq',
    db_name: 'road-safety-eq_road-safety-eq',
    description: 'scenarios.road_safety_equipment.description',
    isDangerZone: false,
    active: false,
    color: '#05603A',
    colorLight: '#ECFDF3',
  },
  {
    id: undefined,
    custom_value: undefined,
    title: 'scenarios.security.title',
    value: 'security',
    db_name: 'security_security',
    description: 'scenarios.security.description',
    isDangerZone: false,
    active: false,
    color: '#B22B90',
    colorLight: '#F9E6F4',
  },
] as const;

export type ScenarioName = (typeof SUPPORTED_SCENARIOS)[number]['value'];

export type ScenarioDBName = (typeof SUPPORTED_SCENARIOS)[number]['db_name'];

export type ScenarioProps = {
  id?: number;
  title: string;
  value: ScenarioName; // TODO: rename to "name"
  description: string;
  isDangerZone: boolean;
  active: boolean;
  color: string;
  colorLight: string;
  custom_value?: Nullable<string>;
};

export type Scenario = ScenarioProps & { id: number };

export function convertToScenarioName(dbName: ScenarioDBName): ScenarioName {
  const scenario = SUPPORTED_SCENARIOS.find(
    (scenario) => scenario.db_name === dbName,
  );

  if (!scenario) {
    throw new Error(`Unknown scenario with db_name: ${dbName}`);
  }

  return scenario.value;
}
