/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  GRAY_LIGHT,
  GRAY_SUBTLE,
  GRAY_WARM,
  PRIMARY_COLOR,
  PRIMARY_COLOR_LIGHT,
} from '../../scss/colors';
import { useTracker } from '../../utils/Tracker';

type Props = {
  label: string;
  checked?: boolean;
  variant?: 'light' | 'dark';
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export function CustomSwitch({
  label,
  checked = false,
  variant: variantProp,
  onChange,
}: Props) {
  const { theme } = useThemeContext();
  const { trackSwitchClick } = useTracker();
  const variant = variantProp || theme;

  return (
    <Form
      css={css`
        .form {
          &-switch {
            display: flex;
            padding: 0;
            flex-direction: row-reverse;
            align-items: center;
            &-dark {
              .form-check-label {
                color: #fff;
              }
            }
          }
        }
      `}
    >
      <Form.Check
        type="switch"
        label={label}
        className={`form-switch-${variant}`}
        css={css`
          .form {
            &-check-input {
              width: 36px;
              height: 20px;
              border-color: ${GRAY_WARM};
              background-color: ${GRAY_SUBTLE};
              &:checked {
                background-color: ${PRIMARY_COLOR};
                border-color: ${PRIMARY_COLOR};
              }
              &:focus {
                border-color: ${PRIMARY_COLOR_LIGHT};
                box-shadow: 0 0 0 0.25rem ${PRIMARY_COLOR_LIGHT};
                background-color: ${GRAY_LIGHT};
                --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e');
              }
              &:checked:focus {
                background-color: ${PRIMARY_COLOR};
              }
              &:not(:checked):focus {
                box-shadow: 0 0 0 0.25rem ${GRAY_SUBTLE};
                --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2396a097%27/%3e%3c/svg%3e');
              }
            }
            &-check-label {
              flex: 1;
              color: #000;
              height: 14px;
              font-weight: 500;
            }
          }
        `}
        style={{ fontSize: '14px' }}
        checked={checked}
        onChange={(e) => {
          if (onChange) {
            trackSwitchClick('switch_click', {
              title: label,
              value: !checked,
            });
            onChange(e);
          }
        }}
      />
    </Form>
  );
}
