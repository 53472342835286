/* eslint-disable no-mixed-operators */
import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { LanguageDropdown } from './LanguageDropdown';
import { setSiteId } from '../../apis/tokens';
import { ReactComponent as Alerts } from '../../assets/nav/bell.svg';
import { ReactComponent as VideoCamera } from '../../assets/nav/camera.svg';
import { ReactComponent as Observations } from '../../assets/nav/eye.svg';
import { ReactComponent as Logout } from '../../assets/nav/logout.svg';
import { ReactComponent as Light } from '../../assets/nav/moon.svg';
import { ReactComponent as Profile } from '../../assets/nav/profile.svg';
import { ReactComponent as Dark } from '../../assets/nav/sun.svg';
import { ReactComponent as Charts } from '../../assets/nav/system.svg';
import { ReactComponent as Workspace } from '../../assets/nav/workspace.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { spacing } from '../../scss/spacing';
import i18n from '../../utils/i18n';
import { useTracker } from '../../utils/Tracker';
import { CustomSwitch } from '../elements/CustomSwitch';

function MenuItem() {
  const navigate = useNavigate();
  const { theme, changeTheme } = useThemeContext();
  const { user, logout, userCookie, featureFlags, overrideFeatureFlags } =
    useAuthContext();

  const { trackButtonClick } = useTracker();
  const showGrouping = featureFlags.observations?.show_grouping;
  const isDevelopment = process.env.REACT_APP_ENV === 'development';

  return (
    <Nav defaultActiveKey="/">
      <div>
        {showGrouping && (
          <NavLink className="nav-link" to="/">
            <Charts className="icon" />
            <span className="diplay-mobile-view d-none">
              {i18n.t('nav_link.charts')}
            </span>
          </NavLink>
        )}

        <NavLink className="nav-link" to="/observations">
          <Observations className="icon" />
          <span className="diplay-mobile-view d-none">
            {i18n.t('nav_link.observations')}
          </span>
        </NavLink>

        <NavLink
          className="nav-link"
          to={
            user?.customer.feature_flags.alerts?.new_layout
              ? '/alerts'
              : '/smart-notifications'
          }
        >
          <Alerts className="icon" />
          <span className="diplay-mobile-view d-none">
            {i18n.t('nav_link.smart_notifications')}
          </span>
        </NavLink>

        <NavLink className="nav-link" to="/cameras">
          <VideoCamera className="icon" />
          <span className="diplay-mobile-view d-none">
            {i18n.t('nav_link.camera')}
          </span>
        </NavLink>
      </div>

      <div className="bottom-nav">
        {isDevelopment && (
          <Dropdown drop="up">
            <Dropdown.Toggle className="ph-no-capture">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-flag"
                viewBox="0 0 16 16"
              >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu variant={theme} className="pt-0">
              <div
                style={{
                  margin: spacing(2),
                }}
              >
                <h3>Feature Flags</h3>
                <CustomSwitch
                  label="Dashboard V2"
                  checked={featureFlags.dashboard.show_page}
                  onChange={() => {
                    overrideFeatureFlags({
                      dashboard: {
                        show_page: !featureFlags.dashboard.show_page,
                      },
                    });
                  }}
                />
                <CustomSwitch
                  label="Camera Details Page"
                  checked={featureFlags.cameras.show_details_page}
                  onChange={() => {
                    overrideFeatureFlags({
                      cameras: {
                        ...featureFlags.cameras,
                        show_details_page:
                          !featureFlags.cameras.show_details_page,
                      },
                    });
                  }}
                />
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {featureFlags.show_language_switcher && (
          <LanguageDropdown className="language-dropdown" />
        )}
        <Nav.Link
          onClick={() => {
            trackButtonClick({
              title: theme,
            });
            if (theme === 'light') {
              changeTheme('dark');
            } else {
              changeTheme('light');
            }
          }}
        >
          {theme === 'light' ? (
            <Light className="icon" />
          ) : (
            <Dark className="icon" />
          )}
        </Nav.Link>

        <div className="user-profile">
          <Dropdown drop="up">
            <Dropdown.Toggle
              variant={`${user?.isAdmin ? 'admin-user' : 'non-admin-user'}`}
              id="dropdown-basic"
              className="profile-toggle ph-no-capture"
            >
              {user?.email.substring(0, 1)}
            </Dropdown.Toggle>

            <Dropdown.Menu variant={theme} className="pt-0">
              <div
                className={`${user?.isAdmin ? 'admin-user' : 'non-admin-user'}`}
              >
                {user?.username && (
                  <p className="m-0 ph-no-capture">{user?.username}</p>
                )}
                <p className="m-0 ph-no-capture" style={{ fontSize: 12 }}>
                  {user?.email}
                </p>
              </div>

              <p className="bottom-nav-title">
                {i18n.t('bottom_nav.working_sites')}
              </p>

              {user?.customer.sites.map((site) => {
                const check = site.id === Number(userCookie.siteId);
                return (
                  <Nav.Link
                    key={site.id}
                    className={`nav-link dropdown-item m-0 justify-content-start ${check && 'active'}`}
                    onClick={() => {
                      setSiteId(String(site.id));
                      navigate('/dashboard');
                      window.location.reload();
                    }}
                  >
                    <Workspace className="me-2" />
                    {site.name}
                  </Nav.Link>
                );
              })}

              <p className="bottom-nav-title">
                {i18n.t('bottom_nav.account_access_control')}
              </p>
              <NavLink
                className="nav-link dropdown-item m-0 justify-content-start"
                to="/settings"
              >
                <Profile className="me-2" />
                {i18n.t('button.profile_settings')}
              </NavLink>
              <Nav.Link
                className="dropdown-item m-0 justify-content-start"
                onClick={() => logout()}
              >
                <Logout className="me-2" />
                {i18n.t('button.log_out')}
              </Nav.Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Nav>
  );
}

export default MenuItem;
