import { useQuery } from '@apollo/client';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { resetCamera } from '../../../apis/api-request';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { AuthContext } from '../../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { GET_SCENARIOS } from '../../../graphql/queries/scenarios';
import { AuthContextType } from '../../../typescript/context/auth';
import { CameraContextType } from '../../../typescript/context/camera';
import { ThemeContextType } from '../../../typescript/context/theme';
import { ScenarioProps } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';
import ScenarioDropdownWithCheckbox from '../../elements/ScenarioDropdownWithCheckbox';

interface Props {
  hideParentModal: () => void;
}

const ResetCamera: FC<Props> = function ResetCamera({ hideParentModal }) {
  const { userCookie, onTokenSave, scenariosList } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { camera } = useContext(CameraUpdateContext) as CameraContextType;
  const { theme } = useContext(ThemeContext) as ThemeContextType;

  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  // const [scenarioList, setScenariosList] = useState<Array<ScenarioProps>>([]);
  const [selectedScenario, setSelectedScenario] = useState<ScenarioProps>();

  // router dom
  const { data } = useQuery(GET_SCENARIOS, {
    fetchPolicy: 'network-only',
  });

  const handleShow = () => {
    hideParentModal();
    setShow(!show);
  };

  // const loadScenarios = (
  //   scenarios: Array<any>,
  // ) => {
  //   if (scenarios) {
  //     const filteredScenario = scenarios.map((secondItem) => {
  //       const matchingFirstItem = ALL_SCENARIO_MODELS
  //         .find((firstItem) => firstItem.value === secondItem.name);
  //       if (matchingFirstItem) {
  //         return {
  //           ...matchingFirstItem,
  //           ...secondItem,
  //           title: secondItem.custom_value ? `scenarios.${secondItem.custom_value}.title` : matchingFirstItem.title,
  //           description: secondItem.custom_value ? `scenarios.${secondItem.custom_value}.description` : matchingFirstItem.description,
  //         };
  //       }
  //       return secondItem;
  //     });
  //     setScenariosList(filteredScenario);
  //   } else {
  //     setScenariosList([]);
  //   }
  // };

  const onResetCamera = async () => {
    if (selectedScenario) {
      setLoading(true);
      const param = {
        camera_id: camera.sql_id,
        scenario_id: selectedScenario.id!,
      };
      const updateCameraImage = await resetCamera(userCookie.userToken, param);
      setLoading(false);

      if (updateCameraImage.status === 200) {
        toast.success(i18n.t('toast.success.reset_camera'));
        return true;
      }
      if (updateCameraImage.status === 401) {
        onTokenSave('');
      }
      toast.error(updateCameraImage.data.message);
    }
    toast.error(i18n.t('toast.error.please_select_scenario'));
    return false;
  };

  useEffect(() => {
    if (data) {
      // loadScenarios(data.scenarios);
    }
  }, [data]);

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('camera.reset_camera.title')}</p>
      <p className="sub-color">{i18n.t('camera.reset_camera.body')}</p>

      <CustomButton
        variant={`outline-${theme}`}
        type="button"
        className="button-with-loader"
        title={i18n.t('button.reset_camera')}
        loading={loading}
        disabled={loading}
        onClick={handleShow}
      />

      <Modal
        centered
        contentClassName={theme}
        className="custom-modal schedule-modal"
        show={show}
        onHide={() => handleShow()}
      >
        <Modal.Header>
          <Modal.Title>{i18n.t('modal.reset_camera.title')}</Modal.Title>
          <CustomButton
            icon={CancelIcon}
            className="border-0"
            type="button"
            onClick={() => handleShow()}
          />
        </Modal.Header>
        <Modal.Body>
          <p className="mb-1 weight-600">
            {i18n.t('modal.reset_camera.scenario_title')}
          </p>
          <ScenarioDropdownWithCheckbox
            className="rounded-pill me-12"
            containerClassName="h-auto"
            title={
              selectedScenario
                ? `${i18n.t(selectedScenario.title)}`
                : `${i18n.t('button.select_scenario')}`
            }
            dropdownData={scenariosList
              .filter((item) => item.value === 'road-safety-eq')
              .map((item) => ({
                ...item,
                title: i18n.t(item.title),
              }))}
            variant={`outline-${theme}`}
            bodyVariant={theme}
            onClick={(value) => {
              setSelectedScenario(value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            title={i18n.t('button.back_to_settings')}
            className="button-with-loader"
            variant={`outline-${theme}`}
            type="button"
            onClick={() => handleShow()}
          />

          <CustomButton
            title={i18n.t('button.reset')}
            className="button-with-loader"
            variant="primary"
            type="button"
            onClick={onResetCamera}
            loading={loading}
            disabled={loading || !selectedScenario}
          />
        </Modal.Footer>
      </Modal>

      <hr />
    </Col>
  );
};

export default ResetCamera;
