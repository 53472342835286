/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useState } from 'react';
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { AuthContext } from '../../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { AuthContextType } from '../../../typescript/context/auth';
import { CameraContextType } from '../../../typescript/context/camera';
import { ThemeContextType } from '../../../typescript/context/theme';
import { ScenarioProps } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';

interface Props {
  hideParentModal: () => void;
}

export const ActiveScenarios = function ActiveScenarios({
  hideParentModal,
}: Props) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;
  const { camera, onCameraUpdate, loading, setLoading } = useContext(
    CameraUpdateContext,
  ) as CameraContextType;

  const [show, setShow] = useState<boolean>(false);

  const [scenarioArray, setScenarioArray] = useState<Array<ScenarioProps>>([]);

  function filteredArray() {
    const filter = scenariosList
      .filter((item) => item.value !== 'ppe')
      .map((arr1Item) => {
        const { title, value, description, isDangerZone, color, colorLight } =
          arr1Item;

        const isScenatio = camera.scenarios.includes(value);

        const updateValue = {
          title,
          value,
          description,
          isDangerZone,
          active: isScenatio,
          color,
          colorLight,
        };
        return updateValue;
      });

    return filter;
  }

  const handleClose = (isUpdate: boolean) => {
    if (!isUpdate) {
      setScenarioArray(filteredArray());
    }
    hideParentModal();
    setShow(false);
  };
  const handleShow = () => {
    hideParentModal();
    setShow(true);
  };

  useEffect(() => {
    if (camera) {
      setScenarioArray(filteredArray());
    }
  }, [camera]);

  const updateScenario = (index: number) => {
    const updatedScenario = [...scenarioArray];
    updatedScenario[index].active = !updatedScenario[index].active;
    setScenarioArray(updatedScenario);
  };

  const onCameraUpdateScenario = async () => {
    const filter = scenarioArray
      .filter((isScenario) => isScenario.active)
      .map((scenario) => scenario.value);
    const updateCamera = camera;
    updateCamera.scenarios = filter;
    await onCameraUpdate(updateCamera);
    handleClose(false);
    setLoading(false);
  };

  function ScenarioObject(item: ScenarioProps, index: number, isIcon: boolean) {
    const { description, color, colorLight, value, title, active } = item;
    return (
      <OverlayTrigger
        key={value}
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{i18n.t(description)}</Tooltip>}
      >
        <li
          className="scenario-list me-2 mb-12"
          key={value}
          style={{
            backgroundColor: theme === 'light' ? colorLight : color,
            color: theme === 'light' ? color : '#fff',
          }}
        >
          {i18n.t(title)}
          {isIcon && (
            <span>
              {active ? (
                <svg
                  className="ms-2"
                  onClick={() => updateScenario(index)}
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L1 7M1 1L7 7"
                    stroke={theme === 'light' ? color : '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="ms-2"
                  onClick={() => updateScenario(index)}
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.24264 0.757812V5.00045M5.24264 5.00045V9.24309M5.24264 5.00045H1M5.24264 5.00045H9.48528"
                    stroke={theme === 'light' ? color : '#fff'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </span>
          )}
        </li>
      </OverlayTrigger>
    );
  }

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">
        {`${i18n.t('camera.active_scenarios.title')} (${filteredArray().filter((scenario) => scenario.active).length})`}
      </p>
      <p className="sub-color">{i18n.t('camera.active_scenarios.body')}</p>

      <div className="scenario-tag">
        <ul>
          {filteredArray().map(
            (item, index) => item.active && ScenarioObject(item, index, false),
          )}
        </ul>
      </div>
      {camera.scenarios.length === 0 && (
        <p>
          <i>{i18n.t('scenarios.no_active_scenarios.title')}</i>
        </p>
      )}

      <CustomButton
        icon={AddIcon}
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.add_scenario')}
        onClick={handleShow}
      />

      <Modal
        centered
        contentClassName={theme}
        className="custom-modal"
        show={show}
        onHide={() => handleClose(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {i18n.t('modal.camera.edit_scenarios.title')}
          </Modal.Title>
          <CustomButton
            icon={CancelIcon}
            className="border-0"
            type="button"
            onClick={() => handleClose(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="scenario-tag">
              <p className="mb-1 weight-600">
                {i18n.t('active_scenarios.title')}
              </p>
              <ul>
                {scenarioArray.map(
                  (item, index) =>
                    item.active && ScenarioObject(item, index, true),
                )}
              </ul>
            </Col>

            <Col md={12} className="scenario-tag">
              <p className="mb-1 weight-600">{i18n.t('all_scenarios.title')}</p>
              <ul>
                {scenarioArray.map(
                  (item, index) =>
                    !item.active && ScenarioObject(item, index, true),
                )}
              </ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            title={i18n.t('button.back_to_settings')}
            className="button-with-loader"
            variant={`outline-${theme}`}
            type="button"
            onClick={() => handleClose(false)}
          />

          <CustomButton
            title={i18n.t('button.save_changes')}
            className="button-with-loader"
            variant="primary"
            type="button"
            onClick={onCameraUpdateScenario}
            loading={loading}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>

      <hr />
    </Col>
  );
};
