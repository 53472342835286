import React, { FC } from 'react';
import { Image } from 'react-bootstrap';

interface Props {
  className?: string;
  imageUrl: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
}

const ImageViewer: FC<Props> = function ImageViewer({
  imageUrl,
  width,
  height,
  className,
  onClick,
}) {
  return (
    <div
      className={`canvas-container ${className}`}
      aria-hidden="true"
      onClick={onClick}
    >
      <Image width={width} height={height} src={imageUrl} id="image" />
    </div>
  );
};

ImageViewer.defaultProps = {
  className: '',
  width: '100%',
  height: undefined,
  onClick: undefined,
};

export default ImageViewer;
