import { siteId } from './tokens';
import { axiosClient } from './utils';

export const baseURL = process.env.REACT_APP_BASE_URL;

export const fileStatus = async (token: string) => {
  const response = await axiosClient
    .post(
      '/v2/file-endpoint/status/',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(siteId && { 'X-Site-Id': `${siteId}` }),
        },
      },
    )
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const generateFile = async (
  token: string,
  data: {
    type: string;
    scenarios: Array<number>;
    since?: number;
    start_time?: number;
    end_time?: number;
    camera_ids?: Array<number>;
    responder?: Array<string>;
    status?: Array<string>;
    priority?: Array<string>;
    is_bookmarked?: boolean;
    limit?: number;
  },
) => {
  const response = await axiosClient
    .post('/v2/file-endpoint/generate-file/', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(siteId && { 'X-Site-Id': `${siteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export const downloadFile = async (token: string) => {
  const response = await axiosClient
    .get('/v2/file-endpoint/download-file?file_type=CSV_EXPORT', {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(siteId && { 'X-Site-Id': `${siteId}` }),
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};
