import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  DaysListFilterProp,
  DaysListProps,
  TIME_RANGES,
} from '../typescript/grouping/grouping-observation';
import i18n from '../utils/i18n';
import { isDefined } from '../utils/typeUtils';

type TimeRangeContextType = {
  timeRange: DaysListProps;
  timeRangesData: DaysListProps[];
  setTimeRange: (timeRange: DaysListProps) => void;
  generateTimeRangeUrlParam: (
    timeRange: DaysListProps,
    valueOnly?: boolean,
  ) => string;
  updateTimeRangeQuery: (timeRange: DaysListProps) => void;
  shouldSetDefaultTimeRange: boolean;
};

export const TimeRangeContext = createContext<TimeRangeContextType | undefined>(
  undefined,
);

export const useTimeRangeContext = () => {
  const context = useContext(TimeRangeContext);
  if (!context) {
    throw new Error(
      'useTimeRangeContext must be used within a TimeRangeProvider',
    );
  }

  return context;
};

type Props = PropsWithChildren;

export const TimeRangeProvider = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryString =
    (searchParams.get('query') &&
      decodeURI(String(searchParams.get('query')))) ||
    '{}';

  const query: Partial<DaysListFilterProp> = JSON.parse(queryString);

  const [rangeFrom, rangeTo] = query.range || [undefined, undefined];

  const defaultTimeRange = query.title
    ? TIME_RANGES.filter((item) => item.text === query.title).map((item) => ({
        ...item,
        value: rangeFrom || item.value,
        end: rangeTo || item.end,
      }))[0]
    : TIME_RANGES[1];
  const shouldSetDefaultTimeRange = !isDefined(query.title);

  const [timeRange, setTimeRange] = useState<DaysListProps>(defaultTimeRange);

  // TODO: move this to the component
  const timeRangesData = TIME_RANGES.map((item: DaysListProps) => ({
    ...item,
    title: `${i18n.t(item.title)}`,
    value: timeRange.text === item.text && rangeFrom ? rangeFrom : item.value,
    end: timeRange.text === item.text && rangeTo ? rangeTo : item.end,
  }));

  const generateTimeRangeUrlParam = useCallback(
    (timeRange: DaysListProps, valueOnly = false) => {
      const value = JSON.stringify({
        title: timeRange.text,
        range: [timeRange.value, timeRange.end],
      });

      return encodeURI(valueOnly ? value : `query=${value}`);
    },
    [],
  );

  const updateTimeRangeQuery = useCallback(
    (timeRange: DaysListProps) => {
      setSearchParams((searchParams) => {
        searchParams.set('query', generateTimeRangeUrlParam(timeRange, true));
        return searchParams;
      });
    },
    [setSearchParams, generateTimeRangeUrlParam],
  );

  const context = useMemo(
    () => ({
      timeRange,
      setTimeRange,
      timeRangesData,
      generateTimeRangeUrlParam,
      updateTimeRangeQuery,
      shouldSetDefaultTimeRange,
    }),
    [
      timeRange,
      setTimeRange,
      timeRangesData,
      generateTimeRangeUrlParam,
      updateTimeRangeQuery,
      shouldSetDefaultTimeRange,
    ],
  );

  return (
    <TimeRangeContext.Provider value={context}>
      {children}
    </TimeRangeContext.Provider>
  );
};
