import posthog from 'posthog-js';

if (process.env.REACT_APP_ENV !== 'production') {
  posthog.init('phc_6tyhhQwK8UgupZgUfMmi5ozG3T4z0StHIzAAta19PNl', {
    api_host: 'https://eu.posthog.com',
  });
}

const actions = {
  identify: (id: any, props: object) => {
    posthog.identify(id, props);
  },
  capture: (id: any, props: object) => {
    posthog.capture(id, props);
  },
  reset: () => {
    posthog.reset();
  },
};

const Posthog = actions;

export default Posthog;
