/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import { ChartItem, Timeseries } from '../../hooks/graphql/chart';
import { isNumber } from '../../utils/typeUtils';
import {
  convertToScenarioName,
  ScenarioDBName,
  ScenarioName,
  ScenarioProps,
  SUPPORTED_SCENARIOS,
} from '../observation/scenario';

export type GroupingItemProp = {
  count: number;
  name: string;
  previous_count: number;
  scenario_names: Array<ScenarioDBName>;
  timeseries: Array<{
    count: number;
    range: string;
    date?: string;
    // __typename: string
  }>;
  __typename: string;
  id: number;
  camera_names: Array<string>;
  default_assigned: null | string;
  default_priority: string;
  trend: number;
  observations_by_time_of_day: {
    evening: number;
    midday: number;
    morning: number;
  };
  cameras: Array<{
    count: number;
    id: number;
    name: string;
  }>;
};

export type TimeseriesWithDate = Timeseries & {
  date: string;
};

export type CombinedDataItem = Partial<Record<ScenarioName, number>> & {
  range: string;
  date?: string;
};

export type DaysListProps = {
  title: string;
  text: 'today' | '24 hours' | '7' | '14' | '30' | 'custom';
  value: number;
  end: number;
  isHourly: boolean;
  isDatePicker?: boolean;
};

export type DaysListFilterProp = {
  title: string;
  range: [number, number];
};

export const TIME_RANGES: Array<DaysListProps> = [
  {
    title: 'dropdown.daylist.today',
    text: 'today',
    value: moment().startOf('day').add(0, 'hours').unix(),
    end: moment().unix(),
    isHourly: true,
  },
  {
    title: 'dropdown.daylist.24_hours',
    text: '24 hours',
    value: moment().subtract(24, 'hours').startOf('hour').unix(),
    end: moment().unix(),
    isHourly: true,
  },
  {
    title: 'dropdown.daylist.7_days',
    text: '7',
    value: moment().subtract(7, 'days').startOf('day').unix(),
    end: moment().unix(),
    isHourly: false,
  },
  {
    title: 'dropdown.daylist.14_days',
    text: '14',
    value: moment().subtract(14, 'days').startOf('day').unix(),
    end: moment().unix(),
    isHourly: false,
  },
  {
    title: 'dropdown.daylist.30_days',
    text: '30',
    value: moment().subtract(30, 'days').startOf('day').unix(),
    end: moment().unix(),
    isHourly: false,
  },
  {
    title: 'dropdown.daylist.custom',
    text: 'custom',
    value: 0,
    end: 0,
    isHourly: false,
    isDatePicker: true,
  },
];

export function dayIntoTimeStamp(title: string, start: number) {
  const dayToTimeStamp =
    title === 'today'
      ? moment().startOf('day').add(0, 'hours').unix()
      : title === 'custom'
        ? start
        : moment().subtract(start, 'days').unix();
  return Number(dayToTimeStamp);
}

export const updatedDate = (
  data: TimeseriesWithDate[],
  title: string,
  start: number,
  end: number,
  isHourly: boolean,
) => {
  const startDate = moment.unix(start);
  const endDate = moment.unix(end);

  const dateCounts: Record<string, number> = {};

  // Aggregate counts for each date
  data.forEach((item) => {
    const date = moment(item.range).format('DD MMM');
    dateCounts[date] = (dateCounts[date] || 0) + item.count;
  });

  const interpolatedData: TimeseriesWithDate[] = [];

  if (isHourly) {
    for (
      let date = startDate.clone();
      date.isBefore(endDate);
      date.add(1, 'hour')
    ) {
      const matchingData = data.find((item) =>
        moment(item.range).isSame(date, 'hour'),
      );

      interpolatedData.push({
        date: date.format('YYYY-MM-DDTHH:00:00Z'),
        range: date.format('YYYY-MM-DDTHH:00:00Z'),
        count: matchingData ? matchingData.count : 0,
      });
    }
  } else {
    for (
      let date = startDate.clone();
      date.isSameOrBefore(endDate);
      date.add(1, 'day')
    ) {
      const dateString = date.format('DD MMM');
      interpolatedData.push({
        date: dateString,
        range: date.format('YYYY-MM-DDTHH:00:00Z'),
        count: dateCounts[dateString] || 0,
      });
    }
  }

  return interpolatedData;
};

export const combineObservations = (observations: ChartItem[]) => {
  const scenarioCounts = new Map<
    string,
    Partial<Record<ScenarioName, number>>
  >();
  const scenarioNames = new Set<ScenarioName>();

  observations.forEach((observationGroup) => {
    const { timeseries, scenario_names } = observationGroup;
    const convertedScenarios = scenario_names.map((scenarioDBName) =>
      convertToScenarioName(scenarioDBName),
    );
    convertedScenarios.forEach((scenarioName) => {
      scenarioNames.add(scenarioName);
    });

    timeseries.forEach((timeEntry) => {
      const { range, count } = timeEntry;

      convertedScenarios.forEach((scenarioName) => {
        const currentCounts = scenarioCounts.get(range) || {};

        scenarioCounts.set(range, {
          ...currentCounts,
          [scenarioName]: (currentCounts[scenarioName] || 0) + count,
        });
      });
    });
  });

  const combinedData: CombinedDataItem[] = [];
  scenarioCounts.forEach((scenarios, range) => {
    const dataEntry: CombinedDataItem = {
      range,
    };
    for (const scenario of scenarioNames) {
      dataEntry[scenario] = scenarios[scenario] || 0;
    }
    combinedData.push(dataEntry);
  });

  return combinedData;
};

type GroupedData = {
  [key: string]: Omit<CombinedDataItem, 'range'> & { date: string };
};

export const groupDataByDates = (
  data: Array<CombinedDataItem>,
  scenarios: Array<ScenarioProps>,
  isHourly: boolean,
) => {
  const groupedData: GroupedData = {};

  data.forEach((item: CombinedDataItem) => {
    const date = isHourly
      ? moment(item.range).format('DD MMM, YYYY, HH:mm')
      : moment(item.range).format('DD MMM, YYYY');

    if (!groupedData[date]) {
      const defaultScenarios = SUPPORTED_SCENARIOS.reduce(
        (acc, key) => {
          acc[key.value] = 0;
          return acc;
        },
        {} as Record<ScenarioName, number>,
      );

      groupedData[date] = {
        date,
        ...defaultScenarios,
      };
    }

    scenarios.forEach((scenario: ScenarioProps) => {
      const itemValue = item[scenario.value];
      if (isNumber(itemValue)) {
        groupedData[date][scenario.value] =
          (groupedData[date][scenario.value] || 0) + itemValue;
      }
    });
  });

  return Object.values(groupedData);
};
