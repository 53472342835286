import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';
import { ReactComponent as CameraIcon } from '../assets/dashboard/camera.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings-dark.svg';
import { CameraStatus } from '../components/camera/CameraStatus';
import { ExtendedCameraHeatMap } from '../components/camera/variants/ExtendedCameraHeatMap';
import { ObservationCharts } from '../components/charts-container/ObservationCharts';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { CustomButton } from '../components/elements/CustomButton';
import { FlexCol } from '../components/elements/FlexCol';
import { PageTitle } from '../components/elements/PageTitle';
import { SectionTitle } from '../components/elements/SectionTitle';
import { CameraSettingsModal } from '../components/modal/CameraSettingsModal';
import { ObservationList } from '../components/observation/ObservationList';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useCameraUpdateContext } from '../contextapi/CameraUpdateProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { useGetCameraQuery } from '../hooks/graphql/camera';
import { GRAY_WARM_DARKER_PLACEHOLDER } from '../scss/colors';
import { spacing } from '../scss/spacing';
import { TIME_RANGES } from '../typescript/grouping/grouping-observation';
import { ScenarioName } from '../typescript/observation/scenario';
import i18n from '../utils/i18n';

export function CameraDetailsPage() {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const { camera, handleImageExport } = useCameraUpdateContext();

  const { cameraId: cameraIdParam } = useParams();
  if (!cameraIdParam) {
    throw new Error('Camera ID is required');
  }
  const cameraId = Number(cameraIdParam);

  const { timeRange, setTimeRange, shouldSetDefaultTimeRange } =
    useTimeRangeContext();
  if (shouldSetDefaultTimeRange) {
    setTimeRange(TIME_RANGES[1]);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const scenarioIdParam = searchParams.get('scenarioId');

  const [showCameraSettingsModal, setShowCameraSettingsModal] =
    useState<boolean>(false);

  const { loading, data, refetch } = useGetCameraQuery({
    id: cameraId,
  });
  // TODO: validate with real data
  const gqlCamera = data?.cameras_by_pk;

  const scenarioNames =
    gqlCamera?.cameras_scenarios.map(
      (scenario) => scenario.customer_scenario_label.name as ScenarioName,
    ) || [];

  // we need to use scenarioList here because it's sorted and has ids
  const defaultScenario = scenariosList.find((scenario) =>
    scenarioNames.includes(scenario.value),
  );

  const scenarioId = scenarioIdParam
    ? Number(scenarioIdParam)
    : defaultScenario?.id;

  const timePeriod = useMemo(
    () => ({
      from: timeRange.value,
      to: timeRange.end,
    }),
    [timeRange],
  );

  useEffect(() => {
    if (camera) {
      refetch();
    }
  }, [camera, refetch]);

  if (loading || !gqlCamera) {
    return <LoadingPage />;
  }

  const scenariosWithDetections = gqlCamera.cameras_scenarios;

  const handleScenarioChange = (scenarioName: ScenarioName) => {
    const scenario = scenariosList.find(
      (scenario) => scenario.value === scenarioName,
    );
    setSearchParams((searchParams) => {
      if (scenario?.id) {
        searchParams.set('scenarioId', String(scenario.id));
      } else {
        searchParams.delete('scenarioId');
      }

      return searchParams;
    });
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Breadcrumb
            title={`${i18n.t('page.camera_details.go_back')}`}
            fromUrl="/cameras"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="d-flex justify-content-between mb-32">
          <div className="d-flex flex-column gap-2">
            <PageTitle
              title={i18n.t('page.camera_details.title')}
              removeMargins
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: spacing(4),
              }}
            >
              <span
                style={{
                  display: 'flex',
                  gap: spacing(2),
                  color: GRAY_WARM_DARKER_PLACEHOLDER,
                  fontWeight: 500,
                }}
              >
                <CameraIcon />
                {gqlCamera.name}
              </span>
              {gqlCamera.status !== null && (
                <CameraStatus status={gqlCamera.status} />
              )}
            </div>
          </div>
          <div className="d-flex gap-2 align-items-end">
            <CustomButton
              className="button-with-loader"
              icon={SettingsIcon}
              variant={`outline-${theme}`}
              title={i18n.t('page.camera_details.settings')}
              onClick={() =>
                setShowCameraSettingsModal(!showCameraSettingsModal)
              }
            />
            <TimeRangeDropdown />
          </div>
        </Col>
      </Row>
      <Row className="mb-32">
        <FlexCol md={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: spacing(6),
            }}
          >
            <SectionTitle
              removeMargins
              title={i18n.t('page.camera_details.heatmap')}
            />
            <CustomButton
              title={`${i18n.t('button.download')}`}
              onClick={() => {
                handleImageExport();
              }}
              className="button-with-loader"
              variant={`outline-${theme}`}
            />
          </div>
          <ExtendedCameraHeatMap
            cameraId={cameraId}
            scenarioId={scenarioId}
            scenariosWithDetections={scenariosWithDetections}
            onScenarioChange={handleScenarioChange}
          />
        </FlexCol>
      </Row>
      <Row>
        <SectionTitle title={i18n.t('page.camera_details.stats')} />
        <ObservationCharts cameraIds={[gqlCamera.id]} />
      </Row>
      <Row>
        <FlexCol md={12}>
          <SectionTitle
            title={i18n.t('page.camera_details.observation_list')}
          />
          <ObservationList
            hideCameraColumn
            variant="camera"
            cameraId={cameraId}
            timePeriod={timePeriod}
          />
        </FlexCol>
      </Row>
      <CameraSettingsModal
        cameraLegacyId={gqlCamera.legacy_id}
        show={showCameraSettingsModal}
        onCloseClick={() => {
          setShowCameraSettingsModal(!showCameraSettingsModal);
        }}
      />
    </>
  );
}
