import { gql } from '@apollo/client';

export const ADD_OBSERVATION_BOOKMARKS = gql`
  mutation ADD_OBSERVATION_BOOKMARKS($data: [observation_user_insert_input!]!) {
    insert_observation_user(
      objects: $data
      on_conflict: {
        constraint: observation_user_user_id_observation_id_key
        update_columns: [observation_id, user_id]
      }
    ) {
      returning {
        id
        observation {
          id
          observation_users {
            id
          }
        }
      }
    }
  }
`;

export const ADD_OBSERVATION_BOOKMARK = gql`
  mutation ADD_OBSERVATION_BOOKMARK($data: observation_user_insert_input!) {
    insert_observation_user_one(object: $data) {
      id
      observation {
        id
        observation_users {
          id
        }
      }
    }
  }
`;

export const UPDATE_OBSERVATION_BY_ID = gql`
  mutation UPDATE_OBSERVATION_BY_ID(
    $id: [bigint]
    $column: String
    $value: String
  ) {
    observation_update_sensitive_column(
      observation_ids: $id
      column: $column
      value: $value
    ) {
      observation {
        id
        imageUrl: image_url
        priority
        requestId: request_id
        responder
        status
        system_timestamp
        created
        is_false_positive
        detections {
          location
          id
          label
          observation {
            customer_scenario_label_id
          }
        }
        timeline
        comments
        customer_scenario_label {
          name
          scenario {
            name
          }
        }
        camera {
          name
        }
        observation_users {
          id
        }
      }
    }
  }
`;

export const DELETE_OBSERVATION_BOOKMARK = gql`
  mutation DELETE_OBSERVATION_BOOKMARK($id: Int!) {
    delete_observation_user_by_pk(id: $id) {
      id
      observation {
        id
        observation_users {
          id
        }
      }
    }
  }
`;

export type ObservationUserInsertInput = {
  note?: string;
  observation_id: string;
  title?: string;
  user_id: number;
};
