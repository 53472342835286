import React, { useMemo } from 'react';
import { Card, Col } from 'react-bootstrap';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
} from 'recharts';
import { DonutTooltip } from './tools/DonutTooltip';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { useNavigateToObservation } from '../../../hooks/charts';
import { ChartItem } from '../../../hooks/graphql/chart';
import {
  convertToScenarioName,
  SUPPORTED_SCENARIOS,
} from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { useTracker } from '../../../utils/Tracker';
import { CustomPlaceholder } from '../../elements/CustomPlaceholder';

type Props = {
  data: ChartItem[];
  cameraIds?: Array<number>;
  isLoading?: boolean;
};

export function DonutChart({ data, cameraIds, isLoading = false }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const { timeRange } = useTimeRangeContext();
  const { trackBarClick } = useTracker();
  const { navigateToGroupScenario } = useNavigateToObservation();

  const chartData = useMemo(
    () =>
      data
        .map((observationGroup) => {
          const { id, timeseries, scenario_names } = observationGroup;

          const scenarioName = convertToScenarioName(scenario_names[0]);
          const observationCount = timeseries.reduce(
            (total, timeEntry) => total + timeEntry.count,
            0,
          );

          return observationCount
            ? { id, name: scenarioName, count: observationCount }
            : { id, name: '', count: 0 };
        })
        .filter(Boolean),
    [data],
  );

  const totalObservations = chartData.reduce(
    (total, { count }) => total + count,
    0,
  );

  const renderCustomLabel = ({ cx, cy }: { cx: number; cy: number }) => (
    <g>
      <defs>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow
            dx="2"
            dy="2"
            stdDeviation="2"
            floodColor={theme === 'dark' ? '#6F7676' : '#1F2321'}
            floodOpacity="0.1"
          />
        </filter>
      </defs>
      <circle
        cx={cx}
        cy={cy}
        r={80}
        fill={theme !== 'dark' ? '#ffffff' : '#1F2321'}
        filter="url(#shadow)"
      />
      <Text
        x={cx}
        y={cy}
        textAnchor="middle"
        dominantBaseline="middle"
        className="total-observations-label"
        fontSize={24}
        fill={theme === 'dark' ? '#ffffff' : '#1F2321'}
        fontWeight="bold"
      >
        {totalObservations.toLocaleString()}
      </Text>
      <Text
        x={cx}
        y={cy + 25}
        textAnchor="middle"
        dominantBaseline="middle"
        className="total-observations-label"
        fontSize={14}
        fill={theme === 'dark' ? '#ffffff' : '#1F2321'}
        opacity={0.7}
      >
        {`${i18n.t('chart.title_observations')}`}
      </Text>
    </g>
  );
  const highest = chartData.reduce(
    (prev, current) => (prev && prev.count > current.count ? prev : current),
    { name: '', count: 0 },
  );

  const selectedScenario = scenariosList.find(
    (item) => item.value === highest.name,
  );
  const percentage = ((highest?.count || 0) / totalObservations) * 100;

  return (
    <Col md={4} className="mb-32">
      <Card
        className={`card-${theme} border border-${theme} border-radius h-100 placeholder-glow`}
      >
        <Card.Body
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Card.Text className="text-center weight-600">
            {i18n.t('analytics_overview.total_observations')}
          </Card.Text>
          {isLoading ? (
            <CustomPlaceholder
              style={{
                margin: '5px auto',
                borderRadius: '50%',
                width: 240,
                height: 240,
              }}
            />
          ) : (
            <ResponsiveContainer
              height={250}
              className="ph-no-capture donut-chart"
            >
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="count"
                  nameKey="name"
                  innerRadius={100}
                  outerRadius={120}
                  paddingAngle={chartData.length === 1 ? 0 : 4}
                  cornerRadius={chartData.length === 1 ? 0 : 2}
                  labelLine={false}
                  strokeWidth={0}
                  label={renderCustomLabel}
                  onClick={(dataValue) => {
                    const scenarioValue = scenariosList.find(
                      (scenario) => scenario.value === dataValue.name,
                    );

                    if (scenarioValue) {
                      const filteredChartData = chartData.find(
                        (scenario) => scenario.name === scenarioValue.value,
                      );
                      trackBarClick('donut_bar_click', {
                        timeRange: {
                          title: timeRange.text,
                          value: timeRange.value,
                          end: timeRange.end,
                        },
                        scenarios: filteredChartData?.name,
                      });
                      navigateToGroupScenario({
                        timeRange,
                        scenarioGroupId: filteredChartData?.id,
                        cameraIds,
                      });
                    }
                  }}
                >
                  {chartData.map((chart) => {
                    const scenario = SUPPORTED_SCENARIOS.find(
                      (select) => select.value === chart.name,
                    );
                    return scenario ? (
                      <Cell
                        key={scenario.value}
                        fill={scenario.color}
                        className="add-cursor"
                      />
                    ) : null;
                  })}
                </Pie>
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  content={
                    <DonutTooltip totalObservations={totalObservations} />
                  }
                />
              </PieChart>
            </ResponsiveContainer>
          )}

          <div
            style={{
              flex: '1 1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (selectedScenario) {
                const scenarioGroupId = chartData.find(
                  (scenario) => scenario.name === selectedScenario.value,
                );
                trackBarClick('donut_bar_click', {
                  timeRange: {
                    title: timeRange.text,
                    value: timeRange.value,
                    end: timeRange.end,
                  },
                  scenarios: scenarioGroupId?.name,
                });
                navigateToGroupScenario({
                  timeRange,
                  scenarioGroupId: scenarioGroupId?.id,
                  cameraIds,
                });
              }
            }}
            aria-hidden
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <CustomPlaceholder
                  style={{
                    margin: '2px 0',
                    height: 16,
                    width: 60,
                  }}
                />
              ) : (
                <>
                  <div
                    className="me-1"
                    style={{
                      width: 10,
                      height: 10,
                      background: selectedScenario?.color,
                    }}
                  />
                  <p className="m-0 ">{i18n.t('analytics_overview.most')}</p>
                </>
              )}
            </div>
            {isLoading ? (
              <CustomPlaceholder
                style={{
                  margin: '2px 0',
                  height: 16,
                }}
              />
            ) : (
              <p className="m-0 ">{`${i18n.t(selectedScenario?.title || '')} — ${percentage.toFixed(2)}% (${highest?.count})`}</p>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
