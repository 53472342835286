import { useMutation } from '@apollo/client';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendOTP } from '../../apis/api-request';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { USER_UPDATE_GQL } from '../../graphql/mutations/user';
import { UserProp } from '../../typescript/user/user';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';
import InputField from '../elements/InputField';
import { OtpModal } from '../modal/OtpModal';

interface Props {
  user: UserProp;
  editable?: boolean | undefined;
}

export function UserForm({ user, editable }: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { userCookie, saveUser, refetchUser } = useAuthContext();

  const phoneInputRef = useRef<string>();

  const [newUser, setNewUser] = useState<UserProp>(user);

  const [loading, setLoading] = useState(false);
  const [loadingOtpModal, setLoadingOtpModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const [updateUser, onUpdateUser] = useMutation(USER_UPDATE_GQL);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setNewUser({ ...newUser, [name]: e.target.value });
  };

  const handlePhoneChange = (value: string) => {
    setNewUser({ ...newUser, phoneNo: value, phoneNoVerified: false });
    phoneInputRef.current = value;
  };

  useEffect(() => {
    setLoading(false);
    if (onUpdateUser.data) {
      toast.success(i18n.t('toast.success.user_update'));
      saveUser(newUser);
    }
  }, [onUpdateUser.data]);

  const updateUserInfo = (e?: any) => {
    e?.preventDefault();
    setLoading(true);
    updateUser({
      variables: {
        id: newUser.id,
        title: newUser.title,
        name: newUser.username,
        phone_no: newUser.phoneNo,
        phone_no_verified: newUser.phoneNoVerified,
      },
    });
  };

  const handleOtpModalOpen = async () => {
    setLoadingOtpModal(true);
    const response = await sendOTP(userCookie.userToken, {
      phone_number: newUser.phoneNo,
    });
    setLoadingOtpModal(false);
    if (response.success) {
      setShowOtpModal(true);
    } else {
      toast.error(response.detail);
    }
  };

  return (
    <Form onSubmit={updateUserInfo} className="user-information">
      <InputField
        controlId="formFullName"
        className={`mb-12 input-${theme} ph-no-capture`}
        name="username"
        value={newUser?.username}
        title={`${i18n.t('input.username.title')}`}
        placeholder={`${i18n.t('input.username.placeholder')}`}
        onChange={handleChange}
        disabled={editable}
        required
      />

      <InputField
        controlId="formTitle"
        className={`mb-12 input-${theme} ph-no-capture`}
        name="title"
        value={newUser?.title}
        title={`${i18n.t('input.user_title.title')}`}
        placeholder={`${i18n.t('input.user_title.placeholder')}`}
        onChange={handleChange}
        disabled={editable}
        required
      />

      {!editable && (
        <div className="mb-12 phone-verification">
          <Form.Label className="weight-500">{`${i18n.t('input.phoneNo.title')}`}</Form.Label>
          <InputGroup>
            <PhoneInput
              controlId="formPhoneNumber"
              className={`flex-1 input-${theme} ph-no-capture`}
              name="phoneNo"
              title={`${i18n.t('input.phoneNo.title')}`}
              international
              placeholder={`${i18n.t('input.phoneNo.placeholder')}`}
              disabled={editable}
              value={newUser?.phoneNo}
              onChange={handlePhoneChange}
            />
            {(!user.phoneNoVerified || user.phoneNo !== newUser.phoneNo) && (
              <CustomButton
                className="position-absolute end-0"
                variant={`outline-${theme}`}
                type="button"
                title={!loadingOtpModal && i18n.t('button.verify')}
                disabled={loadingOtpModal}
                loading={loadingOtpModal}
                onClick={handleOtpModalOpen}
              />
            )}
          </InputGroup>
        </div>
      )}

      <InputField
        controlId="formEmail"
        className={`mb-12 input-${theme} ph-no-capture`}
        type="email"
        name="email"
        value={newUser.email}
        title={`${i18n.t('input.email.title')}`}
        placeholder={`${i18n.t('input.email.placeholder')}`}
        disabled
        readOnly
        required
      />

      {/* <InputField
        controlId="formRole"
        className={`input-${theme}`}
        value={newUser?.isAdmin ? 'Admin' : 'Non-Admin'}
        title={`${i18n.t('input.role.title')}`}
        placeholder={`${i18n.t('input.role.placeholder')}`}
        disabled
        readOnly
        required
      /> */}

      {!editable && (
        <div className="d-flex user-information-action mt-32">
          <CustomButton
            title={i18n.t('button.cancel')}
            className="button-with-loader"
            variant={`outline-${theme}`}
            type="button"
            onClick={() => navigate('/dashboard')}
          />
          <CustomButton
            title={i18n.t('button.save')}
            className="button-with-loader ms-12"
            variant="primary"
            type="submit"
            disabled={loading}
            loading={loading}
          />
        </div>
      )}

      <OtpModal
        show={showOtpModal}
        onClose={() => setShowOtpModal(false)}
        onVerify={async () => {
          await refetchUser();
          setShowOtpModal(false);
        }}
      />
    </Form>
  );
}
