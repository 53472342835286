/* eslint-disable max-len */
import React, { FC, useContext } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import { AuthContext } from '../../../../contextapi/AuthProvider';
import { ThemeContext } from '../../../../contextapi/ThemeProvider';
import { AuthContextType } from '../../../../typescript/context/auth';
import { ThemeContextType } from '../../../../typescript/context/theme';
import i18n from '../../../../utils/i18n';
import { CustomButton } from '../../../elements/CustomButton';

interface Props {
  show: boolean;
  onButtonClick: (() => void) | undefined;
}

const NoScenarioModal: FC<Props> = function NoScenarioModal({
  show,
  onButtonClick,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;

  const scenarioArray = scenariosList.filter(
    (isScenario) => isScenario.isDangerZone && isScenario.isDangerZone,
  );
  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={show}
      onHide={onButtonClick}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.edit_scenarios.title')}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={onButtonClick}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <p className="weight-600">{i18n.t('modal.no_scenario.title')}</p>

            <div className="scenario-tag">
              <ul>
                {scenarioArray.map((scenario) => (
                  <li
                    className="scenario-list me-2 mb-12"
                    key={scenario.value}
                    style={{
                      backgroundColor:
                        theme === 'light'
                          ? scenario.colorLight
                          : scenario.color,
                      color: theme === 'light' ? scenario.color : '#fff',
                    }}
                  >
                    {i18n.t(scenario.title)}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default NoScenarioModal;
