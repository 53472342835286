import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import Loader from './Loader';

export type DropdownProps = {
  title: string | number;
  value: string | number | boolean;
  isDisable?: boolean;
  subMenu?: Array<{
    title: string | number;
    value: string | number | boolean;
  }>;
};

interface Props {
  mainClassName?: string;
  className?: string;
  hideContextData?: string;
  variant?: string;
  bodyVariant?: string;
  title?: string;
  dropdownData: Array<DropdownProps>;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  rightIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  loading?: boolean;
  disabled?: boolean;
  isThreeDot?: boolean;
  onClick?: (value: DropdownProps) => any | undefined;
}
const CustomDropdownWithSubMenu: FC<Props> =
  function CustomDropdownWithSubMenu({
    mainClassName,
    className,
    hideContextData,
    variant,
    bodyVariant,
    title,
    dropdownData,
    icon: Icon,
    rightIcon: RightIcon,
    loading,
    disabled,
    isThreeDot,
    onClick,
  }) {
    return (
      <Dropdown
        className={`${isThreeDot && `${mainClassName} three-dot-dropdown`}`}
      >
        <Dropdown.Toggle
          className={`${className} rounded-pill icon button-with-loader`}
          style={isThreeDot ? { width: 40, height: 40, padding: 0 } : undefined}
          variant={variant}
          id="dropdown-basic"
          disabled={loading || disabled}
        >
          {Icon && <Icon />}
          {title}
          {RightIcon && <RightIcon className="ms-2" />}
          {loading && <Loader className="ms-12" />}
        </Dropdown.Toggle>

        <Dropdown.Menu variant={bodyVariant}>
          {dropdownData.map((item) => {
            if (item.subMenu) {
              return (
                <Dropdown
                  key={item.title}
                  className="child-dropdown-toggle"
                  drop="end"
                >
                  <Dropdown.Toggle
                    variant={variant}
                    id={`dropdown-basic-${item.value}`}
                    disabled={loading || disabled}
                  >
                    {item.title}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant={bodyVariant}>
                    {item.subMenu.map((childItem) => (
                      <Dropdown.Item
                        variant="outline-secondary"
                        className={`py-2 ${hideContextData}`}
                        key={childItem.title}
                        onClick={() =>
                          onClick &&
                          onClick({
                            title: item.title,
                            value: item.value,
                            subMenu: [childItem],
                          })
                        }
                      >
                        {childItem.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              );
            }

            return (
              <Dropdown.Item
                disabled={item.isDisable}
                variant="outline-secondary"
                className={`py-2 ${hideContextData}`}
                key={item.title}
                onClick={() => onClick && onClick(item)}
              >
                {item.title}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

CustomDropdownWithSubMenu.defaultProps = {
  mainClassName: '',
  className: '',
  hideContextData: '',
  variant: '',
  bodyVariant: '',
  title: '',
  loading: false,
  disabled: false,
  icon: undefined,
  rightIcon: undefined,
  isThreeDot: false,
  onClick: undefined,
};

export default CustomDropdownWithSubMenu;
