export type ModalType = {
  title: string;
  body: string;
  buttonType: string;
  doneText: string;
  cancelText: string;
  show: boolean;
  loading?: boolean;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const defaultModalValue = {
  title: '',
  body: '',
  buttonType: '',
  doneText: '',
  cancelText: '',
  show: false,
  icon: undefined,
};
