import { useNavigate } from 'react-router-dom';
import { DaysListProps } from '../typescript/grouping/grouping-observation';

type NavigateToObservationProps = {
  timeRange: Pick<DaysListProps, 'value' | 'end'>;
  scenarioIds: Array<number>;
  scenarioGroupId?: number;
  cameraIds?: Array<number>;
};

type NavigateToGroupScenarioProps = {
  timeRange: Pick<DaysListProps, 'value' | 'end' | 'text'>;
  scenarioGroupId?: number;
  cameraIds?: Array<number>;
};

const createQueryString = ({
  timeRange,
  scenarioIds,
  cameraIds,
}: NavigateToObservationProps) => {
  const param = {
    system_timestamp: {
      _gte: timeRange.value,
      _lte: timeRange.end,
    },
    customer_scenario_label_id: {
      _in: scenarioIds,
    },
    camera_id: cameraIds && { _in: cameraIds },
  };

  const filter = `&filter=${JSON.stringify(param)}`;
  const encodeUrl = encodeURI(filter);

  return `?type=${0}&pageNo=${1}&limit=${10}${encodeUrl}`;
};

const createScenarioGroupQueryString = ({
  timeRange,
  cameraIds,
}: NavigateToGroupScenarioProps) => {
  const query = {
    title: timeRange.text,
    range: [timeRange.value, timeRange.end],
  };
  const camera = cameraIds
    ? cameraIds.map((item) => ({
        value: item,
        isChecked: true,
      }))
    : [];

  return `?pageNo=${1}&limit=${10}&query=${encodeURI(JSON.stringify(query))}&cameraIds=${encodeURI(JSON.stringify(camera))}`;
};

export const useNavigateToObservation = () => {
  const navigate = useNavigate();

  return {
    navigateToObservation: ({
      timeRange,
      scenarioIds,
      cameraIds,
    }: NavigateToObservationProps) => {
      const queryString = createQueryString({
        timeRange,
        scenarioIds,
        cameraIds,
      });

      navigate({
        pathname: '/observations',
        search: queryString,
      });
    },
    navigateToGroupScenario: ({
      scenarioGroupId,
      timeRange,
      cameraIds,
    }: NavigateToGroupScenarioProps) => {
      const scenarioGroupQueryString = createScenarioGroupQueryString({
        timeRange,
        cameraIds,
      });

      navigate({
        pathname: `/group-observations/${scenarioGroupId}`,
        search: scenarioGroupQueryString,
      });
    },
  };
};
