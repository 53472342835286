import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraUpdateContext } from '../../contextapi/CameraUpdateProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';
import { ActiveScenarios } from '../cameras/settings/ActiveScenarios';
import CameraScenariosSchedule from '../cameras/settings/CameraScenariosSchedule';
import CameraSchedule from '../cameras/settings/CameraSchedule';
import DangerZone from '../cameras/settings/DangerZone';
import RemoveCamera from '../cameras/settings/RemoveCamera';
import ResetBackgroundImage from '../cameras/settings/ResetBackgroundImage';
import ResetCamera from '../cameras/settings/ResetCamera';
import { CustomButton } from '../elements/CustomButton';
import { Loader } from '../elements/Loader';

interface Props {
  cameraLegacyId: string;
  show: boolean;
  onCloseClick: () => void;
}

export function CameraSettingsModal({
  cameraLegacyId,
  show,
  onCloseClick,
}: Props) {
  const { user } = useAuthContext();
  const { getCamera } = useCameraUpdateContext();
  const { theme } = useThemeContext();
  const [hideModal, setHideModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const subscribe = async () => {
      setLoading(true);
      await getCamera(cameraLegacyId);
      setLoading(false);
    };
    subscribe();
  }, [cameraLegacyId]);

  const onHideModal = useCallback(() => {
    setHideModal(!hideModal);
  }, [hideModal]);

  return (
    <Modal
      centered
      contentClassName={theme}
      className={`${hideModal && 'd-none'} custom-modal`}
      show={show}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.setting.title')}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center">
        {loading && <Loader main className="mx-auto" />}
        {!loading && (
          <Row>
            <ActiveScenarios hideParentModal={onHideModal} />
            <DangerZone hideParentModal={onHideModal} />
            <CameraSchedule hideParentModal={onHideModal} />
            <CameraScenariosSchedule hideParentModal={onHideModal} />
            {user?.customer.feature_flags.cameras?.set_site_button && (
              <ResetBackgroundImage
                hideParentModal={onHideModal}
                onCloseClick={onCloseClick}
              />
            )}

            {user?.customer.feature_flags.cameras?.init_site && (
              <ResetCamera hideParentModal={onHideModal} />
            )}
            <RemoveCamera
              hideParentModal={onHideModal}
              onCloseClick={onCloseClick}
            />
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
