/* eslint-disable import/no-mutable-exports */
import { Cookies } from 'react-cookie';

const cookie = new Cookies();
let siteId = cookie.get('siteId') || '';

function setSiteId(value: string) {
  siteId = value;
  cookie.set('siteId', value, { path: '/' });
}

export { siteId, setSiteId };
