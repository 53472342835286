/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { FC, useContext } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import i18n from '../../utils/i18n';

interface Props {
  active?: boolean;
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
  label?: any;
}
const CustomLegend: FC<Props> = function CustomLegend({
  active,
  payload,
  label,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;

  const scenarioList = payload && payload.map((item) => item.dataKey);

  const filteredScenario =
    scenarioList &&
    scenariosList.filter((element) => scenarioList.includes(element.value));

  if (payload) {
    return (
      <div className="d-flex justify-content-center mt-12">
        {filteredScenario?.map((item) => (
          <span
            key={item.value}
            className="scenario-list d-flex mx-2"
            style={{
              backgroundColor: theme === 'light' ? item.colorLight : item.color,
              color: theme === 'light' ? item.color : '#fff',
            }}
          >
            {`${i18n.t(item.title)}`}
          </span>
        ))}
      </div>
    );
  }
  return null;
};

CustomLegend.defaultProps = {
  active: undefined,
  payload: undefined,
  label: undefined,
};

export default CustomLegend;
