/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { ReactComponent as ActiveArrow } from '../../../assets/icons/active-left-arrow.svg';
import { ReactComponent as InActiveArrow } from '../../../assets/icons/inactive-left-arrow.svg';
import { ReactComponent as LegendBar } from '../../../assets/icons/legend-bar.svg';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { CamerasScenarios } from '../../../hooks/graphql/camera';
import {
  GRAY_MIDDLE,
  GRAY_SUBTLE,
  GRAY_WARM_DARKER_AA,
} from '../../../scss/colors';
import { spacing } from '../../../scss/spacing';
import { ScenarioName } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';
import { CustomSwitch } from '../../elements/CustomSwitch';
import { HeatMapImageWithDangerZone } from '../HeatMapImageWithDangerZone';
import { TitleWithContent } from '../TitleWithContent';

type Props = {
  cameraId: number;
  scenarioId?: number;
  scenariosWithDetections: Array<CamerasScenarios>;
  onScenarioChange?: (scenario: ScenarioName) => void;
};

export function ExtendedCameraHeatMap({
  cameraId,
  scenarioId,
  scenariosWithDetections,
  onScenarioChange,
}: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();

  const scenarioNames = scenariosWithDetections.map(
    (scenario) => scenario.customer_scenario_label.name,
  );
  const scenarioModels = scenariosList.filter((element) =>
    scenarioNames.includes(element.value),
  );
  const defaultScenario = scenarioModels.find(
    (scenario) => scenario.id === scenarioId,
  );

  const [selectedScenario, setSelectedScenario] = useState(defaultScenario);
  const [isDangerZone, setIsDangerZone] = useState<boolean>(false);

  useEffect(() => {
    const scenario = scenarioModels.find(
      (scenario) => scenario.id === scenarioId,
    );

    setSelectedScenario(scenario);
  }, [scenarioId, scenarioModels]);

  return (
    <Card className={`card-${theme} border border-${theme} border-radius`}>
      <Card.Body
        className="g-0"
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          position: 'relative',
          padding: 0,
        }}
      >
        <Col
          md={9}
          style={{
            background: 'black',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HeatMapImageWithDangerZone
            cameraId={cameraId}
            selectedScenario={selectedScenario}
            showDangerZones={isDangerZone}
            scenariosWithDetections={scenariosWithDetections}
          />
        </Col>
        <Col
          md={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <h3
            style={{
              height: spacing(9),
              padding: spacing(4),
              margin: 0,
              borderBottom: `1px solid ${theme === 'light' ? GRAY_MIDDLE : GRAY_WARM_DARKER_AA}`,
            }}
          >
            {`${i18n.t('camera_detail.scenarios')} (${scenarioModels.length})`}
          </h3>
          <div
            style={{
              overflowX: 'auto',
              flex: '1 1 0',
              marginBottom: -1,
              minHeight: 120,
            }}
          >
            {scenarioModels.map((scenario, index) => (
              <CustomButton
                css={css`
                  border-width: ${index === 0 ? '1px' : '0'} 0 1px 0;
                  width: 100%;
                  justify-content: flex-start;
                  height: 48px;
                  border-radius: 0;
                  box-shadow: none !important;
                  font-weight: 500 !important;

                  :hover {
                    border-color: ${theme === 'light'
                      ? GRAY_MIDDLE
                      : GRAY_WARM_DARKER_AA};
                    background: ${theme === 'light'
                      ? GRAY_SUBTLE
                      : GRAY_WARM_DARKER_AA};
                  }

                  :first-child {
                    border-top: 0;
                  }
                `}
                isRounded
                key={scenario.value}
                className="button-with-loader"
                icon={
                  scenario.id === selectedScenario?.id
                    ? ActiveArrow
                    : InActiveArrow
                }
                iconSpacing={3}
                variant={`outline-${theme}`}
                title={i18n.t(scenario.title)}
                onClick={() => {
                  setSelectedScenario(scenario);
                  if (onScenarioChange) {
                    onScenarioChange(scenario.value);
                  }
                }}
              />
            ))}
          </div>

          <TitleWithContent
            title={`${i18n.t('camera_detail.display_options')}`}
          >
            <CustomSwitch
              label={`${i18n.t('switch.danger_zone')}`}
              checked={isDangerZone}
              onChange={() => setIsDangerZone(!isDangerZone)}
            />
          </TitleWithContent>

          <TitleWithContent title={`${i18n.t('camera_detail.legend')}`}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <p className="m-0 me-12">{i18n.t('camera_detail.legend.less')}</p>
              <LegendBar />
              <p className="m-0 ms-12">{i18n.t('camera_detail.legend.more')}</p>
            </div>
          </TitleWithContent>
        </Col>
      </Card.Body>
    </Card>
  );
}
