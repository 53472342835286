import { gql } from '@apollo/client';

export const UPDATE_GROUP = gql`
  mutation UPDATE($groupId: Int, $column: String, $value: String) {
    observation_groups_bulk_update(
      group_id: $groupId
      column: $column
      value: $value
      timestamp: 0
    ) {
      message
      status
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DELETE($groupId: Int) {
    observation_groups_bulk_delete_observations(
      group_id: $groupId
      timestamp: 0
    ) {
      message
      status
    }
  }
`;

export const UPDATE_OBSERVATION = gql`
  mutation GET_OBSERVATION($id: Int!, $data: observations_set_input) {
    update_observations(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        priority
      }
      returning {
        id
        imageUrl: image_url
        priority
        requestId: request_id
        responder
        status
        system_timestamp
        created
        is_false_positive
        customer_scenario_label {
          name
          scenario {
            name
          }
        }
        camera {
          name
        }
      }
    }
  }
`;

export const UPDATE_OBSERVATIONS = gql`
  mutation GET_OBSERVATIONS(
    $observationIds: [Int!]
    $data: observations_set_input
  ) {
    update_observations_many(
      updates: { where: { id: { _in: $observationIds } }, _set: $data }
    ) {
      affected_rows
      returning {
        id
        imageUrl: image_url
        priority
        requestId: request_id
        responder
        status
        system_timestamp
        created
        customer_scenario_label {
          name
          scenario {
            name
          }
        }
        camera {
          name
        }
      }
    }
  }
`;

export const DELETE_OBSERVATION = gql`
  mutation DELETE($id: Int!) {
    delete_observations_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETE_OBSERVATIONS = gql`
  mutation DELETE($observationIds: [Int!]) {
    delete_observations(where: { id: { _in: $observationIds } }) {
      affected_rows
      returning {
        camera {
          id
        }
      }
    }
  }
`;

export type ObservationsSetInput = {
  comments?: string;
  is_false_positive?: boolean;
  message?: string;
  priority?: string;
  responder?: string;
  status?: string;
  timeline?: string;
};
