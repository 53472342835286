/* eslint-disable no-param-reassign */
import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Dropdown, FormCheck, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { CameraUpdateContext } from '../../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { CameraContextType } from '../../../typescript/context/camera';
import { ThemeContextType } from '../../../typescript/context/theme';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';

type ScheduleProps = {
  [key: string]: string[];
};
interface Props {
  hideParentModal: () => void;
}

const CameraSchedule: FC<Props> = function CameraSchedule({ hideParentModal }) {
  const { camera, onCameraUpdate, loading, setLoading } = useContext(
    CameraUpdateContext,
  ) as CameraContextType;
  const { theme } = useContext(ThemeContext) as ThemeContextType;

  const [show, setShow] = useState<boolean>(false);

  const [daysTypeArray, setDaysTypeArray] = useState([
    { value: 'radiobox.all_week', isChecked: false },
    { value: 'radiobox.custom_days', isChecked: true },
  ]);
  const [timeTypeArray, setTimeTypeArray] = useState([
    { value: 'radiobox.all_time', isChecked: false },
    { value: 'radiobox.custom_time', isChecked: true },
  ]);
  const daysArray: ScheduleProps = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  const [daysArrayValue, setDaysArrayValue] =
    useState<ScheduleProps>(daysArray);

  const handleClose = (isUpdate: boolean) => {
    hideParentModal();
    if (!isUpdate) {
      // setScheduleArray(filteredArray());
    }
    setShow(false);
  };
  const handleShow = () => {
    hideParentModal();
    setShow(true);
  };

  function updateCameraSchedule() {
    if (camera) {
      if (camera.schedule) {
        const { cameraSchedule } = JSON.parse(camera.schedule);
        if (cameraSchedule) {
          const scheduled = cameraSchedule as ScheduleProps;

          if (Object.keys(scheduled).length === 7) {
            const updatedDays = [...daysTypeArray];
            updatedDays[0].isChecked = true;
            updatedDays[1].isChecked = false;
            setDaysTypeArray(updatedDays);
          }
          if (JSON.stringify(scheduled) === JSON.stringify(daysArray)) {
            const updatedItems = [...timeTypeArray];
            updatedItems[0].isChecked = true;
            updatedItems[1].isChecked = false;
            setTimeTypeArray(updatedItems);
          }

          setDaysArrayValue(scheduled);
        }
      }
    }
  }
  useEffect(() => {
    updateCameraSchedule();
  }, [camera]);

  const range = (start: number, stop: number) =>
    Array.from({ length: stop - start + 1 }, (_, i) => start + i);

  function updateTime(parentIndex: number, time: string, index: number) {
    const newArr = { ...daysArrayValue };
    newArr[parentIndex][index] = time;
    setDaysArrayValue(newArr);
  }

  const onCameraUpdateSchedule = async () => {
    const schedule = JSON.parse(camera.schedule);
    schedule.cameraSchedule = daysArrayValue;
    camera.schedule = JSON.stringify(schedule);

    setLoading(true);
    await onCameraUpdate(camera);
    handleClose(false);
    setLoading(false);
  };

  const mergeObjects = (obj1: any, obj2: any) => {
    const mergedObj: { [key: string]: string[] } = { ...obj1 };

    Object.keys(obj2).forEach((key) => {
      mergedObj[key] = [...obj2[key]];
    });
    return mergedObj;
  };

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{`${i18n.t('camera.schedule.title')}`}</p>
      <p className="sub-color">{i18n.t('camera.schedule.body')}</p>

      <CustomButton
        icon={AddIcon}
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.add_schedule')}
        onClick={handleShow}
      />

      <Modal
        centered
        contentClassName={theme}
        className="custom-modal schedule-modal"
        show={show}
        onHide={() => handleClose(false)}
      >
        <Modal.Header>
          <Modal.Title>{i18n.t('modal.add_new_schedule.title')}</Modal.Title>
          <CustomButton
            icon={CancelIcon}
            className="border-0"
            type="button"
            onClick={() => handleClose(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p className="mb-1 weight-600">
                {i18n.t('modal.add_new_schedule.days')}
              </p>
              <p className="sub-color">
                {i18n.t('modal.add_new_schedule.days.body')}
              </p>

              {daysTypeArray.map((item, index) => (
                <FormCheck
                  checked={item.isChecked}
                  key={`${item.value}`}
                  label={i18n.t(item.value)}
                  className="weight-600"
                  name="DayGroup"
                  type="radio"
                  onClick={() => {
                    if (index === 0) {
                      setDaysArrayValue(
                        mergeObjects(daysArray, daysArrayValue),
                      );
                    } else {
                      updateCameraSchedule();
                    }

                    const updatedItems = [...daysTypeArray];
                    updatedItems[index] = {
                      ...updatedItems[index],
                      isChecked: true,
                    };
                    updatedItems.forEach((update, i) => {
                      if (i !== index) {
                        update.isChecked = false;
                      }
                    });
                    setDaysTypeArray(updatedItems);
                  }}
                />
              ))}

              {daysTypeArray[1].isChecked &&
                Object.entries(daysArray).map(([day]) => {
                  const monday = day === '0' ? 'Monday' : '';
                  const tuesday = day === '1' ? 'Tuesday' : '';
                  const wednesday = day === '2' ? 'Wednesday' : '';
                  const thrusday = day === '3' ? 'Thursday' : '';
                  const friday = day === '4' ? 'Friday' : '';
                  const saturday = day === '5' ? 'Saturday' : '';
                  const sunday = day === '6' ? 'Sunday' : '';
                  return (
                    <FormCheck
                      key={day}
                      className="check-box"
                      label={`${monday}${tuesday}${wednesday}${thrusday}${friday}${saturday}${sunday}`}
                      onClick={() => {
                        const updatedDaysArray = { ...daysArrayValue };
                        if (day in updatedDaysArray) {
                          delete updatedDaysArray[day];
                        } else {
                          updatedDaysArray[day] = [];
                        }
                        setDaysArrayValue(updatedDaysArray);
                      }}
                      checked={day in daysArrayValue}
                    />
                  );
                })}
              <hr />
            </Col>

            <Col md={12}>
              <p className="mb-1 weight-600">
                {i18n.t('modal.add_new_schedule.time')}
              </p>
              <p className="sub-color">
                {i18n.t('modal.add_new_schedule.time.body')}
              </p>

              {timeTypeArray.map((item, index) => (
                <FormCheck
                  checked={item.isChecked}
                  key={`${item}`}
                  label={i18n.t(item.value)}
                  className="weight-600"
                  name="TimeGroup"
                  type="radio"
                  onClick={() => {
                    if (index === 0) {
                      const transformedDaysArrayValue: ScheduleProps =
                        Object.fromEntries(
                          Object.entries(daysArrayValue).map(([key]) => [
                            key,
                            [],
                          ]),
                        );
                      setDaysArrayValue(transformedDaysArrayValue);
                    } else {
                      // updateCameraSchedule();
                    }

                    const updatedItems = [...timeTypeArray];
                    updatedItems[index] = {
                      ...updatedItems[index],
                      isChecked: true,
                    };
                    updatedItems.forEach((update, i) => {
                      if (i !== index) {
                        update.isChecked = false;
                      }
                    });
                    setTimeTypeArray(updatedItems);
                  }}
                />
              ))}

              {timeTypeArray[1].isChecked &&
                Object.entries(daysArrayValue).map(([day, timeArray]) => {
                  const monday = day === '0' ? i18n.t('weekdays.monday') : '';
                  const tuesday = day === '1' ? i18n.t('weekdays.tuesday') : '';
                  const wednesday =
                    day === '2' ? i18n.t('weekdays.wednesday') : '';
                  const thrusday =
                    day === '3' ? i18n.t('weekdays.thursday') : '';
                  const friday = day === '4' ? i18n.t('weekdays.friday') : '';
                  const saturday =
                    day === '5' ? i18n.t('weekdays.saturday') : '';
                  const sunday = day === '6' ? i18n.t('weekdays.sunday') : '';
                  return (
                    <div key={`${day}`} className="mt-24">
                      <p className="mb-2 weight-600 d-flex align-items-center">
                        {`${monday}${tuesday}${wednesday}${thrusday}${friday}${saturday}${sunday}:`}{' '}
                        <AddIcon
                          key="warning"
                          className="w-auto ps-12 pe-12 icon"
                          onClick={() => {
                            const lastValue = `${daysArrayValue[day].slice(-1)}`;
                            if (
                              lastValue === '' &&
                              daysArrayValue[day].length !== 0
                            ) {
                              toast.warning('Please fill the time');
                            } else {
                              const updatedDaysArray = { ...daysArrayValue };
                              updatedDaysArray[day].push('');
                              setDaysArrayValue(updatedDaysArray);
                            }
                          }}
                        />
                      </p>
                      {timeArray.map((originalTime: string, index: number) => {
                        const fullTime =
                          originalTime === ''
                            ? '00:00-00:00'.split('-')
                            : originalTime.split('-');
                        const from = fullTime[0].split(':');
                        const to = fullTime[1].split(':');
                        return (
                          <div
                            key={originalTime}
                            className="d-flex align-items-center mb-12"
                          >
                            {/* from */}
                            <p className="m-0">from</p>
                            <Dropdown className="ms-2 interval-dropdowns">
                              <Dropdown.Toggle
                                className="rounded-pill"
                                variant={`outline-${theme}`}
                                id="dropdown-basic"
                              >
                                {/* Hours */}
                                {from[0]}
                              </Dropdown.Toggle>

                              <Dropdown.Menu variant={theme}>
                                {[...Array(24)].map((x, hours) => {
                                  const time = String(hours + 0).padStart(
                                    2,
                                    '0',
                                  );
                                  return (
                                    <Dropdown.Item
                                      key={x}
                                      onClick={() => {
                                        updateTime(
                                          Number(day),
                                          `${time}:${from[1]}-${fullTime[1]}`,
                                          index,
                                        );
                                      }}
                                    >
                                      {`${time} h`}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className="ms-2 me-3 interval-dropdowns">
                              <Dropdown.Toggle
                                className="rounded-pill"
                                variant={`outline-${theme}`}
                                id="dropdown-basic"
                              >
                                {/* minutes */}
                                {from[1]}
                              </Dropdown.Toggle>

                              <Dropdown.Menu variant={theme}>
                                {[...Array(60)].map((x, minutes) => {
                                  const time = String(minutes).padStart(2, '0');
                                  return (
                                    <Dropdown.Item
                                      key={x}
                                      onClick={() => {
                                        updateTime(
                                          Number(day),
                                          `${from[0]}:${time}-${fullTime[1]}`,
                                          index,
                                        );
                                      }}
                                    >
                                      {`${time} m`}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>

                            {/* To */}
                            <p className="m-0">to</p>
                            <Dropdown className="ms-2 interval-dropdowns">
                              <Dropdown.Toggle
                                className="rounded-pill"
                                variant={`outline-${theme}`}
                                id="dropdown-basic"
                              >
                                {/* Hours */}
                                {to[0]}
                              </Dropdown.Toggle>

                              <Dropdown.Menu variant={theme}>
                                {range(Number(from[0]), 23).map((hours) => {
                                  const time = String(hours).padStart(2, '0');
                                  return (
                                    <Dropdown.Item
                                      key={hours}
                                      onClick={() => {
                                        updateTime(
                                          Number(day),
                                          `${fullTime[0]}-${time}:${to[1]}`,
                                          index,
                                        );
                                      }}
                                    >
                                      {`${time} h`}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className="ms-2 interval-dropdowns">
                              <Dropdown.Toggle
                                className="rounded-pill"
                                variant={`outline-${theme}`}
                                id="dropdown-basic"
                              >
                                {/* minutes */}
                                {to[1]}
                              </Dropdown.Toggle>

                              <Dropdown.Menu variant={theme}>
                                {range(
                                  Number(from[0]) !== Number(to[0])
                                    ? 1
                                    : Number(from[1]),
                                  59,
                                ).map((minutes) => {
                                  const time = String(minutes).padStart(2, '0');
                                  return (
                                    <Dropdown.Item
                                      key={minutes}
                                      onClick={() => {
                                        updateTime(
                                          Number(day),
                                          `${fullTime[0]}-${to[0]}:${time}`,
                                          index,
                                        );
                                      }}
                                    >
                                      {`${time} m`}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>

                            {index !== 0 && (
                              <DeleteIcon
                                key="warning"
                                className="w-auto ps-12 pe-12"
                                onClick={() => {
                                  const newArr = { ...daysArrayValue };
                                  const items = daysArrayValue[
                                    Number(day)
                                  ].filter(
                                    (_, childIndex) => childIndex !== index,
                                  );
                                  newArr[Number(day)] = items;
                                  setDaysArrayValue(newArr);
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            title={i18n.t('button.back_to_settings')}
            className="button-with-loader"
            variant={`outline-${theme}`}
            type="button"
            onClick={() => handleClose(false)}
          />

          <CustomButton
            title={i18n.t('button.save_changes')}
            className="button-with-loader"
            variant="primary"
            type="button"
            onClick={onCameraUpdateSchedule}
            loading={loading}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>

      <hr />
    </Col>
  );
};

export default CameraSchedule;
