import React, { useContext, useState } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../assets/logo-dark.svg';
import lightLogo from '../assets/logo-light.svg';
import { CustomButton } from '../components/elements/CustomButton';
import InputField from '../components/elements/InputField';
import { AuthContext } from '../contextapi/AuthProvider';
import { AuthContextType } from '../typescript/context/auth';
import i18n from '../utils/i18n';

type Props = {
  theme?: string;
};

export const LoginPage = ({ theme = 'light' }: Props) => {
  const authContext = useContext(AuthContext) as AuthContextType | null;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);

  if (!authContext) {
    return null;
  }

  const { login, route, navigate } = authContext;
  const signup = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const onLogin = await login(email, password);
      if (onLogin) {
        navigate(route.from);
      } else {
        toast.error(i18n.t('toast.wrong_user_details'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="login g-0">
      <Col xs={8} sm={12} md={6} className="align-self-center mx-auto">
        <div className="login-container">
          <Image width="50%" src={theme === 'light' ? logo : lightLogo} />

          <h5 className="mb-32">{i18n.t('login.body')}</h5>

          <Form onSubmit={signup}>
            <InputField
              className="mb-12"
              controlId="formEmail"
              type="email"
              required
              title={`${i18n.t('input.email.title')}`}
              placeholder={`${i18n.t('input.email.placeholder')}`}
              onChange={(e) => setEmail(e.target.value)}
            />

            <InputField
              className="mb-24"
              type="password"
              controlId="formPassword"
              title={`${i18n.t('input.password.title')}`}
              placeholder={`${i18n.t('input.password.placeholder')}`}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="mb-3 d-flex justify-content-between">
              {/* <Form.Check
                  type="checkbox"
                  label="Remember me"
                /> */}

              <Link
                to="/forgot-password"
                className={`btn btn-link btn-link-${theme} ${theme === 'dark' ? 'white-color' : 'dark-color'}`}
              >
                {i18n.t('button.forgot')}
              </Link>
            </div>

            <CustomButton
              title={i18n.t('button.sign_in')}
              className="button-with-loader w-100"
              variant="primary"
              type="submit"
              disabled={loading}
              loading={loading}
            />
          </Form>
        </div>
      </Col>

      <Col className="login-background" md={6} />
    </Row>
  );
};
