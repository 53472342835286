import moment from 'moment';
import React, { useMemo } from 'react';
import { AnalyticsOverview } from '../components/dashboard/AnalyticsOverview';
import { SiteOverview } from '../components/dashboard/SiteOverview';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { TIME_RANGES } from '../typescript/grouping/grouping-observation';
import i18n from '../utils/i18n';
import { assertUnreachable } from '../utils/typeUtils';

export const DashboardPage = () => {
  const { user } = useAuthContext();
  const { setTimeRange, shouldSetDefaultTimeRange } = useTimeRangeContext();
  if (shouldSetDefaultTimeRange) {
    setTimeRange(TIME_RANGES[4]);
  }

  const greetings = useMemo(() => {
    const currentHour = Number(moment().format('HH'));

    switch (true) {
      case currentHour >= 6 && currentHour < 12:
        return i18n.t('text.good_morning');
      case currentHour >= 12 && currentHour < 15:
        return i18n.t('text.good_afternoon');
      case currentHour >= 15 && currentHour < 20:
        return i18n.t('text.good_evening');
      case currentHour >= 20 || currentHour < 6:
        return i18n.t('text.good_night');
      default:
        return assertUnreachable();
    }
  }, []);

  const nameOrEmail = user?.username ? user?.username : user?.email;

  return (
    <>
      <div className="d-flex mt-24">
        <div className="mb-32 flex-1 d-flex align-items-center">
          <h5 className="m-0 ph-no-capture">
            {`${greetings}${nameOrEmail && ', '}${nameOrEmail} 👋`}
          </h5>
        </div>
      </div>
      <SiteOverview />
      <AnalyticsOverview />
    </>
  );
};
