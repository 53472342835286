import { gql } from '@apollo/client';

export const ALERT_SITE_CHANNEL = gql`
  query GET {
    alert_site_channel {
      alert_channel_id
      id
      alert_channel {
        alert_type_name
      }
    }
  }
`;

export const ALERT_RULES = gql`
  query GET {
    alert_rules(order_by: { name: asc }) {
      id
      name
      number_of_observations
      period
      customer_scenario_label_id
      alert_rule_cameras {
        camera {
          id
          name
        }
      }
      alert_actions {
        alert_site_channel {
          alert_channel_id
          id
          alert_channel {
            alert_type_name
          }
        }
      }
      last_triggered
    }
  }
`;

export const ALERT_RULES_BY_ID = gql`
  query GET($id: Int!) {
    alert: alert_rules_by_pk(id: $id) {
      id
      name
      number_of_observations
      period
      customer_scenario_label_id
      alert_rule_cameras {
        camera_id
        alert_rule_id
        id
        camera {
          id
          name
        }
      }
      alert_actions {
        alert_site_channel {
          alert_channel_id
          id
          alert_channel {
            alert_type_name
          }
        }

        alert_recipients {
          id
          value
          alert_action_id
          user_id
        }

        id
        alert_site_channel_id
        alert_rule_id
      }
      last_triggered
    }
  }
`;

export const ALERT_INCIDENTS = gql`
  query GET(
    $limit: Int
    $offset: Int
    $where: alert_incidents_bool_exp
    $orderBy: order_by
  ) {
    alerts: alert_incidents(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: $orderBy }
    ) {
      id
      created_at
      alert_rule {
        id
        name
        number_of_observations
        period
        customer_scenario_label_id
        created_at
        last_triggered
        alert_rule_cameras {
          camera {
            id
            name
          }
        }
        alert_actions {
          alert_site_channel {
            alert_channel_id
            id
            alert_channel {
              alert_type_name
            }
            created_at
          }
        }
      }
      alert_rule_state
    }

    alertAggregate: alert_incidents_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    alert: alert_rules {
      id
      name
      number_of_observations
      period
      customer_scenario_label_id
      alert_rule_cameras {
        camera {
          id
          name
        }
      }
      alert_actions {
        alert_site_channel {
          alert_channel_id
          id
          alert_channel {
            alert_type_name
          }
        }
      }
      last_triggered
    }
  }
`;

export const ALERT_INCIDENTS_BY_ID = gql`
  query GET($id: Int!) {
    alert: alert_incidents_by_pk(id: $id) {
      id
      alert_incident_observations_aggregate {
        aggregate {
          count
        }
      }
      alert_rule {
        id
        name
        number_of_observations
        period
        customer_scenario_label_id
        created_at
        last_triggered
        alert_rule_cameras {
          camera {
            id
            name
          }
        }
        alert_actions {
          alert_site_channel {
            alert_channel_id
            id
            alert_channel {
              alert_type_name
            }
            created_at
          }
        }
      }
      alert_rule_state
      created_at
    }
  }
`;

export const ALERT_OBSERVATIONS_BY_INCIDENTS_ID = gql`
  query GET(
    $id: Int!
    $limit: Int
    $offset: Int
    $where: observations_bool_exp
    $orderBy: order_by
  ) {
    alert: alert_incidents_by_pk(id: $id) {
      id
      alert_incident_observations(
        limit: $limit
        offset: $offset
        where: { observation: $where }
        order_by: { observation: { system_timestamp: $orderBy } }
      ) {
        observation {
          id
          imageUrl: image_url
          priority
          requestId: request_id
          responder
          status
          system_timestamp
          created
          detections {
            location
            id
            label
            observation {
              customer_scenario_label_id
            }
          }
          customer_scenario_label {
            name
            scenario {
              name
            }
          }
          camera {
            name
          }
          observation_users {
            id
          }
        }
      }
      alert_incident_observations_aggregate(
        where: { observation: $where }
        order_by: { observation: { system_timestamp: $orderBy } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export type WhereQueryProps = {
  created_at?: { _gte: string; _lte: string };
  alert_rule?: {
    id?: { _in: Array<number> };
    customer_scenario_label_id?: { _in: Array<number> };
    alert_rule_cameras?: {
      camera_id?: { _in: Array<number> };
    };
  };
  alert_incident_observations?: {
    observation?: {
      camera_id?: { _in: Array<number> };
    };
  };
};
