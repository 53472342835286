import { useQuery } from '@apollo/client';
import { GET_ALL_USERS, GET_USER } from '../../graphql/queries/user';
import { ClientUser } from '../../typescript/observation/assignee';
import { UserProp } from '../../typescript/user/user';

type GetUserQueryResponse = {
  user: [UserProp];
};

type GetAllUsersQueryResponse = {
  users: ClientUser[];
};

export const useGetUserQuery = () =>
  useQuery<GetUserQueryResponse>(GET_USER, {
    fetchPolicy: 'network-only',
  });

export const useGetAllUsersQuery = () =>
  useQuery<GetAllUsersQueryResponse>(GET_ALL_USERS, {
    fetchPolicy: 'network-only',
  });
