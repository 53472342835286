/* eslint-disable react/display-name */
import Konva from 'konva';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import {
  Group,
  Image as KonvaImage,
  Label,
  Layer,
  Line,
  Rect,
  Stage,
  Tag,
  Text,
} from 'react-konva';
import { AuthContext } from '../../contextapi/AuthProvider';
import { AuthContextType } from '../../typescript/context/auth';
import {
  DetectionsProp,
  updatedAnnotationPointer,
  ZoneSizeProp,
} from '../../typescript/observation/observation';
import { ScenarioProps } from '../../typescript/observation/scenario';
import i18n from '../../utils/i18n';

interface Props {
  className?: string;
  imageUrl: string;
  detections: Array<DetectionsProp>;
  containerWidth?: string | number;
  containerHeight?: string | number;
  onImageLoading?: boolean;
  hideLabel?: boolean;
  isActionEnable?: boolean;
  isList?: boolean;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
  onError?: React.ReactEventHandler<HTMLImageElement>;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
}

export interface AnnotationRef {
  downloadImage(): void;
}

const ImageWithAnnotation = forwardRef<AnnotationRef, Props>(
  (
    {
      imageUrl,
      detections,
      containerWidth,
      containerHeight,
      className,
      onImageLoading,
      hideLabel,
      isActionEnable,
      isList,
      onLoad,
      onError,
      onClick,
    },
    ref,
  ) => {
    const { scenariosList } = useContext(AuthContext) as AuthContextType;
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const imageContainerRef = useRef<HTMLImageElement>(null);
    const stageRef = useRef<Konva.Stage>(null);

    const [labelConfigList, setLabelConfigList] = useState<
      Array<{
        x: number;
        y: number;
        scenario: ScenarioProps;
      }>
    >([]);
    const [zoneSize, setZoneSize] = useState<ZoneSizeProp>();
    const [konvaImage, setKonvaImage] = useState<HTMLImageElement | null>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(
      null,
    );
    const [highlightedIndexClicked, setHighlightedIndexClicked] =
      useState<boolean>(false);
    const [detectionFirstItem, setDetectionFirstItem] = useState<
      Array<{
        scenario: number;
        annotations: number[];
      }>
    >([]);

    const calculateLabelConfigs = () => {
      if (detectionFirstItem.length > 0 && labelConfigList.length === 0) {
        const newLabelConfigs = detectionFirstItem.map((item) => {
          const box = {
            x: Math.min(...item.annotations.filter((_, i) => i % 2 === 0)),
            y: Math.min(...item.annotations.filter((_, i) => i % 2 !== 0)),
            width:
              Math.max(...item.annotations.filter((_, i) => i % 2 === 0)) -
              Math.min(...item.annotations.filter((_, i) => i % 2 === 0)),
            height:
              Math.max(...item.annotations.filter((_, i) => i % 2 !== 0)) -
              Math.min(...item.annotations.filter((_, i) => i % 2 !== 0)),
          };
          const findScenario = scenariosList.find(
            (scenario) => scenario.id === item.scenario,
          );
          return {
            x: box.x + box.width / 2,
            y: box.y,
            scenario: findScenario!,
          };
        });
        setLabelConfigList(newLabelConfigs);
      }
    };

    const updateZoneSize = () => {
      if (mainContainerRef.current && !onImageLoading) {
        const checkFrameHight =
          mainContainerRef.current.clientHeight < 40 ||
          mainContainerRef.current.clientHeight ===
            mainContainerRef.current.clientWidth;
        if (mainContainerRef.current.clientWidth === 0 || checkFrameHight) {
          setTimeout(updateZoneSize, 100);
        } else {
          const param = {
            width: mainContainerRef.current.clientWidth,
            height: mainContainerRef.current.clientHeight,
          };

          setZoneSize(param);
          setLabelConfigList([]);
        }
      }
    };

    useEffect(() => {
      if (zoneSize) {
        setDetectionFirstItem(
          detections.map((item) => updatedAnnotationPointer(item, zoneSize)),
        );
      }
    }, [zoneSize]);

    useEffect(() => {
      setHighlightedIndex(null);
      setHighlightedIndexClicked(false);
    }, [imageUrl]);

    useEffect(() => {
      updateZoneSize();
      window.addEventListener('resize', updateZoneSize);
      return () => window.removeEventListener('resize', updateZoneSize);
    }, [imageContainerRef, onImageLoading, imageUrl]);

    useEffect(() => {
      calculateLabelConfigs();
    }, [detectionFirstItem]);

    const handleExport = () => {
      if (stageRef.current) {
        const uri = stageRef.current.toDataURL({
          mimeType: 'image/png',
          quality: 1.0,
        });
        const link = document.createElement('a');
        link.download = 'observation';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.warn('Konva stage not yet available');
      }
    };

    useImperativeHandle(ref, () => ({
      downloadImage() {
        return handleExport();
      },
    }));

    const handleMouseEnter = (index: number) => {
      if (isActionEnable) {
        setHighlightedIndex(index);
      }
    };

    const handleMouseLeave = () => {
      if (!highlightedIndexClicked) {
        setHighlightedIndex(null);
      }
    };

    const getPointerDirection = (labelX: number, labelY: number) => {
      if (zoneSize) {
        const margin = 30;
        if (labelY < margin && labelX < zoneSize.width - margin) return 'top';
        if (labelY > margin + 70 && labelX > zoneSize.width - margin + 70)
          return 'right';
        if (labelY > margin + 70 && labelX < margin + 70) return 'left';
      }
      return 'down';
    };

    return (
      <div
        className={`canvas-container ph-no-capture ${className}`}
        aria-hidden="true"
        onClick={onClick}
        ref={mainContainerRef}
        style={{
          position: 'relative',
          width: containerWidth,
          height: containerHeight,
        }}
      >
        <BootstrapImage
          src={imageUrl}
          crossOrigin="anonymous"
          width={containerWidth}
          height={containerHeight}
          onLoad={(e) => {
            setKonvaImage(imageContainerRef.current);

            if (onLoad) {
              onLoad(e);
            }
          }}
          onError={onError}
          ref={imageContainerRef}
          id="image"
          style={{ visibility: 'hidden' }}
        />

        {konvaImage && !onImageLoading && zoneSize && (
          <Stage
            className="image-annotoation"
            width={zoneSize.width}
            height={zoneSize.height}
            ref={stageRef}
          >
            <Layer>
              <Rect
                width={zoneSize.width}
                height={zoneSize.height}
                fill="black"
              />
              <Group
                clipFunc={(ctx) => {
                  ctx.beginPath();
                  detectionFirstItem.forEach((item) => {
                    if (highlightedIndexClicked) {
                      if (highlightedIndex !== null) {
                        const { annotations } =
                          detectionFirstItem[highlightedIndex];
                        ctx.moveTo(annotations[0], annotations[1]);
                        for (let i = 2; i < annotations.length; i += 2) {
                          ctx.lineTo(annotations[i], annotations[i + 1]);
                        }
                        ctx.closePath();
                      }
                    } else {
                      ctx.moveTo(item.annotations[0], item.annotations[1]);
                      for (let i = 2; i < item.annotations.length; i += 2) {
                        ctx.lineTo(
                          item.annotations[i],
                          item.annotations[i + 1],
                        );
                      }
                      ctx.closePath();
                    }
                  });
                  ctx.clip();
                }}
              >
                <KonvaImage
                  image={konvaImage}
                  width={zoneSize.width}
                  height={zoneSize.height}
                  onClick={() => {
                    setHighlightedIndexClicked(false);
                    setHighlightedIndex(null);
                  }}
                />
              </Group>
              <Group>
                <KonvaImage
                  image={konvaImage}
                  width={zoneSize.width}
                  height={zoneSize.height}
                  fill="black"
                  opacity={highlightedIndex === null ? 1 : 0.3}
                  onClick={() => {
                    setHighlightedIndexClicked(false);
                    setHighlightedIndex(null);
                  }}
                />
                {detectionFirstItem.map((item, index) => (
                  <React.Fragment key={`${String(index)}`}>
                    {labelConfigList[index] && (
                      <>
                        <Line
                          points={item.annotations}
                          fill="transparent"
                          stroke={labelConfigList[index].scenario.color}
                          strokeWidth={2}
                          closed
                          onClick={() => {
                            setHighlightedIndexClicked(true);
                            handleMouseEnter(index);
                          }}
                          onMouseEnter={() => {
                            if (!highlightedIndexClicked) {
                              handleMouseEnter(index);
                            }
                          }}
                          onMouseLeave={handleMouseLeave}
                          ref={(node) => {
                            if (node && highlightedIndex === index) {
                              node.moveToTop();
                            }
                          }}
                        />
                        {isList && hideLabel && (
                          <Label
                            x={labelConfigList[index].x}
                            y={labelConfigList[index].y}
                            opacity={
                              highlightedIndex === index
                                ? 1
                                : highlightedIndex !== null
                                  ? 0
                                  : 0.8
                            }
                            onClick={() => {
                              setHighlightedIndexClicked(true);
                              handleMouseEnter(index);
                            }}
                            onMouseEnter={() => {
                              if (!highlightedIndexClicked) {
                                handleMouseEnter(index);
                              }
                            }}
                            onMouseLeave={handleMouseLeave}
                            ref={(node) => {
                              if (node && highlightedIndex === index) {
                                node.moveToTop();
                              }
                            }}
                            visible
                          >
                            <Tag
                              fill={labelConfigList[index].scenario.color}
                              pointerDirection={getPointerDirection(
                                labelConfigList[index].x,
                                labelConfigList[index].y,
                              )}
                              pointerWidth={10}
                              pointerHeight={10}
                            />
                            <Text
                              text={`${i18n.t(labelConfigList[index].scenario.title)}`}
                              fontSize={18}
                              padding={5}
                              fill="white"
                            />
                          </Label>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Group>
            </Layer>
          </Stage>
        )}
      </div>
    );
  },
);

ImageWithAnnotation.defaultProps = {
  className: '',
  containerWidth: undefined,
  containerHeight: undefined,
  onImageLoading: false,
  hideLabel: true,
  isActionEnable: false,
  isList: true,
  onLoad: undefined,
  onError: undefined,
  onClick: undefined,
};

export default ImageWithAnnotation;
