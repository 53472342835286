export type RuleProps = {
  action: {
    destination: Array<string>;
    type: number;
  };
  client_id: string;
  condition: {
    min_occurance: number;
    period: number;
  };
  id: string;
  interval: number;
  last_triggered_timestamp: number;
  title: string;
  trigger: {
    cameras: Array<string>;
    scenarios: Array<string>;
  };
};

export type StringAndNumberProps = { title: string; value: number };

// Create-Alert-Rules
export const conditionList = [
  {
    title: 'button.one_hour',
    value: 1,
  },
  {
    title: 'button.twelve_hour',
    value: 12,
  },
  {
    title: 'button.twenty_four_hour',
    value: 24,
  },
  {
    title: 'button.three_days',
    value: 72,
  },
  {
    title: 'button.one_week',
    value: 168,
  },
];

export const actionList = [
  'actionlist.email',
  'actionlist.sms',
  // 'actionlist.trigger_iot_device'
];
export const intercalList = ['button.minutes', 'button.hours', 'button.days'];
