import moment from 'moment';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  FormCheck,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { urlTempImage, urlTempImageV2 } from '../../apis/api-request';
import { siteId } from '../../apis/tokens';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';
import ImageDownloader from '../elements/ImageDownloader';
import { ImageLoader } from '../elements/ImageLoader';
import ImageWithAnnotation, {
  AnnotationRef,
} from '../elements/ImageWithAnnotation';
import { Loader } from '../elements/Loader';

interface Props {
  observation: ObservationProp;
  showModal: boolean;
  currentIndex?: number;
  lastIndex?: number;
  isAllAction?: boolean;
  onLoading?: boolean;
  onNextPress?: (index: number) => void;
  onBackPress?: (index: number) => void;
  onFKeyPress?: (observation: ObservationProp) => void;
  onAKeyPress?: (observation: ObservationProp) => void;
  onRKeyPress?: (observation: ObservationProp) => void;
  onCloseClick: () => void;
}

const ShowObservationModal: FC<Props> = function ShowObservationModal({
  showModal,
  observation,
  onLoading,
  isAllAction,
  currentIndex,
  lastIndex,
  onCloseClick,
  onNextPress,
  onBackPress,
  onFKeyPress,
  onAKeyPress,
  onRKeyPress,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { userCookie, scenariosList, user } = useContext(
    AuthContext,
  ) as AuthContextType;
  const annotationRef = useRef<AnnotationRef>(null);

  const [observationDetail, setObservationDetail] = useState<ObservationProp>();

  const [retryCount, setRetryCount] = useState(0);
  const [imageURL, setImageURL] = useState<string>('');
  const [onImageLoading, setOnImageLoading] = useState(true);
  const [hideLabel, setHideLabel] = useState<boolean>(true);

  const filteredScenario = scenariosList.find((scenario) => {
    if (observation.customer_scenario_label.name) {
      return scenario.value === observation.customer_scenario_label.name;
    }
    return scenario.value === observation.customer_scenario_label.name;
  });

  const onNext = () => {
    if (currentIndex !== undefined && onNextPress && lastIndex) {
      if (currentIndex < lastIndex) {
        onNextPress(currentIndex + 1);
      } else {
        onCloseClick();
      }
    }
  };
  const onBack = () => {
    if (currentIndex !== undefined && onBackPress) {
      if (currentIndex > 0) {
        onBackPress(currentIndex - 1);
      }
    }
  };

  const onFkey = () => {
    if (onFKeyPress) {
      onFKeyPress(observationDetail!);
    }
  };

  const onAkey = () => {
    if (onAKeyPress) {
      onAKeyPress(observationDetail!);
    }
  };

  const onRkey = () => {
    if (onRKeyPress) {
      onRKeyPress(observationDetail!);
    }
  };

  useEffect(() => {
    setObservationDetail(observation);
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        event.preventDefault();
        onNext();
      }
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        onBack();
      }
      if (event.shiftKey && event.key === 'F') {
        event.preventDefault();
        onFkey();
      }
      if (event.shiftKey && event.key === 'A') {
        event.preventDefault();
        onAkey();
      }
      if (event.shiftKey && event.key === 'R') {
        event.preventDefault();
        onRkey();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [currentIndex, onLoading, observation]);

  useEffect(() => {
    const showLabels =
      user?.customer.feature_flags.observations.show_labels_by_default;
    const labelValue = showLabels === undefined ? true : showLabels;
    setHideLabel(labelValue);
  }, [user]);

  const updateImageUrl = async () => {
    if (userCookie && retryCount < 2 && observationDetail) {
      const param = {
        customer_id: user?.customerId,
        site_id: Number(siteId),
        request_id: observationDetail.requestId,
        version: 'original',
      };
      const oldParam = { observation_id: observationDetail.id };
      const show_thumbnail = user?.customer.feature_flags.image?.show_thumbnail;
      try {
        const getImageUrl = show_thumbnail
          ? await urlTempImageV2(userCookie.userToken, param)
          : await urlTempImage(userCookie.userToken, oldParam);
        if (!getImageUrl || getImageUrl?.data.detail === 'Image not found') {
          const getOldImageUrl = await urlTempImage(
            userCookie.userToken,
            oldParam,
          );
          setImageURL(getOldImageUrl.data.message);
        } else {
          setImageURL(
            show_thumbnail
              ? getImageUrl.data.signed_url
              : getImageUrl.data.message,
          );
        }
      } catch (error) {
        console.error('Error fetching image URL:', error);
        setRetryCount(retryCount + 1);
      } finally {
        setOnImageLoading(false);
      }
    }
  };

  useEffect(() => {
    setImageURL('');
    updateImageUrl();
  }, [observationDetail]);

  if (!showModal) {
    return null;
  }

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  const showAnnotation =
    user?.customer.feature_flags.observations.show_annotation;
  const isAnnotation =
    showAnnotation &&
    observationDetail &&
    observationDetail.id >= Number(showAnnotation);
  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal observation-image danger-zone-modal"
      show={showModal}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <div className="d-flex">
          <span className={`me-12 ${!isAllAction && 'd-none'}`}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  {i18n.t('show_observation_modal.hint')}
                </Tooltip>
              }
            >
              <InfoIcon className="icon" />
            </OverlayTrigger>
          </span>
          {isAnnotation && (
            <FormCheck
              type="switch"
              id="custom-switch"
              label={`${i18n.t('show_observation_modal.switch.show')}`}
              checked={hideLabel}
              onChange={(e) => {
                setHideLabel(e.currentTarget.checked);
              }}
            />
          )}
        </div>

        <CustomButton
          icon={CancelIcon}
          className="border-0 ms-auto"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div
          className={`position-absolute bottom-50 ${!onLoading && 'd-none'} ${theme}`}
          style={{
            left: '48%',
            zIndex: 1,
            padding: 10,
            borderRadius: '50%',
          }}
        >
          <Loader main />
        </div>

        {observationDetail && (
          <>
            <ImageLoader
              style={{ height: 400, width: '100%' }}
              className={`loading-image loading-image-${theme} ${!onImageLoading && 'd-none'}`}
            />
            {isAnnotation ? (
              <ImageWithAnnotation
                ref={annotationRef}
                className={`ph-no-capture add-cursor ${onImageLoading && 'd-none'}`}
                containerWidth="100%"
                containerHeight="100%"
                onImageLoading={onImageLoading}
                hideLabel={hideLabel}
                isActionEnable
                onLoad={() => setOnImageLoading(false)}
                onError={() => updateImageUrl()}
                imageUrl={imageURL}
                detections={observationDetail.detections}
              />
            ) : (
              <Image
                className={`${onImageLoading && 'd-none'}`}
                onLoad={() => setOnImageLoading(false)}
                onError={() => updateImageUrl()}
                width="100%"
                height="100%"
                src={imageURL}
              />
            )}
            <div className="d-flex justify-content-between align-items-center mt-12">
              <span className="ph-no-capture me-12">
                {moment
                  .unix(observationDetail.system_timestamp)
                  .format('DD MMM, HH:mm:ss')}
              </span>
              <div className="d-flex align-items-center">
                <span className="camera-name rounded-pill ph-no-capture me-12">
                  {observationDetail.camera
                    ? observationDetail.camera.name
                    : i18n.t('td.camera_no_longer_exists')}
                </span>
                {!filteredScenario ? (
                  i18n.t('td.scenario_not_found')
                ) : (
                  <span
                    className="scenario-list"
                    style={{
                      backgroundColor:
                        theme === 'light'
                          ? filteredScenario.colorLight
                          : filteredScenario.color,
                      color:
                        theme === 'light' ? filteredScenario.color : '#fff',
                    }}
                  >
                    {i18n.t(filteredScenario.title)}
                  </span>
                )}
              </div>

              {isAnnotation ? (
                <CustomButton
                  title={`${i18n.t('button.download')}`}
                  onClick={handleExport}
                  className="button-with-loader"
                  variant={`outline-${theme}`}
                />
              ) : (
                <ImageDownloader
                  imageUrl={observationDetail?.imageUrl}
                  className="mb-3"
                  variant={`outline-${theme}`}
                />
              )}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

ShowObservationModal.defaultProps = {
  onNextPress: undefined,
  onBackPress: undefined,
  onFKeyPress: undefined,
  onAKeyPress: undefined,
  onRKeyPress: undefined,
  currentIndex: undefined,
  lastIndex: undefined,
  onLoading: false,
  isAllAction: false,
};

export default ShowObservationModal;
