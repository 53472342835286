import React, { FC, useContext, useState } from 'react';
import { Col } from 'react-bootstrap';
import DangerZoneModal from './danger-zone/DangerZoneModal';
import NoScenarioModal from './danger-zone/NoScenarioModal';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { AuthContext } from '../../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { AuthContextType } from '../../../typescript/context/auth';
import { CameraContextType } from '../../../typescript/context/camera';
import { ThemeContextType } from '../../../typescript/context/theme';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';

interface Props {
  hideParentModal: () => void;
}

const DangerZone: FC<Props> = function DangerZone({ hideParentModal }) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;
  const { camera } = useContext(CameraUpdateContext) as CameraContextType;

  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => {
    hideParentModal();
    setShow(!show);
  };

  function filteredArray() {
    const filter = scenariosList.map((arr1Item) => {
      const { title, value, description, isDangerZone, color } = arr1Item;

      const isScenatio = camera.scenarios.includes(value);

      const updateValue = {
        title,
        value,
        description,
        isDangerZone,
        active: isScenatio,
        color,
      };
      return updateValue;
    });

    return filter;
  }

  if (!camera) return null;

  const scenario = filteredArray().filter(
    (isScenario) => isScenario.isDangerZone && isScenario.active,
  )[0];
  const scenarioArray = scenariosList.filter(
    (isScenario) => isScenario.isDangerZone && isScenario.isDangerZone,
  );

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('camera.danger_zone.title')}</p>
      <p className="sub-color">{i18n.t('camera.danger_zone.body')}</p>

      <p className="fw-bold">
        {scenarioArray.map((item, index) => {
          const comma = scenarioArray.length - 1 !== index ? ', ' : '';
          return i18n.t(item.title) + comma;
        })}
      </p>

      <CustomButton
        icon={AddIcon}
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.danger_zone')}
        onClick={handleShow}
      />

      {scenario && <DangerZoneModal show={show} onButtonClick={handleShow} />}
      {!scenario && <NoScenarioModal show={show} onButtonClick={handleShow} />}

      <hr />
    </Col>
  );
};

export default DangerZone;
