import { gql } from '@apollo/client';

export const USER_UPDATE_GQL = gql`
  mutation UPDATE(
    $id: Int!
    $title: String
    $phone_no: String
    $phone_no_verified: Boolean
    $name: String
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        name: $name
        phone_no: $phone_no
        phone_no_verified: $phone_no_verified
      }
    ) {
      title
      phoneNoVerified: phone_no_verified
      phoneNo: phone_no
      username: name
      isAdmin: is_admin
      id
      email
      fcmtoken
      customerId: customer_id
      customer {
        feature_flags
        dashboard
        id
        sites {
          name
          id
        }
        customer_scenario_labels {
          id
          value: name
        }
      }
    }
  }
`;
