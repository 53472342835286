import { Nullable } from '../../utils/typeUtils';
import { ScenarioName } from '../observation/scenario';

export type FeatureFlags = {
  cameras: {
    set_site_button: boolean;
    show_live_stream: boolean;
    init_site: boolean;
    show_details_page: boolean;
  };
  dashboard: {
    show_page: boolean;
  };
  image: {
    draw_bounding_box: boolean;
    show_thumbnail: boolean;
    show_heatmap: boolean;
  };
  observations: {
    show_grouping: boolean;
    show_delete: boolean;
    show_export_csv: boolean;
    show_labels_by_default: boolean;
    show_annotation: boolean | number;
  };
  alerts: {
    new_layout: boolean;
  };
  show_language_switcher: boolean;
};

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  cameras: {
    set_site_button: false,
    show_live_stream: false,
    init_site: false,
    show_details_page: false,
  },
  dashboard: {
    show_page: false,
  },
  image: {
    draw_bounding_box: false,
    show_thumbnail: false,
    show_heatmap: false,
  },
  observations: {
    show_grouping: false,
    show_delete: false,
    show_export_csv: false,
    show_labels_by_default: false,
    show_annotation: false,
  },
  alerts: {
    new_layout: false,
  },
  show_language_switcher: false,
};

export type UserListProps = {
  email: string;
  phoneNo: string | null;
  username: string | null;
  isChecked?: boolean;
};

export type CustomerSite = {
  id: number;
  name: string;
};

export type CustomerScenario = {
  id: number;
  value: ScenarioName;
  custom_value: Nullable<string>;
};

export type UserProp = {
  title: string;
  phoneNoVerified: boolean;
  phoneNo: string;
  username: string;
  isAdmin: boolean;
  id: number;
  email: string;
  fcmtoken: string;
  customerId: number;
  trackingId: string;
  customer: {
    feature_flags: FeatureFlags;
    dashboard: string;
    id: number;
    sites: CustomerSite[];
    customer_scenario_labels: CustomerScenario[];
    trackingId: string;
  };
  exclude_from_tracking: boolean;
};
