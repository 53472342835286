import { useMutation } from '@apollo/client';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityObserver from 'react-visibility-observer';
import { ReactComponent as VerticalDotsIcon } from '../../assets/icons/vertical-dots.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  ADD_OBSERVATION_BOOKMARK,
  DELETE_OBSERVATION_BOOKMARK,
  ObservationUserInsertInput,
} from '../../graphql/mutations/observation';
import {
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  ObservationProp,
} from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import CustomDropdownWithSubMenu, {
  DropdownProps,
} from '../elements/CustomDropdownWithSubMenu';
import LazyLoadedImage from '../elements/LazyLoadedImage';

interface Props {
  observation: ObservationProp;
  selectedObservations: boolean;
  hideCameraColumn?: boolean;
  dropdownActionList: Array<DropdownProps>;
  onImageClick: (selectedObservation: ObservationProp) => void;
  isObservationSelected: (isSeleted: boolean, id: number) => void;
  onReportFalseModal: (id: number) => void;
  onSensitiveObservationAction: (
    id: number,
    type: string,
    value: string,
  ) => void;
}

export function ObservationItem({
  observation,
  selectedObservations,
  hideCameraColumn = false,
  dropdownActionList,
  onImageClick,
  isObservationSelected,
  onReportFalseModal,
  onSensitiveObservationAction,
}: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { scenariosList, user, clientUsersList } = useAuthContext();

  const [addObservationBookmark, observationBookmark] = useMutation(
    ADD_OBSERVATION_BOOKMARK,
  );
  const [deleteObservationBookmark, deleteBookmark] = useMutation(
    DELETE_OBSERVATION_BOOKMARK,
  );

  const isBookmarked = observation.observation_users.length !== 0;

  useEffect(() => {
    if (observationBookmark.error) {
      toast.error(observationBookmark.error.message);
    } else if (observationBookmark.data) {
      toast.success(i18n.t('toast.success.bookmarked'), {
        autoClose: 500,
        onClick: async () => {
          navigate('/observations?type=2');
          window.location.reload();
        },
      });
    }
  }, [
    observationBookmark.error,
    observationBookmark.data,
    observation,
    navigate,
  ]);

  useEffect(() => {
    if (deleteBookmark.error) {
      toast.error(deleteBookmark.error.message);
    } else if (deleteBookmark.data) {
      toast.success(i18n.t('toast.success.unbookmark'));
    }
  }, [deleteBookmark.error, deleteBookmark.data]);

  function onBookmark(value: ObservationUserInsertInput) {
    if (isBookmarked) {
      deleteObservationBookmark({
        variables: {
          id: observation?.observation_users[0].id,
        },
      });
    } else {
      addObservationBookmark({
        variables: {
          data: value,
        },
      });
    }
  }

  const filteredScenario = scenariosList.filter(
    (scenario) => scenario.value === observation.customer_scenario_label.name,
  );

  const filteredPriority = OBSERVATION_PRIORITIES.filter(
    (priority) => priority.value === observation.priority,
  );

  const filteredStatus =
    OBSERVATION_STATUSES.filter(
      (status) => status.value === observation.status,
    )[0] || null;

  const priorityHighLevel = filteredPriority.length
    ? filteredPriority[0].value === 'high' && 'background-error'
    : 'background-success';
  const priorityMediumLevel = filteredPriority.length
    ? filteredPriority[0].value === 'medium' && 'background-warning'
    : 'background-success';
  const priorityLowLevel = filteredPriority.length
    ? filteredPriority[0].value === 'low' && 'background-success'
    : 'background-success';

  function navigation() {
    navigate(`/observation/${observation.id}`);
  }

  const dropdownList = dropdownActionList.map((item) => ({
    ...item,
    title: i18n.t(`${item.title}`),
  }));

  const filteredAssignee = clientUsersList.filter(
    (item) => item.email === observation.responder,
  )[0];

  return (
    <tr
      className={`add-cursor selected-row ${selectedObservations && `selected-row-${theme}`}`}
    >
      <td className="p-0">
        <div className="d-flex align-items-center">
          <div
            className="ps-3 py-3"
            onClick={() => {
              isObservationSelected(!selectedObservations, observation.id);
            }}
            aria-hidden
          >
            <FormCheck
              checked={selectedObservations}
              className="me-12 check-box"
              readOnly
            />
          </div>
          <span
            className="ms-0 ph-no-capture"
            onClick={() => navigation()}
            aria-hidden
          >
            {moment
              .unix(observation.system_timestamp)
              .format('DD MMM, HH:mm:ss')}
          </span>
        </div>
      </td>

      <td onClick={() => navigation()}>
        {!filteredScenario[0] ? (
          <span>{i18n.t('td.scenario_not_found')}</span>
        ) : (
          <span
            className="scenario-list"
            style={{
              backgroundColor:
                theme === 'light'
                  ? filteredScenario[0].colorLight
                  : filteredScenario[0].color,
              color: theme === 'light' ? filteredScenario[0].color : '#fff',
            }}
          >
            {i18n.t(filteredScenario[0].title)}
          </span>
        )}
      </td>

      {!hideCameraColumn && (
        <td onClick={() => navigation()}>
          <span className="camera-name rounded-pill ph-no-capture">
            {observation.camera
              ? observation.camera.name
              : i18n.t('td.camera_no_longer_exists')}
          </span>
        </td>
      )}

      <td onClick={() => navigation()}>
        {observation.responder && filteredAssignee ? (
          <Link
            onClick={(e) => e.stopPropagation()}
            className={`btn-link btn-link-${theme} ph-no-capture`}
            to={`/profile/${filteredAssignee.id}`}
          >
            {filteredAssignee.username || observation.responder}
          </Link>
        ) : (
          i18n.t('td.not_assigned')
        )}
      </td>

      <td className="text-capitalize">
        {i18n.t(filteredStatus ? filteredStatus.title : 'status.unknown')}
      </td>

      <td onClick={() => navigation()}>
        <VisibilityObserver triggerOnce threshold={0.2}>
          <LazyLoadedImage
            onImageClick={onImageClick}
            observation={observation}
          />
        </VisibilityObserver>
      </td>

      <td onClick={() => navigation()}>
        <div className="d-flex align-items-center">
          <span
            className={`${priorityHighLevel} ${priorityMediumLevel} ${priorityLowLevel}`}
            style={{
              width: 6,
              height: 6,
              marginRight: 4,
            }}
          />
          <p className="m-0">
            {i18n.t(
              filteredPriority.length
                ? filteredPriority[0].title
                : OBSERVATION_PRIORITIES[2].title,
            )}
          </p>
        </div>
      </td>

      <td>
        <div
          className="d-flex justify-content-center align-items-center"
          aria-hidden
        >
          <CustomDropdownWithSubMenu
            mainClassName="position-absolute"
            dropdownData={dropdownList}
            hideContextData="ph-no-capture"
            isThreeDot
            icon={VerticalDotsIcon}
            variant={`outline-${theme}`}
            bodyVariant={theme}
            onClick={(selectValue) => {
              const { value, subMenu } = selectValue;
              const { id } = observation;
              if (selectValue.value === 'report') {
                onReportFalseModal(id);
              } else if (selectValue.value === 'bookmark') {
                onBookmark({ observation_id: `${id}`, user_id: user?.id || 0 });
              } else if (selectValue.value === 'view_details') {
                navigation();
              } else {
                onSensitiveObservationAction(
                  id,
                  String(value),
                  String(subMenu ? subMenu[0].value : ''),
                );
              }
            }}
          />
        </div>
      </td>
    </tr>
  );
}
