/* eslint-disable import/no-extraneous-dependencies */
import Lottie from 'lottie-react';
import React from 'react';
import loader from '../../assets/animations/loader.json';
import mainLoader from '../../assets/animations/main-loader.json';

interface Props {
  className?: string | undefined;
  main?: boolean;
}

export function Loader({ className, main = false }: Props) {
  return (
    <Lottie
      className={`loader ${className}`}
      animationData={main ? mainLoader : loader}
    />
  );
}

export default Loader;
