/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useContext, useEffect, useState } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { AuthContext } from '../../../contextapi/AuthProvider';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { AuthContextType } from '../../../typescript/context/auth';
import { ThemeContextType } from '../../../typescript/context/theme';
import { ScenarioProps } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { useTracker } from '../../../utils/Tracker';

export type CustomPayload = Payload<
  string | number | (string | number)[],
  string | number
>;

type Props = {
  payload?: CustomPayload[] | undefined;
  onClick?: (payload: Array<ScenarioProps>) => void;
};

export function CustomLegend({ payload, onClick }: Props) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { trackScenarioTagClick } = useTracker();
  const { scenariosList } = useContext(AuthContext) as AuthContextType;
  const [activeScenarios, setActiveScenario] = useState<Array<ScenarioProps>>(
    [],
  );

  useEffect(() => {
    const scenarioList = (payload && payload.map((item) => item.dataKey)) || [];

    const filteredScenario = scenariosList.filter((element) =>
      scenarioList.includes(element.value),
    );

    setActiveScenario(filteredScenario || []);
  }, []);

  return (
    <div
      className="d-flex justify-content-center mt-12"
      style={{
        flexFlow: 'row wrap',
        alignItems: 'flex-end',
        height: '2.25rem',
      }}
    >
      {activeScenarios.map((scenario) => (
        <span
          key={scenario.value}
          className="scenario-list d-flex mx-2 mt-12 add-cursor"
          aria-hidden
          onClick={() => {
            if (onClick) {
              const selectedScenario = activeScenarios.map((item) => {
                if (item.value === scenario.value) {
                  return {
                    ...item,
                    active: !item.active,
                  };
                }
                return item;
              });

              trackScenarioTagClick('scenario_tag', {
                title: selectedScenario[0].value,
              });
              setActiveScenario(selectedScenario);
              if (selectedScenario) {
                onClick(selectedScenario);
              }
            }
          }}
          style={{
            opacity: !scenario.active ? 1.0 : 0.4,
            backgroundColor:
              theme === 'light' ? scenario.colorLight : scenario.color,
            color: theme === 'light' ? scenario.color : '#fff',
          }}
        >
          {`${i18n.t(scenario.title)}`}
        </span>
      ))}
    </div>
  );
}
