import React, { FC, useContext } from 'react';
import { Col } from 'react-bootstrap';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { ThemeContextType } from '../../../typescript/context/theme';
import {
  OBSERVATION_PRIORITIES,
  TitleAndValue,
} from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import CustomDropdown from '../../elements/CustomDropdown';

interface Props {
  selectedPriority: TitleAndValue | null;
  onClick: (priority: TitleAndValue) => void;
}

const PriorityFilter: FC<Props> = function PriorityFilter({
  selectedPriority,
  onClick,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  return (
    <Col md={12} className="">
      <hr />
      <p className="mb-1 weight-600 ">{i18n.t('filter.priority.title')}</p>
      <p className="sub-color">{i18n.t('filter.priority.body')}</p>

      <CustomDropdown
        title={
          !selectedPriority
            ? `${i18n.t('button.select.priority')}`
            : `${i18n.t(selectedPriority.title)}`
        }
        className="me-2"
        dropdownData={OBSERVATION_PRIORITIES.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.title === value.title),
        ).map((item: TitleAndValue) => ({
          title: `${i18n.t(item.title)}`,
          value: item.value,
        }))}
        variant={`outline-${theme}`}
        bodyVariant={theme}
        onClick={(value) =>
          onClick({ title: String(value.title), value: value.value })
        }
      />
    </Col>
  );
};

export default PriorityFilter;
