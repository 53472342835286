import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './scss/buddywise.scss';
import React, { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ThemeProvider } from './contextapi/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import { Sentry } from './utils/Sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (process.env.REACT_APP_DISABLE_SENTRY !== 'true') {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: 'https://1d6259a4062544e7807bed0210e64ea0@o1302818.ingest.sentry.io/6540957',
    integrations: [new Sentry.BrowserTracing({})],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <StrictMode>
    <CookiesProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </CookiesProvider>
  </StrictMode>,
);
reportWebVitals();
