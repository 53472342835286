import React, { FC, useContext } from 'react';
import { Col } from 'react-bootstrap';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { ThemeContextType } from '../../typescript/context/theme';
import i18n from '../../utils/i18n';
import InputField from '../elements/InputField';

interface Props {
  className?: string | undefined;
  alertName?: string | number | string[] | undefined;
  onTextChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
}

const NameOfAlertRuleAction: FC<Props> = function NameOfAlertRuleAction({
  className,
  alertName,
  onTextChange,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;

  return (
    <Col md={12} className={className}>
      <p className="mb-1 weight-600">{i18n.t('name_of_the_alert.title')}</p>
      <p className="sub-color">{i18n.t('name_of_the_alert.body')}</p>
      <div className="d-flex align-items-center">
        <p className="m-0 me-12">{i18n.t('name_of_the_alert.container')}</p>
        <InputField
          className={`input-${theme}`}
          controlId="formCameraName"
          type="text"
          value={alertName}
          placeholder={`${i18n.t('input.alert_name_placeholder')}`}
          onChange={onTextChange}
        />
      </div>
    </Col>
  );
};

NameOfAlertRuleAction.defaultProps = {
  className: '',
  alertName: undefined,
  onTextChange: undefined,
};

export default NameOfAlertRuleAction;
