import { gql } from '@apollo/client';

export const GET_CAMERA = gql`
  query GET_CAMERA($id: Int!) {
    cameras_by_pk(id: $id) {
      id
      name
      status
      image_url
      legacy_id
      cameras_scenarios {
        id
        perimeter
        customer_scenario_label {
          id
          name
        }
      }
      observations_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
