import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { urlTempImage } from '../../apis/api-request';
import { ReactComponent as OfflineCamera } from '../../assets/icons/offline-camera.svg';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import i18n from '../../utils/i18n';
import { isString } from '../../utils/typeUtils';
import { ImageLoader } from '../elements/ImageLoader';

interface ImageProps {
  id: number;
  cameraPerRow: number;
  className?: string;
}

export const DisplayImage = ({ id, cameraPerRow, className }: ImageProps) => {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { userCookie, onTokenSave } = useContext(
    AuthContext,
  ) as AuthContextType;
  const [cameraUrl, setCameraUrl] = useState<string>('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isImageFound, setIsImageFound] = useState(true);

  const fetchImageUrl = async () => {
    try {
      const param = { camera_id: id };
      const response = await urlTempImage(userCookie.userToken, param);

      if (response.status === 401) {
        onTokenSave('');
        return;
      }

      const imageUrl = response.data.message;
      if (isString(imageUrl)) {
        setCameraUrl(imageUrl);
        setIsImageFound(true);
      } else {
        setIsImageFound(false);
      }
    } catch (error) {
      console.error('Error fetching new image URL:', error);
      setIsImageFound(false);
    } finally {
      setIsImageLoading(false);
    }
  };

  useEffect(() => {
    fetchImageUrl();
  }, [id, userCookie]);

  const convertHeight = 80 * cameraPerRow;

  return (
    <>
      {isImageLoading && <ImageLoader style={{ height: convertHeight }} />}
      {isImageFound ? (
        <Image
          onLoad={() => setIsImageLoading(false)}
          onError={() => setIsImageFound(false)}
          height={convertHeight}
          width="100%"
          style={{ objectFit: 'cover' }}
          className={`${className} ${isImageLoading ? 'd-none' : ''} ph-no-capture`}
          src={cameraUrl}
        />
      ) : (
        <div
          className={`observation-image-container-offline  ${isImageLoading ? 'd-none' : ''} ${theme === 'light' ? 'dark' : 'light'}`}
          style={{ height: convertHeight }}
        >
          <OfflineCamera />
          <p>{i18n.t('camera.camera_not_found')}</p>
        </div>
      )}
    </>
  );
};
