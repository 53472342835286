/* eslint-disable no-mixed-operators */
import i18n, { Resource } from 'i18next';
import moment from 'moment';
import { Cookies } from 'react-cookie';
import { initReactI18next } from 'react-i18next';
import { fetchTranslationCatalog } from '../apis/traduora-api';

const cookies = new Cookies();

const getTranslationResources = async (
  currentLangauge: string,
): Promise<Resource> => {
  const activeCatalog = await fetchTranslationCatalog(currentLangauge);

  return {
    en: { translation: currentLangauge === 'en' ? activeCatalog : {} },
    sv_SE: { translation: currentLangauge === 'sv_SE' ? activeCatalog : {} },
    es: { translation: currentLangauge === 'es' ? activeCatalog : {} },
  };
};

export const loadTranslations = async () => {
  const currentLangauge = cookies.get('language') || 'en';

  try {
    const resources = await getTranslationResources(currentLangauge);
    i18n.use(initReactI18next).init({
      resources,
      lng: currentLangauge,
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false,
      },
    });
    moment.locale(
      currentLangauge === 'en' ? `${currentLangauge}-gb` : currentLangauge,
    );

    return true;
  } catch (error) {
    console.error('Error loading translations');
    return false;
  }
};

export default i18n;
