import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { DonutChart } from './charts/DonutChart';
import { MultiBarChart } from './charts/MultiBarChart';
import { EmptyChart } from './EmptyChart';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { useGetChartDataQuery } from '../../hooks/graphql/chart';
import { updatedDate } from '../../typescript/grouping/grouping-observation';

type Props = {
  cameraIds?: Array<number>;
};

export function ObservationCharts({ cameraIds }: Props) {
  const { timeRange } = useTimeRangeContext();

  const variables = {
    from: timeRange.value,
    until: timeRange.end,
    camera_ids: cameraIds,
  };

  const { loading, data, refetch } = useGetChartDataQuery(variables);

  useEffect(() => {
    refetch();
  }, [timeRange, refetch]);

  const chartItems = useMemo(
    () =>
      (data?.chartData || []).map((group) => ({
        ...group,
        timeseries: updatedDate(
          group.timeseries.map((item) => ({
            count: item.count,
            date: moment(item.range).format('DD MMM'),
            range: item.range,
          })),
          timeRange.text,
          timeRange.value,
          timeRange.end,
          timeRange.isHourly,
        ),
      })),
    [timeRange, data?.chartData],
  );

  return !loading && chartItems?.length === 0 ? (
    <EmptyChart />
  ) : (
    <>
      <DonutChart data={chartItems} cameraIds={cameraIds} isLoading={loading} />
      <MultiBarChart
        data={chartItems}
        cameraIds={cameraIds}
        isLoading={loading}
      />
    </>
  );
}
