import React from 'react';
import { Col, Row } from 'react-bootstrap';
import i18n from '../../utils/i18n';
import { ObservationCharts } from '../charts-container/ObservationCharts';
import { SectionTitle } from '../elements/SectionTitle';
import { TimeRangeDropdown } from '../shared/TimeRangeDropdown';

export function AnalyticsOverview() {
  return (
    <Row className="group-observations mt-32">
      <Col
        md={12}
        className="mb-20 d-flex align-items-center justify-content-between"
      >
        <SectionTitle
          title={i18n.t('dashboard.analytics_overview')}
          removeMargins
        />
        <TimeRangeDropdown />
      </Col>

      <ObservationCharts />
    </Row>
  );
}
