import { useEffect, useState } from 'react';
import { loadHeatMap, urlTempImage } from '../../apis/api-request';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { TimePeriod } from '../../typescript/datetime';
import { isDefined } from '../../utils/typeUtils';

type Args = {
  cameraId: number;
  scenarioId?: number;
  timePeriod: TimePeriod;
};

export const useCameraHeatmap = ({
  cameraId,
  scenarioId,
  timePeriod,
}: Args) => {
  const { userCookie } = useAuthContext();
  const [heatmapUrl, setHeatmapUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchHeatmap = async () => {
      if (!isDefined(scenarioId)) {
        return;
      }

      const imageResponse = await urlTempImage(userCookie.userToken, {
        camera_id: cameraId,
      });

      const param = {
        image_url: imageResponse.data.message,
        customer_scenario_label_id: scenarioId,
        camera_id: cameraId,
        timestamp: timePeriod,
      };

      const heatmapResponse = await loadHeatMap(userCookie.userToken, param);
      if (heatmapResponse.success) {
        const heatmapUrl = URL.createObjectURL(heatmapResponse.data);
        setHeatmapUrl(heatmapUrl);
      } else if (process.env.REACT_APP_ENV === 'development') {
        setHeatmapUrl('https://dummyimage.com/900x700/663399/fff');
      }
    };

    fetchHeatmap();
  }, [cameraId, scenarioId, userCookie.userToken, timePeriod]);

  return {
    heatmapUrl,
  };
};
