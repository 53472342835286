import React, { FC } from 'react';
import { Button, Dropdown, FormCheck } from 'react-bootstrap';
import Loader from './Loader';

export type DropdownProps = {
  title: string | number;
  value: string | number | boolean;
  isChecked?: boolean;
};

interface Props {
  className?: string;
  hideContextData?: string;
  variant?: string;
  bodyVariant?: string;
  title?: string;
  selectAllTitle?: string;
  confirmTitle?: string;
  dropdownData: Array<DropdownProps>;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  rightIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  disabled?: boolean;
  selectedAll?: boolean;
  isConfirm?: boolean;
  onSelectedAll?: (isAllCheck: boolean, fromChild: boolean) => any | undefined;
  onClick?: (value: DropdownProps) => any | undefined;
  onConfirm?: (value: Array<DropdownProps>) => any | undefined;
}
const CustomDropdownWithCheckbox: FC<Props> =
  function CustomDropdownWithCheckbox({
    className,
    hideContextData,
    variant,
    bodyVariant,
    title,
    selectAllTitle,
    confirmTitle,
    dropdownData,
    icon: Icon,
    rightIcon: RightIcon,
    type,
    loading,
    disabled,
    selectedAll,
    isConfirm,
    onSelectedAll,
    onClick,
    onConfirm,
  }) {
    return (
      <Dropdown>
        <Dropdown.Toggle
          className={`${className} icon button-with-loader`}
          variant={variant}
          id="dropdown-basic"
          disabled={loading || disabled}
        >
          {Icon && <Icon className="me-2" />}
          {title}
          {RightIcon && <RightIcon className="ms-2" />}
          {loading && <Loader className="ms-12" />}
        </Dropdown.Toggle>

        <Dropdown.Menu
          variant={bodyVariant}
          className={`${isConfirm && 'pb-0'}`}
        >
          <div className="dropdown-item border-bottom">
            <FormCheck
              className={`check-box py-1 ${dropdownData.every((item) => item.isChecked) || 'not-check-all'}`}
              checked={selectedAll}
              label={selectAllTitle}
              onClick={(e: any) => {
                if (onSelectedAll) {
                  onSelectedAll(e.target.checked, false);
                }
              }}
            />
          </div>
          {dropdownData.map((item) => (
            <div
              className={`dropdown-item ${hideContextData}`}
              key={item.title}
            >
              <FormCheck
                className="check-box"
                checked={item.isChecked}
                label={item.title}
                onClick={() => {
                  if (onClick && onSelectedAll) {
                    onClick(item);
                    onSelectedAll(true, true);
                  }
                }}
              />
            </div>
          ))}
          {isConfirm && (
            <Button
              className="d-flex w-100 justify-content-center rounded-top-0"
              disabled={
                dropdownData.filter((item) => item.isChecked).length === 0
              }
              onClick={() => onConfirm && onConfirm(dropdownData)}
            >
              {confirmTitle}
            </Button>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

CustomDropdownWithCheckbox.defaultProps = {
  className: '',
  hideContextData: '',
  variant: '',
  bodyVariant: '',
  title: '',
  selectAllTitle: '',
  confirmTitle: '',
  type: 'button',
  loading: false,
  disabled: false,
  selectedAll: false,
  isConfirm: false,
  icon: undefined,
  rightIcon: undefined,
  onSelectedAll: undefined,
  onClick: undefined,
  onConfirm: undefined,
};

export default CustomDropdownWithCheckbox;
