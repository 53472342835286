import { useMutation } from '@apollo/client';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  DELETE_OBSERVATIONS,
  UPDATE_OBSERVATIONS,
} from '../../graphql/mutations';
import { ObservationsSetInput } from '../../graphql/mutations/groups';
import { UPDATE_OBSERVATION_BY_ID } from '../../graphql/mutations/observation';
import { WhereQueryProps } from '../../graphql/queries/observation';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { ObservationsTable } from '../observation/ObservationsTable';

interface Props {
  loading?: boolean;
  observationsList: Array<ObservationProp> | [];
  totalPages: number;
  limit: number;
  pageNumber: number;
  isOrder: boolean;
  selectedCameraIds?: Array<number>;
  selectedScenariosIds?: Array<number>;
  dayStart?: number;
  dayEnd?: number;
  setIsOrder: Dispatch<SetStateAction<boolean>>;
  onFilterApplied: (
    obj: WhereQueryProps,
    listLimit: number,
    pageNo: number,
  ) => void;
}

export function ScenarioObservationsList({
  loading = true,
  observationsList,
  totalPages,
  limit,
  pageNumber,
  isOrder,
  selectedCameraIds,
  selectedScenariosIds,
  dayStart = 0,
  dayEnd = 0,
  setIsOrder,
  onFilterApplied,
}: Props) {
  const [searchParams] = useSearchParams();

  const decode: string = searchParams.get('filter')
    ? decodeURI(String(searchParams.get('filter')))
    : '{}';

  const filter: WhereQueryProps = JSON.parse(decode);

  const [observations, setObservations] =
    useState<Array<ObservationProp>>(observationsList);

  const [updateSensitiveObservation, onSensitiveObservationAction] =
    useMutation(UPDATE_OBSERVATION_BY_ID);
  const [updateObservation, update] = useMutation(UPDATE_OBSERVATIONS);
  const [deleteObservation, deleteActions] = useMutation(DELETE_OBSERVATIONS);

  const onDeleteObservation = (observationIds: Array<number>) => {
    deleteObservation({
      variables: {
        observationIds,
      },
    });
  };

  const onUpdateObservation = (
    observationIds: Array<number>,
    value: ObservationsSetInput,
  ) => {
    updateObservation({
      variables: {
        observationIds,
        data: value,
      },
    });
  };

  useEffect(() => {
    if (onSensitiveObservationAction.data) {
      const updatedObservation =
        onSensitiveObservationAction.data.observation_update_sensitive_column;
      const filteredArray = updatedObservation.map(
        (item: any) => item.observation[0],
      );
      const updatedArray = observations.map((item1) => {
        const matchedItem = filteredArray.find(
          (item2: ObservationProp) => item2.id === item1.id,
        );
        return matchedItem ? { ...item1, ...matchedItem } : item1;
      });
      setObservations(updatedArray);
    }
  }, [onSensitiveObservationAction.data]);

  useEffect(() => {
    if (update.data) {
      window.location.reload();
    }
  }, [update.data]);

  useEffect(() => {
    if (deleteActions.data) {
      toast.success(i18n.t('toast.success.deleted'), {
        autoClose: 500,
        onClose: () => window.location.reload(),
      });
    }
  }, [observations, deleteActions.data]);

  const onSensitiveObservation = (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => {
    updateSensitiveObservation({
      variables: {
        id: observationIds,
        column,
        value,
      },
    });
  };

  return (
    <ObservationsTable
      observations={observations}
      filter={filter}
      limit={limit}
      pageNumber={pageNumber}
      totalPages={totalPages}
      isOrder={isOrder}
      setIsOrder={setIsOrder}
      onSensitiveObservation={onSensitiveObservation}
      onFilterApplied={onFilterApplied}
      onUpdateObservation={onUpdateObservation}
      onDeleteObservation={onDeleteObservation}
      loading={loading}
      selectedCameraIds={selectedCameraIds}
      selectedScenariosIds={selectedScenariosIds}
      dayStart={dayStart}
      dayEnd={dayEnd}
    />
  );
}
