import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GET_USER {
    user {
      title
      phoneNoVerified: phone_no_verified
      phoneNo: phone_no
      username: name
      isAdmin: is_admin
      id
      email
      fcmtoken
      customerId: customer_id
      trackingId: tracking_id
      customer {
        feature_flags
        dashboard
        id
        sites {
          name
          id
        }
        customer_scenario_labels {
          id
          value: name
          custom_value: custom_name
        }
        trackingId: tracking_id
      }
      exclude_from_tracking
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GET_ALL_USERS {
    users {
      id
      customerId: customer_id
      email
      username: name
      phoneNo: phone_no
      isAdmin: is_admin
      title
    }
  }
`;

export const GET_USERS_BY_ID = gql`
  query GET_USERS_BY_ID($id: Int!) {
    users_by_pk(id: $id) {
      id
      customerId: customer_id
      email
      username: name
      phoneNo: phone_no
      isAdmin: is_admin
      title
    }
  }
`;
