import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as SpanishFlag } from '../../assets/flags/flag-es.svg';
import { ReactComponent as EnglishFlag } from '../../assets/flags/flag-uk.svg';
import { useTranslationContext } from '../../contextapi/TranslationProvider';
import i18n from '../../utils/i18n';

interface Props {
  className?: string | undefined;
}

export function LanguageDropdown({ className }: Props) {
  const { language, changeLanguage } = useTranslationContext();

  return (
    <Dropdown drop="up" className={className}>
      <Dropdown.Toggle variant="transparent" id="dropdown-basic">
        {language === 'en' && <EnglishFlag />}
        {/* {language === 'sv_SE' && (
        <SwedenFlag />
        )} */}
        {language === 'es' && <SpanishFlag />}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => changeLanguage('en')}>
          <EnglishFlag className="me-2" />
          {i18n.t('button.english')}
        </Dropdown.Item>

        {/* <Dropdown.Item onClick={() => onChange('sv_SE')}>
          <SwedenFlag />
        </Dropdown.Item> */}

        <Dropdown.Item onClick={() => changeLanguage('es')}>
          <SpanishFlag className="me-2" />
          {i18n.t('button.spanish')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
