import Konva from 'konva';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import CameraMultipleCanvasFilter, {
  CanvasRef,
} from './CameraMultipleCanvasFilter';
import { useCameraUpdateContext } from '../../../../contextapi/CameraUpdateProvider';
import { useTimeRangeContext } from '../../../../contextapi/TimeRangeProvider';
import { DangerZoneResult } from '../../../../typescript/camera/dangerzone';
import { ScenarioProps } from '../../../../typescript/observation/scenario';
import i18n from '../../../../utils/i18n';

export type CanvasSize = {
  width: number;
  height: number;
};

interface Props {
  selectedScenario: ScenarioProps;
  canvasPerimeter: Array<DangerZoneResult>;
  removeZone?: number | null;
  canvasSize: CanvasSize;
  imageRef: React.RefObject<HTMLImageElement>;
  showDangerZones?: boolean;
  removeSeletedZone?: (id: number) => void;
  onClear?: () => void;
}

export function CameraCanvasFilter({
  selectedScenario,
  canvasPerimeter,
  removeZone,
  imageRef,
  canvasSize,
  showDangerZones = false,
  removeSeletedZone,
  onClear,
}: Props) {
  const { setImageExportParam } = useCameraUpdateContext();
  const { timeRange } = useTimeRangeContext();

  // state
  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);
  const [curMousePos, setCurMousePos] = useState([0, 0]);

  // reference
  const stageRef = useRef<Konva.Stage>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<CanvasRef>(null);

  useEffect(() => {
    const clientWidth = canvasSize.width;
    const clientHeight = canvasSize.height;
    setCanvasWidth(clientWidth!);
    setCanvasHeight(clientHeight!);
  }, [canvasPerimeter]);

  const getMousePos = (stage: Konva.Stage | null) => {
    const stageValue = [
      stage?.getPointerPosition()?.x,
      stage?.getPointerPosition()?.y,
    ];
    return stageValue;
  };

  function getDividedOfPostions(partialValue: number, totalValue: number) {
    return partialValue / totalValue;
  }

  const checkXPointPostion = (x: number) => {
    if (x < 0) {
      return 15;
    }
    if (canvasWidth < x) {
      return canvasWidth - 15;
    }
    return getDividedOfPostions(x, canvasWidth);
  };

  const checkYPointPostion = (y: number) => {
    if (y < 0) {
      return 0;
    }
    if (canvasHeight < y) {
      return canvasHeight;
    }
    return getDividedOfPostions(y, canvasHeight);
  };

  const handleClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = event.target.getStage();
    const mousePos = getMousePos(stage);

    const posX = checkXPointPostion(mousePos[0]!);
    const posY = checkYPointPostion(mousePos[1]!);
    canvasPerimeter.forEach((zone, index) => {
      if (zone.completed) {
        return;
      }
      const newState = [...canvasPerimeter];
      if (canvasRef.current?.getStartPoint()) {
        newState[index].completed = true;
      } else {
        newState[index].zonePoints.push([posX, posY]);
      }
    });
  };

  const handleDragEndPoint = (
    event: Konva.KonvaEventObject<MouseEvent>,
    pointerIndex: number,
  ) => {
    const selectedIndex = event.target.index - 1;
    const { x, y } = event.target.attrs;
    const posX = checkXPointPostion(x);
    const posY = checkYPointPostion(y);

    const pos = [posX, posY];
    const newState = [...canvasPerimeter];
    newState[pointerIndex].zonePoints[selectedIndex] = pos;
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = event.target.getStage();
    const mousePos = getMousePos(stage);
    const posX = checkXPointPostion(mousePos[0]!);
    const posY = checkYPointPostion(mousePos[1]!);

    setCurMousePos([posX, posY]);
  };

  useEffect(() => {
    const dateRange = `${moment.unix(timeRange.value).format('DD MMM, YYYY, HH:mm')} ~ ${moment.unix(timeRange.end).format('DD MMM, YYYY, HH:mm')}`;
    setImageExportParam({
      stageRef,
      imageRef,
      scenarioName: i18n.t(selectedScenario.title),
      dateRange,
    });
  }, [stageRef, imageRef, selectedScenario, timeRange, setImageExportParam]);

  return (
    <div className="canvas-box" ref={mainContainerRef}>
      <Stage
        ref={stageRef}
        width={canvasWidth}
        height={canvasHeight}
        onMouseDown={handleClick}
        onMouseMove={handleMouseMove}
        onClick={onClear ? () => onClear() : undefined}
      >
        <Layer>
          {!showDangerZones &&
            canvasPerimeter.map((zone) => (
              <CameraMultipleCanvasFilter
                ref={canvasRef}
                key={zone.id}
                selectedZoneColor={selectedScenario.color}
                dangerZones={zone}
                zoneSize={{ canvasWidth, canvasHeight }}
                curMousePos={curMousePos}
                onDblTapGroup={removeSeletedZone}
                selectedZone={removeZone}
                onDragEnd={handleDragEndPoint}
              />
            ))}
        </Layer>
      </Stage>
    </div>
  );
}
