import React, { FC, useContext, useState } from 'react';
import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { removeCameras } from '../../../apis/api-request';
import { AuthContext } from '../../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import {
  defaultModalValue,
  ModalType,
} from '../../../typescript/components/modal';
import { AuthContextType } from '../../../typescript/context/auth';
import { CameraContextType } from '../../../typescript/context/camera';
import { ThemeContextType } from '../../../typescript/context/theme';
import i18n from '../../../utils/i18n';
import { CustomButton } from '../../elements/CustomButton';
import ConfirmationModal from '../../modal/ConfirmationModal';

interface Props {
  onCloseClick: () => void;
  hideParentModal: () => void;
}

const RemoveCamera: FC<Props> = function RemoveCamera({
  onCloseClick,
  hideParentModal,
}) {
  const { userCookie, onTokenSave } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { camera, loadAllCameras } = useContext(
    CameraUpdateContext,
  ) as CameraContextType;
  const { theme } = useContext(ThemeContext) as ThemeContextType;

  const [loading, setLoading] = useState<boolean>(false);

  // Modal
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);

  const onRemoveCamera = async () => {
    setLoading(true);
    const param = { id: camera.sql_id };
    const deleteCamera = await removeCameras(userCookie.userToken, param);
    setLoading(false);

    if (deleteCamera.status === 200) {
      toast.success(deleteCamera.data.message);
      setConfirmationModal(defaultModalValue);
      loadAllCameras();
      onCloseClick();
      return true;
    }
    if (deleteCamera.status === 401) {
      onTokenSave('');
    }
    toast.error(deleteCamera.data.message);
    setConfirmationModal(defaultModalValue);
    return false;
  };

  const handleShowConfirmation = (isTrue: boolean) => {
    if (isTrue) onRemoveCamera();
    hideParentModal();
    setConfirmationModal(defaultModalValue);
  };

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('camera.remove_camera.title')}</p>
      <p className="sub-color">{i18n.t('camera.remove_camera.body')}</p>

      <CustomButton
        variant={`outline-${theme}`}
        type="button"
        className="button-with-loader"
        title={i18n.t('button.remove_camera')}
        loading={loading}
        onClick={() => {
          hideParentModal();
          setConfirmationModal({
            title: i18n.t('modal.delete_camera.title'),
            body: i18n.t('modal.delete_camera.body'),
            buttonType: 'danger',
            doneText: i18n.t('modal.delete_observation.yes'),
            cancelText: i18n.t('modal.delete_observation.no'),
            show: true,
          });
        }}
      />
      <ConfirmationModal
        modalValue={confirmationModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />
    </Col>
  );
};

export default RemoveCamera;
