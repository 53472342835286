import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Loader } from '../components/elements/Loader';
import { UserForm } from '../components/settings/UserForm';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import i18n from '../utils/i18n';

export const SettingsPage = () => {
  const { user } = useAuthContext();
  const { theme } = useThemeContext();
  const [key, setKey] = useState<number>(0);

  return (
    <Row className="settings mt-32">
      <Col md={12}>
        {!user ? (
          <div className="loader-layout">
            <Loader main />
          </div>
        ) : (
          <>
            <div className="user-title mb-32">
              <p className="user-information-image ph-no-capture">
                {`${user?.email}`.substring(0, 1)}
              </p>
              <h5 className="ph-no-capture">
                {user?.username ? user?.username : user?.email}
              </h5>
              <p
                className={`user-role user-role-${user?.isAdmin}-${theme} user-role-${user?.isAdmin} rounded-pill border`}
              >
                {user?.isAdmin ? i18n.t('text.admin') : i18n.t('text.user')}
              </p>
            </div>
            <Tabs
              activeKey={key}
              className={`nav-tabs-${theme} mb-32 d-flex justify-content-center`}
              onSelect={(k) => setKey(Number(k))}
            >
              <Tab eventKey={0} title={i18n.t('tab.user_information')}>
                {user && <UserForm user={user} />}
              </Tab>
            </Tabs>
          </>
        )}
      </Col>
    </Row>
  );
};
