export type CameraProps = {
  clientId: string;
  id: string;
  sql_id: number;
  imageUrl: string;
  ipAddress: string;
  name: string;
  perimeters: string;
  port: number;
  scenarios: Array<string>;
  schedule: string;
  shouldCollectData: boolean;
  threshold: number;
  timecreated: string;
  isDemo: boolean;
};

export const RenderList = [
  {
    value: 2,
    colValue: 6,
  },
  {
    value: 3,
    colValue: 4,
  },
  {
    value: 4,
    colValue: 3,
  },
];

export type RenderListProps = {
  value: number;
  colValue: number;
};

export const numberOfIncident = [10, 20, 50, 100, 200];

export const showNumberOfItems = [1, 10, 20, 50, 100, 200];
