import React, { FC, useContext } from 'react';
import { Col } from 'react-bootstrap';
import { ThemeContext } from '../../../contextapi/ThemeProvider';
import { ThemeContextType } from '../../../typescript/context/theme';
import { ClientUser } from '../../../typescript/observation/assignee';
import i18n from '../../../utils/i18n';
import CustomDropdown from '../../elements/CustomDropdown';

interface Props {
  responders: Array<ClientUser>;
  selectedResponder: string | undefined;
  onClick: (responder: string) => void;
}

const ResponderFilter: FC<Props> = function ResponderFilter({
  onClick,
  responders,
  selectedResponder,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  return (
    <Col md={12} className="">
      <hr />
      <p className="mb-1 weight-600 ">{i18n.t('filter.responder.title')}</p>
      <p className="sub-color">{i18n.t('filter.responder.body')}</p>

      <CustomDropdown
        title={
          !selectedResponder
            ? `${i18n.t('button.select_responder')}`
            : selectedResponder
        }
        hideContextData="ph-no-capture"
        className="me-2"
        dropdownData={responders.map((item: ClientUser) =>
          item.username
            ? {
                title: item.username,
                value: item.email,
              }
            : {
                title: item.email,
                value: item.email,
              },
        )}
        variant={`outline-${theme}`}
        bodyVariant={theme}
        onClick={(item) => {
          if (item.value === 'no assignee') {
            return false;
          }
          onClick(String(item.value));
          return true;
        }}
      />
    </Col>
  );
};

export default ResponderFilter;
