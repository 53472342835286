import React, { FC } from 'react';
import { Col, Image } from 'react-bootstrap';
import emptylist from '../../assets/empty-group-list.svg';
import i18n from '../../utils/i18n';

interface Props {
  className?: string;
}

const EmptyGroupList: FC<Props> = function EmptyGroupList({ className }) {
  return (
    <Col
      md={6}
      className={`
        text-center
        mx-auto
        align-self-center
        d-flex
        flex-column
        justify-content-center
        align-items-center
        flex-1
        ${className}
      `}
    >
      <Image width={128} className="mb-3" src={emptylist} />
      <h6>{i18n.t('empty_group_list.title')}</h6>
      <p>{i18n.t('empty_group_list.body')}</p>
    </Col>
  );
};

EmptyGroupList.defaultProps = {
  className: '',
};

export default EmptyGroupList;
