import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomLegend } from './CustomLegend';
import { BarTooltip } from './tools/BarTooltip';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../../../contextapi/TimeRangeProvider';
import { useNavigateToObservation } from '../../../hooks/charts';
import { ChartItem } from '../../../hooks/graphql/chart';
import {
  combineObservations,
  groupDataByDates,
} from '../../../typescript/grouping/grouping-observation';
import {
  convertToScenarioName,
  ScenarioProps,
} from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { useTracker } from '../../../utils/Tracker';
import { CustomPlaceholder } from '../../elements/CustomPlaceholder';

interface Props {
  data: ChartItem[];
  cameraIds?: Array<number>;
  isLoading?: boolean;
}

export function MultiBarChart({ data, cameraIds, isLoading = false }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const { timeRange } = useTimeRangeContext();
  const { trackBarClick } = useTracker();
  const { navigateToObservation } = useNavigateToObservation();
  const [activeScenarios, setActiveScenarios] = useState<ScenarioProps[]>([]);
  const formatedData = useMemo(
    () =>
      combineObservations(data).map((item) => ({
        ...item,
        date: moment(item.range).format('DD MMM, YYYY'),
        range: item.range,
      })),
    [data],
  );

  const totalObservations = useMemo(
    () =>
      data.reduce(
        (total, { timeseries }) =>
          total + timeseries.reduce((acc, { count }) => acc + count, 0),
        0,
      ),
    [data],
  );

  const convertedScenarios = data.map(
    (observation) => observation.scenario_names.map(convertToScenarioName)[0],
  );
  const filteredScenario = scenariosList
    .filter((element) => convertedScenarios.includes(element.value))
    .map((item) => item);

  const groupedByDates = groupDataByDates(
    formatedData,
    filteredScenario,
    timeRange.isHourly,
  );

  useEffect(() => {
    setActiveScenarios(filteredScenario);
  }, [data, scenariosList]);

  const placeholderBarCount = useMemo(() => {
    switch (timeRange.text) {
      case '24 hours':
        return 25;
      case 'today':
        return moment().hours() + 1;
      case '7':
        return 8;
      case '14':
        return 15;
      case '30':
        return 31;
      default:
        return Math.ceil((timeRange.end - timeRange.value) / 86400) + 1;
    }
  }, [timeRange]);

  const placeholderBarHeights = useMemo(
    () =>
      Array.from({ length: placeholderBarCount }).map(
        () => 240 * Math.random(),
      ),
    [placeholderBarCount],
  );

  return (
    <Col md={8} className="mb-32">
      <Card
        className={`card-${theme} border border-${theme} border-radius h-100 placeholder-glow`}
      >
        <Card.Body className="d-flex flex-column">
          <Card.Text className="text-center weight-600">
            {i18n.t('analytics_overview.daily_chart')}
          </Card.Text>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                height: 300,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: 8,
                  marginBottom: 30,
                  height: 240,
                }}
              >
                {placeholderBarHeights.map((height, index) => (
                  <CustomPlaceholder
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${placeholderBarCount}_${index}`}
                    style={{
                      flex: 1,
                      height,
                    }}
                  />
                ))}
              </div>
              <CustomPlaceholder
                style={{
                  display: 'block',
                  margin: '0 auto',
                  width: '100%',
                  height: 26,
                }}
              />
            </div>
          ) : (
            <ResponsiveContainer height={300} className="ph-no-capture">
              <BarChart data={groupedByDates}>
                <YAxis
                  tickMargin={10}
                  strokeOpacity={0.3}
                  stroke={theme === 'light' ? '#1f2321' : '#ffffff'}
                />
                <XAxis
                  dataKey="date"
                  tickMargin={10}
                  tickFormatter={(tick: string) =>
                    timeRange.isHourly
                      ? moment(tick).format('HH:mm')
                      : tick.slice(0, -6)
                  }
                  stroke={theme === 'light' ? '#1f2321' : '#ffffff'}
                  strokeOpacity={0.3}
                />
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke={theme === 'light' ? '#cfd3cf' : '#6f7676'}
                />
                {activeScenarios.map((item) => (
                  <Bar
                    key={item.value}
                    dataKey={item.value}
                    stackId="stacked"
                    hide={item.active}
                    fill={item.color}
                    onClick={(_, index) => {
                      const startOfDay: number = moment(
                        groupedByDates[index].date,
                      ).unix();
                      let endOfDay: number;
                      if (groupedByDates.length - 1 !== index) {
                        endOfDay = moment(
                          groupedByDates[index + 1].date,
                        ).unix();
                      } else {
                        endOfDay = moment(groupedByDates[index].date)
                          .endOf('day')
                          .unix();
                      }
                      const scenarioIds = activeScenarios
                        .filter((scenairo) => !scenairo.active)
                        .map((scenairo) => scenairo.id || 0);

                      trackBarClick('multi_bar_click', {
                        timeRange: {
                          title: timeRange.text,
                          value: startOfDay,
                          end: endOfDay,
                        },
                        scenarios: activeScenarios
                          .filter((scenairo) => !scenairo.active)
                          .map((scenairo) => scenairo.value),
                      });

                      navigateToObservation({
                        timeRange: {
                          value: startOfDay,
                          end: endOfDay,
                        },
                        scenarioIds,
                        cameraIds,
                      });
                    }}
                    className="add-cursor"
                  />
                ))}
                <Legend
                  content={
                    <CustomLegend
                      onClick={(payload: Array<ScenarioProps>) =>
                        setActiveScenarios(payload)
                      }
                    />
                  }
                />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  content={<BarTooltip totalObservations={totalObservations} />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}
