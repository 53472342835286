/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  COLOR_STATUS_ERROR,
  COLOR_STATUS_NEUTRAL,
  COLOR_STATUS_SUCCESS,
  SECONDARY_COLOR,
} from '../../scss/colors';
import { spacing } from '../../scss/spacing';
import i18n from '../../utils/i18n';
import { Maybe } from '../../utils/typeUtils';

type Props = {
  status: Maybe<'ONLINE' | 'OFFLINE'>;
};

export function CameraStatus({ status }: Props) {
  const { theme } = useThemeContext();
  const statusColor =
    status === 'ONLINE'
      ? COLOR_STATUS_SUCCESS
      : status === 'OFFLINE'
        ? COLOR_STATUS_ERROR
        : COLOR_STATUS_NEUTRAL;

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${spacing(1)} ${spacing(2)};
        border-radius: ${spacing(1)};
        white-space: nowrap;
        width: fit-content;
        background-color: ${theme === 'light' ? '#f7f8f7' : '#e6eae6'};
        color: ${SECONDARY_COLOR};
        gap: ${spacing(1)};
      `}
    >
      {i18n.t(`camera_status.${status?.toLowerCase()}`)}
      <span
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 0.375rem;
          width: 0.375rem;
          border-radius: ${spacing(1)};
          white-space: nowrap;
          background-color: ${statusColor};
        `}
      />
    </span>
  );
}
