import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Image } from 'react-bootstrap';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { useCameraHeatmap } from '../../hooks/api/camera';
import { CamerasScenarios, XYCoordinate } from '../../hooks/graphql/camera';
import { DangerZoneResult } from '../../typescript/camera/dangerzone';
import { TimePeriod } from '../../typescript/datetime';
import { ScenarioProps } from '../../typescript/observation/scenario';
import { isEmptyObject } from '../../utils/typeUtils';
import {
  CameraCanvasFilter,
  CanvasSize,
} from '../cameras/settings/danger-zone/CameraCanvasFilter';
import ShowImageModal from '../modal/ShowImageModal';

type Props = {
  cameraId: number;
  selectedScenario?: ScenarioProps;
  scenariosWithDetections: Array<CamerasScenarios>;
  showDangerZones: boolean;
};

export function HeatMapImageWithDangerZone({
  cameraId,
  selectedScenario,
  scenariosWithDetections,
  showDangerZones = false,
}: Props) {
  const { timeRange } = useTimeRangeContext();

  const [canvasSize, setCanvasSize] = useState<CanvasSize>();
  const [onImageLoading, setOnImageLoading] = useState<boolean>(true);
  const [showImageModal, setShowImageModal] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const [dangerZones, setDangerZones] = useState<Array<DangerZoneResult>>([]);

  const scenarioId = selectedScenario?.id;
  const timePeriod = useMemo<TimePeriod>(
    () => ({
      from: timeRange.value,
      until: timeRange.end,
    }),
    [timeRange.value, timeRange.end],
  );

  const { heatmapUrl } = useCameraHeatmap({
    cameraId,
    scenarioId,
    timePeriod,
  });

  useEffect(() => {
    setDangerZones([]);
  }, [selectedScenario]);

  const updateZoneSize = useCallback(() => {
    if (imageRef.current && !onImageLoading) {
      const checkFrameHight =
        imageRef.current.clientHeight < 40 ||
        imageRef.current.clientHeight === imageRef.current.clientWidth;
      if (imageRef.current.clientWidth === 0 || checkFrameHight) {
        setTimeout(updateZoneSize, 100);
      } else {
        const param = {
          width: imageRef.current.clientWidth,
          height: imageRef.current.clientHeight,
        };
        setCanvasSize(param);

        const detection = scenariosWithDetections.find(
          (detection) => detection.customer_scenario_label.id === scenarioId,
        );

        const perimeter = isEmptyObject(detection?.perimeter)
          ? undefined
          : detection?.perimeter;

        const dangerZones =
          perimeter?.position.map((item: XYCoordinate[], index: number) => ({
            id: index,
            completed: true,
            zonePoints: item,
          })) || [];

        if (dangerZones.length === 0) {
          setDangerZones([]);
        } else {
          setDangerZones(dangerZones);
        }
      }
    }
  }, [imageRef, onImageLoading, scenariosWithDetections, scenarioId]);

  useEffect(() => {
    updateZoneSize();
    window.addEventListener('resize', updateZoneSize);
    return () => window.removeEventListener('resize', updateZoneSize);
  }, [imageRef, onImageLoading, scenariosWithDetections, updateZoneSize]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Image
        onLoad={() => setOnImageLoading(false)}
        width="100%"
        src={heatmapUrl}
        ref={imageRef}
      />
      {imageRef.current &&
        !onImageLoading &&
        canvasSize &&
        selectedScenario && (
          <CameraCanvasFilter
            showDangerZones={!showDangerZones}
            selectedScenario={selectedScenario}
            canvasPerimeter={dangerZones}
            canvasSize={canvasSize}
            imageRef={imageRef}
          />
        )}

      {heatmapUrl && (
        <ShowImageModal
          imageUrl={heatmapUrl}
          showModal={showImageModal}
          onCloseClick={() => setShowImageModal(false)}
        />
      )}
    </div>
  );
}
