import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthContext } from './AuthProvider';
import { LoadingPage } from '../pages/LoadingPage';
import { loadTranslations } from '../utils/i18n';

export type TranslationContextType = {
  language: string;
  changeLanguage: (locale: string) => void;
};

export const TranslationContext = createContext<TranslationContextType | null>(
  null,
);

export const useTranslationContext = () => {
  const context = React.useContext(TranslationContext);
  if (!context) {
    throw new Error(
      'useTranslationContext must be used within a TranslationProvider',
    );
  }
  return context;
};

export const TranslationProvider = ({ children }: PropsWithChildren) => {
  const { userCookie, setUserCookie } = useAuthContext();
  const [areTranslationsLoaded, setTranslationsLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    const subscribe = async () => {
      const translationsLoaded = await loadTranslations();
      setTranslationsLoaded(translationsLoaded);
    };
    subscribe();
  }, []);

  const context: TranslationContextType = useMemo(
    () => ({
      language: userCookie.language,
      changeLanguage: (locale: string) => {
        setUserCookie('language', locale);
        window.location.reload();
      },
    }),
    [setUserCookie, userCookie.language],
  );

  return !areTranslationsLoaded ? (
    <LoadingPage />
  ) : (
    <TranslationContext.Provider value={context}>
      {children}
    </TranslationContext.Provider>
  );
};
