/* eslint-disable react/jsx-no-useless-fragment */
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { Col, Image, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urlTempImage, urlTempImageV2 } from '../apis/api-request';
import { siteId } from '../apis/tokens';
import InboxIcon from '../assets/empty-comment-list.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as OfflineCamera } from '../assets/icons/offline-camera.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import { ReactComponent as UnbookmarkIcon } from '../assets/icons/start-outline.svg';
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { CustomButton } from '../components/elements/CustomButton';
import CustomDropdown from '../components/elements/CustomDropdown';
import { ImageLoader } from '../components/elements/ImageLoader';
import ImageWithAnnotation from '../components/elements/ImageWithAnnotation';
import InputField from '../components/elements/InputField';
import { Loader } from '../components/elements/Loader';
import ConfirmationModal from '../components/modal/ConfirmationModal';
import ReportFalseModal from '../components/modal/ReportFalseModal';
import ShowObservationModal from '../components/modal/ShowObservationModal';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import {
  DELETE_OBSERVATION,
  ObservationsSetInput,
  UPDATE_OBSERVATION,
} from '../graphql/mutations';
import {
  ADD_OBSERVATION_BOOKMARK,
  DELETE_OBSERVATION_BOOKMARK,
  ObservationUserInsertInput,
  UPDATE_OBSERVATION_BY_ID,
} from '../graphql/mutations/observation';
import { GET_OBSERVATION } from '../graphql/queries/observation';
import { defaultModalValue, ModalType } from '../typescript/components/modal';
import { ClientUser } from '../typescript/observation/assignee';
import {
  Comments,
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  ObservationProp,
  TimeLine,
  TitleAndValue,
} from '../typescript/observation/observation';
import i18n from '../utils/i18n';
import { isString } from '../utils/typeUtils';

const ObservationDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  // Context
  const {
    userCookie,
    user,
    clientUsersList,
    scenariosList,
    onTokenSave,
    featureFlags,
  } = useAuthContext();
  const { theme } = useThemeContext();

  const [observation, setObservation] = useState<ObservationProp | undefined>();
  const [isImageFound, setIsImageFound] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState(0);
  const [observationImageUrl, setObservationImageUrl] = useState<string | null>(
    null,
  );
  // const [isBookmarkId, setIsBookmarkId] = useState<number|null>(null);

  // Loaders
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [onReportLoading, setOnReportLoading] = useState(false);
  const [onDeleteLoading, setOnDeleteLoading] = useState(false);
  const [onStatusLoading, setOnStatusLoading] = useState(false);
  const [onResponderLoading, setOnResponderLoading] = useState(false);
  const [onPriorityLoading, setOnPriorityLoading] = useState(false);

  // Comment
  const [comment, setComment] = useState<string>('');

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);
  const [reportFalseModal, setReportFalseModal] =
    useState<ModalType>(defaultModalValue);

  // GraphQL
  const { loading, data } = useQuery(GET_OBSERVATION, {
    variables: {
      id: Number(slug),
    },
    fetchPolicy: 'network-only',
  });

  const [updateObservation] = useMutation(UPDATE_OBSERVATION);
  const [updateSensitiveObservation, onSensitiveObservationAction] =
    useMutation(UPDATE_OBSERVATION_BY_ID);
  const [deleteObservation, deleteActions] = useMutation(DELETE_OBSERVATION);
  const [addObservationBookmark, observationBookmark] = useMutation(
    ADD_OBSERVATION_BOOKMARK,
  );
  const [deleteObservationBookmark, deleteBookmark] = useMutation(
    DELETE_OBSERVATION_BOOKMARK,
  );

  const isBookmarked = observation?.observation_users.length !== 0;

  useEffect(() => {
    if (data) {
      setOnReportLoading(false);
      setOnDeleteLoading(false);
      setOnResponderLoading(false);
      setOnStatusLoading(false);
      setOnPriorityLoading(false);

      if (data.observations_by_pk) {
        const isFalsePositive: boolean =
          data.observations_by_pk.is_false_positive;
        if (!isFalsePositive) {
          if (observation?.imageUrl !== data.observations_by_pk.imageUrl) {
            setObservationImageUrl(data.observations_by_pk.imageUrl);
            setRetryCount(0);
          }
          setObservation(data.observations_by_pk);
        }
        if (isFalsePositive) {
          toast.warning(
            'This observation is reported False positive reported',
            {
              autoClose: 500,
              onClose: () => navigate(-1),
            },
          );
        }
      } else {
        toast.error(i18n.t('toast.error.observation_not_exists'), {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }

      if (deleteActions.data) {
        toast.success(i18n.t('toast.success.observation_deleted'), {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
    }
  }, [data, deleteActions.data, navigate]);

  useEffect(() => {
    if (onSensitiveObservationAction.data) {
      const updatedObservation =
        onSensitiveObservationAction.data.observation_update_sensitive_column;
      setObservation(updatedObservation[0].observation[0]);
      setComment('');
    }
  }, [onSensitiveObservationAction.data]);

  useEffect(() => {
    if (observationBookmark.error) {
      toast.error(observationBookmark.error.message);
    } else if (observationBookmark.data) {
      toast.success(i18n.t('toast.success.bookmarked'), {
        autoClose: 500,
        onClick: async () => {
          await navigate('/observations?type=2');
          window.location.reload();
        },
      });
    }
  }, [observationBookmark.error, observationBookmark.data, navigate]);

  useEffect(() => {
    if (deleteBookmark.error) {
      toast.error(deleteBookmark.error.message);
    } else if (deleteBookmark.data) {
      toast.success(i18n.t('toast.success.unbookmark'));
    }
  }, [deleteBookmark.error, deleteBookmark.data]);

  const getImageUrlV1 = async (): Promise<string | null> => {
    if (!userCookie || !observation) {
      return null;
    }

    const response = await urlTempImage(userCookie.userToken, {
      observation_id: observation.id,
    });

    if (response?.status === 401) {
      onTokenSave('');
    }

    return response.data.message;
  };

  const getImageUrlV2 = async (): Promise<string | null> => {
    if (!userCookie || !observation) {
      return null;
    }

    const response = await urlTempImageV2(userCookie.userToken, {
      customer_id: user?.customerId,
      site_id: Number(siteId),
      request_id: observation.requestId,
      version: 'original',
    });

    if (response?.status === 401) {
      onTokenSave('');
    }

    if (response.data.detail === 'Image not found') {
      return null;
    }

    return response.data.signed_url;
  };

  const updateImageUrl = async () => {
    setIsImageLoading(true);
    const showThumbnail = featureFlags.image?.show_thumbnail;
    if (userCookie && observation && retryCount < 2) {
      try {
        const imageUrlV2 = showThumbnail ? await getImageUrlV2() : null;
        const imageUrlV1 = imageUrlV2 ? null : await getImageUrlV1();

        const imageUrl = imageUrlV2 || imageUrlV1;
        if (isString(imageUrl)) {
          setObservationImageUrl(imageUrl);
          setIsImageFound(true);
        } else {
          setIsImageFound(false);
        }
      } catch (fetchError) {
        console.error('Error fetching new image URL:', fetchError);

        setIsImageFound(false);
      } finally {
        setIsImageLoading(false);
        setRetryCount((prevCount) => prevCount + 1); // Increment retry count
      }
    } else {
      setIsImageLoading(false);
      setIsImageFound(true);
    }
  };

  function onBookmark(value: ObservationUserInsertInput) {
    if (isBookmarked) {
      deleteObservationBookmark({
        variables: {
          id: observation?.observation_users[0].id,
        },
      });
    } else {
      addObservationBookmark({
        variables: {
          data: value,
        },
      });
    }
  }

  function onSensitiveObservation(column: string, value: string) {
    updateSensitiveObservation({
      variables: {
        id: [Number(slug)],
        column,
        value,
      },
    });
  }

  function onUpdateObservation(value: ObservationsSetInput) {
    updateObservation({
      variables: {
        id: Number(slug),
        data: value,
      },
    });
  }

  function onDeleteObservation() {
    deleteObservation({
      variables: {
        id: Number(slug),
      },
    });
  }

  const handleClose = () => setShowModal(false);

  const handleShowConfirmation = (isTrue: boolean) => {
    if (isTrue && observation) {
      onDeleteObservation();
      setOnDeleteLoading(true);
    }

    setConfirmationModal(defaultModalValue);
  };

  const handleShowReportFalse = (isTrue: boolean) => {
    if (isTrue && observation) {
      onUpdateObservation({ is_false_positive: true });
      setOnReportLoading(true);
    }

    setReportFalseModal(defaultModalValue);
  };

  const filteredScenario = scenariosList.filter(
    (element) => element.value === observation?.customer_scenario_label.name,
  );
  const filteredPriority = OBSERVATION_PRIORITIES.filter(
    (priority) => priority.value === observation?.priority,
  );
  const filteredStatus = OBSERVATION_STATUSES.filter(
    (status) => status.value === observation?.status,
  );
  const filteredAssignee = clientUsersList.filter(
    (item) => item.email === observation?.responder,
  )[0];

  const showDelete = featureFlags.observations.show_delete;
  const showAnnotation = featureFlags.observations.show_annotation;
  const isAnnotation =
    observation && showAnnotation && observation.id >= Number(showAnnotation);
  return (
    <div className="observation">
      <Breadcrumb
        title={`${i18n.t('landing.title')}`}
        fromUrl="/observations"
      />
      <Row>
        <Col md={12} className="mb-32">
          <h5 className="m-0">{i18n.t('observation.title')}</h5>
        </Col>
        {loading && (
          <Col
            md={12}
            className="vh-100 d-flex justify-content-center align-items-center"
          >
            <Loader main />
          </Col>
        )}
        {observation && [
          <Col md={12} className="mb-24" key="buttons">
            <div className="d-flex">
              <CustomButton
                className="button-with-loader me-2"
                variant={`outline-${theme}`}
                icon={WarningIcon}
                title={i18n.t('button.report_false')}
                onClick={() => {
                  setReportFalseModal({
                    title: i18n.t('button.report_false'),
                    body: '',
                    buttonType: 'primary',
                    doneText: i18n.t('button.send'),
                    cancelText: i18n.t('button.cancel'),
                    show: true,
                  });
                }}
                loading={onReportLoading}
              />

              <CustomDropdown
                title={i18n.t('button.change_assignee')}
                hideContextData="ph-no-capture"
                className="me-2"
                dropdownData={clientUsersList.map((item: ClientUser) => ({
                  title: item.username ?? item.email,
                  value: item.email,
                }))}
                variant={`outline-${theme}`}
                bodyVariant={theme}
                disabled={onResponderLoading}
                loading={onResponderLoading}
                onClick={(item) => {
                  if (item.value === 'no assignee') {
                    return false;
                  }
                  if (observation.responder !== item.value) {
                    setOnResponderLoading(true);
                    onSensitiveObservation('responder', `${item.value}`);
                  }
                  return true;
                }}
              />

              <CustomButton
                title={
                  isBookmarked
                    ? i18n.t('button.remove_bookmark')
                    : i18n.t('button.bookmark')
                }
                className="button-with-loader me-2"
                variant={`outline-${theme}`}
                disabled={observationBookmark.loading || deleteBookmark.loading}
                loading={observationBookmark.loading || deleteBookmark.loading}
                icon={UnbookmarkIcon}
                onClick={() => {
                  if (slug && user) {
                    onBookmark({ observation_id: slug, user_id: user.id });
                  }
                }}
              />

              <CustomButton
                title={i18n.t('button.share')}
                className="button-with-loader me-2"
                variant={`outline-${theme}`}
                icon={ShareIcon}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  toast.success(i18n.t('toast.success.share_observation'));
                }}
              />

              {showDelete && (
                <CustomButton
                  title={i18n.t('button.delete')}
                  className="button-with-loader"
                  variant={`outline-${theme}`}
                  icon={DeleteIcon}
                  loading={onDeleteLoading}
                  onClick={() => {
                    setConfirmationModal({
                      title: i18n.t('modal.delete_observation.title'),
                      body: i18n.t('modal.delete_observation.body'),
                      buttonType: 'danger',
                      doneText: i18n.t('modal.delete_observation.yes'),
                      cancelText: i18n.t('modal.delete_observation.no'),
                      show: true,
                    });
                  }}
                />
              )}
            </div>
          </Col>,

          <Col md={8} key="body">
            <div className={`border border-${theme} border-radius p-4`}>
              <Row>
                <Col md={12} lg={6} className="danger-zone-modal">
                  {isImageFound ? (
                    <>
                      <ImageLoader
                        className={`${!isImageLoading && 'd-none'}`}
                      />
                      {isAnnotation ? (
                        <ImageWithAnnotation
                          className={`ph-no-capture add-cursor ${isImageLoading && 'd-none'}`}
                          containerWidth="100%"
                          containerHeight="100%"
                          onImageLoading={isImageLoading}
                          isActionEnable={false}
                          onLoad={() => setIsImageLoading(false)}
                          onError={() => updateImageUrl()}
                          onClick={() => setShowModal(true)}
                          imageUrl={observationImageUrl || ''}
                          detections={observation.detections}
                        />
                      ) : (
                        <Image
                          className={`ph-no-capture add-cursor ${isImageLoading && 'd-none'}`}
                          width="100%"
                          height="100%"
                          onLoad={() => setIsImageLoading(false)}
                          onError={() => updateImageUrl()}
                          onClick={() => setShowModal(true)}
                          src={observationImageUrl || ''}
                        />
                      )}
                    </>
                  ) : (
                    <div
                      className={`observation-image-container-offline ${theme === 'light' ? 'dark' : 'light'}`}
                    >
                      <OfflineCamera />
                      <p>Camera no longer exists.</p>
                    </div>
                  )}
                </Col>
                <Col md={12} lg={6}>
                  <h6 className="mb-3 ph-no-capture">
                    {observation.camera
                      ? observation.camera.name
                      : 'Camera no longer exists'}
                  </h6>
                  <div className="capitalize-letter d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.status')}:
                    </p>{' '}
                    {/* remove filter form statusList later when the resolve and resolved typeo is fixed */}
                    <CustomDropdown
                      title={
                        filteredStatus.length !== 0
                          ? i18n.t(filteredStatus[0].title)
                          : i18n.t('button.select')
                      }
                      className="me-2"
                      dropdownData={OBSERVATION_STATUSES.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex((t) => t.title === value.title),
                      ).map((item: TitleAndValue) => ({
                        title: `${i18n.t(item.title)}`,
                        value: item.value,
                      }))}
                      variant={`outline-${theme}`}
                      bodyVariant={theme}
                      disabled={onStatusLoading}
                      loading={onStatusLoading}
                      onClick={(item) => {
                        if (filteredStatus[0] === undefined) {
                          setOnStatusLoading(true);
                          onSensitiveObservation('status', `${item.value}`);
                        } else if (filteredStatus[0].value !== item.value) {
                          setOnStatusLoading(true);
                          onSensitiveObservation('status', `${item.value}`);
                        }
                      }}
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.priority')}:
                    </p>
                    <CustomDropdown
                      title={
                        observation.priority
                          ? i18n.t(filteredPriority[0].title)
                          : i18n.t('button.select')
                      }
                      className="me-2"
                      dropdownData={OBSERVATION_PRIORITIES.map(
                        (item: TitleAndValue) => ({
                          title: `${i18n.t(item.title)}`,
                          value: item.value,
                        }),
                      )}
                      variant={`outline-${theme}`}
                      bodyVariant={theme}
                      disabled={onPriorityLoading}
                      loading={onPriorityLoading}
                      onClick={(item) => {
                        if (filteredPriority[0].value !== item.value) {
                          setOnPriorityLoading(true);
                          onSensitiveObservation('priority', `${item.value}`);
                        }
                      }}
                    />
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.created')}:
                    </p>
                    <p className="m-0 ph-no-capture">
                      {moment
                        .unix(observation?.system_timestamp)
                        .format('MMM DD, HH:mm:ss')}
                    </p>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.scenario')}:
                    </p>
                    <p className="m-0">
                      {!filteredScenario[0] ? (
                        i18n.t('td.scenario_not_found')
                      ) : (
                        <span
                          className="scenario-list"
                          style={{
                            backgroundColor:
                              theme === 'light'
                                ? filteredScenario[0].colorLight
                                : filteredScenario[0].color,
                            color:
                              theme === 'light'
                                ? filteredScenario[0].color
                                : '#fff',
                          }}
                        >
                          {i18n.t(filteredScenario[0].title)}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0" style={{ minWidth: 70 }}>
                      {i18n.t('td.assignee')}:
                    </p>
                    {observation.responder && filteredAssignee ? (
                      <Link
                        className={`ph-no-capture d-inline btn-link btn-link-${theme}`}
                        to={`/profile/${filteredAssignee.id}`}
                      >
                        {filteredAssignee.username || observation.responder}
                      </Link>
                    ) : (
                      i18n.t('td.not_assigned')
                    )}
                  </div>

                  {/* <p className="m-0">
                    <span>
                      {i18n.t('td.duration')}
                      :
                    </span>
                    {' '}
                    {moment.unix(observation!.inferenceTime).format('HH:mm:ss')}
                  </p> */}
                </Col>
              </Row>
            </div>

            <div
              className={`border border-${theme} border-radius p-4 mt-4 hidden-scroll-y`}
              style={{ height: 350 }}
            >
              <h6>{i18n.t('timeline.title')}</h6>

              {observation.timeline && (
                <Table
                  responsive="sm alerts-table overflow-hidden"
                  variant={theme}
                >
                  <tbody>
                    {[...observation.timeline] // Create a shallow copy of observation.timeline
                      .sort(
                        (a: TimeLine, b: TimeLine) => b.timestamp - a.timestamp,
                      )
                      .map((item: TimeLine) => (
                        <tr
                          style={{ height: 48 }}
                          key={item.timestamp}
                          className="ph-no-capture"
                        >
                          <td>
                            {moment
                              .unix(item.timestamp)
                              .format('YYYY-MM-DD, HH:mm:ss')}
                          </td>
                          <td>{item.message}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>,

          <Col md={4} key="comment">
            <div className={`border border-${theme} border-radius h-100 p-4`}>
              <Row className="h-100">
                <Col
                  md="12"
                  className="d-flex flex-column justify-content-between hidden-scroll-y"
                  style={{ height: 638 }}
                >
                  <h6>{i18n.t('comments.title')}</h6>
                  {(observation.comments || []).length !== 0 ? (
                    <div className="observation-comments">
                      {(observation.comments || []).map((item: Comments) =>
                        item.sender === user?.email ? (
                          <div className="comment-sender" key={item.timestamp}>
                            <p className="name text-end">
                              {i18n.t('text.you')}
                            </p>
                            <p className="message ph-no-capture">{`${item.comment} : ${moment.unix(item.timestamp).format('h:mma')}`}</p>
                          </div>
                        ) : (
                          <div
                            className="comment-receiver"
                            key={item.timestamp}
                          >
                            <p className="name ph-no-capture">{item.sender}</p>
                            <p className="message ph-no-capture">{`${item.comment}  ${moment.unix(item.timestamp).format('h:mma')}`}</p>
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <div className="observation-comments justify-content-center align-items-center text-center">
                      <Image className="mb-3" src={InboxIcon} />

                      <h6>{i18n.t('observation.comments.title')}</h6>
                      <p>{i18n.t('observation.comments.body')}</p>
                    </div>
                  )}

                  <div
                    className={`observation-input observation-input-${theme} border border-${theme} border-radius`}
                  >
                    <InputField
                      type="text"
                      value={comment}
                      className={`mb-12 input-${theme}`}
                      controlId="comment"
                      placeholder={`${i18n.t('input.comment.placeholder')}`}
                      onChange={(e) => setComment(e.target.value)}
                      disabled={onSensitiveObservationAction.loading}
                    />
                    <div className="d-flex justify-content-end">
                      {/* <CustomButton
                        className="button-with-loader me-2"
                        variant={`outline-${theme}`}
                        title="Add photo"
                        icon={AttachmentIcon}
                        onClick={() => toast.info('Feature is coming soon')}
                      /> */}
                      <CustomButton
                        className="button-with-loader"
                        variant={theme}
                        title={i18n.t('button.send')}
                        loading={onSensitiveObservationAction.loading}
                        disabled={
                          comment.length === 0 ||
                          onSensitiveObservationAction.loading
                        }
                        onClick={() => {
                          onSensitiveObservation('comments', comment);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>,
        ]}
      </Row>

      <ReportFalseModal
        modalValue={reportFalseModal}
        onCloseClick={(e) => handleShowReportFalse(e)}
      />

      <ConfirmationModal
        modalValue={confirmationModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />

      {observation && (
        <ShowObservationModal
          observation={observation}
          showModal={showModal}
          onCloseClick={() => handleClose()}
        />
      )}
    </div>
  );
};

export const ObservationDetailPage = memo(ObservationDetail);
