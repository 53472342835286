import { useQuery } from '@apollo/client';
import { GET_CAMERA } from '../../graphql/queries/cameras';
import { ScenarioName } from '../../typescript/observation/scenario';
import { Maybe } from '../../utils/typeUtils';

type GetCameraQueryVariables = {
  id: number;
};
export type XYCoordinate = [number, number];

export type Perimeter = {
  scenario: ScenarioName;
  position: [XYCoordinate[]];
};

type NoPerimeter = Record<string, never>;

export type CamerasScenarios = {
  id: number;
  perimeter: Perimeter | NoPerimeter;
  customer_scenario_label: {
    id: number;
    name: ScenarioName;
  };
};

type GetCameraQueryResponse = {
  cameras_by_pk: {
    id: number;
    name: string;
    status: Maybe<'ONLINE' | 'OFFLINE'>;
    image_url: string;
    legacy_id: string;
    cameras_scenarios: Array<CamerasScenarios>;
    observations_aggregate: {
      aggregate: {
        count: number;
      };
    };
  };
};

export const useGetCameraQuery = (variables: GetCameraQueryVariables) =>
  useQuery<GetCameraQueryResponse, GetCameraQueryVariables>(GET_CAMERA, {
    variables,
    fetchPolicy: 'network-only',
  });
