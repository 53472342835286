import { useQuery } from '@apollo/client';
import { GET_CHART_DATA } from '../../graphql/queries/dashboard';
import { ScenarioDBName } from '../../typescript/observation/scenario';

type GetChartDataQueryVariables = {
  from: number;
  until: number;
  camera_ids?: Array<number>;
};

export type Timeseries = {
  count: number;
  date?: string;
  range: string;
};

export type ChartItem = {
  id: number;
  scenario_names: ScenarioDBName[];
  timeseries: Timeseries[];
};

type GetChartDataQueryResponse = {
  chartData: ChartItem[];
};

export const useGetChartDataQuery = (variables: GetChartDataQueryVariables) =>
  useQuery<GetChartDataQueryResponse, GetChartDataQueryVariables>(
    GET_CHART_DATA,
    {
      variables,
      fetchPolicy: 'network-only',
    },
  );
