import React, { useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useVisibilityObserver } from 'react-visibility-observer';
import { ImageLoader } from './ImageLoader';
import ImageWithAnnotation from './ImageWithAnnotation';
import { urlTempImage, urlTempImageV2 } from '../../apis/api-request';
import { siteId } from '../../apis/tokens';
import { ReactComponent as OfflineCamera } from '../../assets/icons/offline-camera.svg';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import { ObservationProp } from '../../typescript/observation/observation';

interface Props {
  observation: ObservationProp;
  onImageClick: (selectedObservation: ObservationProp) => void;
}

export function LazyLoadedImage({ observation, onImageClick }: Props) {
  const { userCookie, user } = useContext(AuthContext) as AuthContextType;
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const [onImageLoading, setOnImageLoading] = useState(true);
  const [isImageFound, setIsImageFound] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [observationDetail, setObservationDetail] =
    useState<ObservationProp>(observation);
  const { isVisible } = useVisibilityObserver();

  const fetchImageUrl = async () => {
    if (userCookie && retryCount < 2) {
      const param = {
        customer_id: user?.customerId,
        site_id: Number(siteId),
        request_id: observationDetail.requestId,
        version: 'thumbnail',
      };
      const oldParam = { observation_id: observationDetail.id };
      const show_thumbnail = user?.customer.feature_flags.image?.show_thumbnail;
      try {
        const getImageUrl = show_thumbnail
          ? await urlTempImageV2(userCookie.userToken, param)
          : await urlTempImage(userCookie.userToken, oldParam);
        if (!getImageUrl || getImageUrl?.data.detail === 'Image not found') {
          const getOldImageUrl = await urlTempImage(
            userCookie.userToken,
            oldParam,
          );

          setObservationDetail({
            ...observationDetail,
            imageUrl: getOldImageUrl?.data.message || '',
          });
        } else {
          setObservationDetail({
            ...observationDetail,
            imageUrl: show_thumbnail
              ? getImageUrl?.data.signed_url || ''
              : getImageUrl?.data.message || '',
          });
        }
        setIsImageFound(true);
      } catch (error) {
        console.error('Error fetching image URL:', error);
        setIsImageFound(false);
        setRetryCount(retryCount + 1);
      } finally {
        setOnImageLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchImageUrl();
    }
  }, [isVisible]);

  if (!isVisible || onImageLoading) {
    return (
      <ImageLoader className={`loading-image loading-image-${theme} ms-2`} />
    );
  }

  if (!isImageFound) {
    return (
      <div
        className={`observation-image-container-offline ${theme === 'light' ? 'dark' : 'light'}`}
        style={{ width: 90, height: 50 }}
      >
        <OfflineCamera width="50%" height="50%" />
      </div>
    );
  }

  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isImageFound) {
      onImageClick(observationDetail);
    }
  };

  const showAnnotation =
    user?.customer.feature_flags.observations.show_annotation;
  const isAnnotation =
    showAnnotation && observationDetail.id >= Number(showAnnotation);

  if (isAnnotation) {
    return (
      <ImageWithAnnotation
        className={`add-cursor ms-2 ${onImageLoading && 'd-none'} ph-no-capture`}
        containerWidth="90px"
        containerHeight="50px"
        isList={false}
        onImageLoading={onImageLoading}
        isActionEnable={false}
        onClick={handleImageClick}
        onLoad={() => setOnImageLoading(false)}
        imageUrl={observationDetail.imageUrl}
        detections={observationDetail.detections}
      />
    );
  }

  return (
    <Image
      className={`add-cursor ms-2 ${onImageLoading && 'd-none'} ph-no-capture`}
      onClick={handleImageClick}
      onLoad={() => setOnImageLoading(false)}
      width="90"
      height="50"
      src={observationDetail.imageUrl}
    />
  );
}

export default LazyLoadedImage;
