import React, { FC } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  className?: string;
  controlId: string;
  type?: string;
  name?: string | undefined;
  value?: string | number | string[] | undefined;
  required?: boolean;
  title?: string;
  placeholder: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const InputField: FC<Props> = function InputField({
  className,
  controlId,
  type,
  value,
  name,
  required,
  title,
  placeholder,
  disabled,
  readOnly,
  onChange,
}) {
  return (
    <Form.Group className={className} controlId={controlId}>
      {title && <Form.Label className="weight-500">{title}</Form.Label>}
      <Form.Control
        className="rounded-pill form-control-input"
        value={value}
        name={name}
        required={required}
        type={type}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </Form.Group>
  );
};

InputField.defaultProps = {
  className: '',
  type: 'text',
  name: undefined,
  title: undefined,
  value: undefined,
  required: false,
  disabled: false,
  readOnly: false,
  onChange: undefined,
};

export default InputField;
