import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.REACT_APP_MIXPANEL || '';

mixpanel.init(mixpanelToken, {
  api_host: 'https://api-eu.mixpanel.com',
});
const actions = {
  identify: (id: any) => {
    mixpanel.identify(id);
  },
  alias: (id: any) => {
    mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props);
    },
  },
  reset: () => {
    mixpanel.reset();
  },
};

const Mixpanel = actions;

export default Mixpanel;
