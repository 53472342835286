import React, { FC, useState } from 'react';
import { Col, Image } from 'react-bootstrap';
import emptylist from '../../assets/empty-list.svg';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';
import AddCameraModal from '../modal/AddCameraModal';

interface Props {
  className?: string;
}

const EmptyObservationsList: FC<Props> = function EmptyObservationsList({
  className,
}) {
  // Modal
  const [showAddCameraModal, setShowAddCameraModal] = useState<boolean>(false);

  return (
    <>
      <Col
        md={6}
        className={`
        text-center
        mx-auto
        align-self-center
        d-flex
        flex-column
        justify-content-center
        align-items-center
        flex-1
        ${className}
      `}
      >
        <Image className="mb-3" src={emptylist} />
        <h6>{i18n.t('landing.empty.list.title')}</h6>
        <p>{i18n.t('landing.empty.list.body')}</p>

        <CustomButton
          title={i18n.t('button.camera.add')}
          className="button-with-loader mx-auto mt-4"
          variant="primary"
          type="button"
          onClick={() => setShowAddCameraModal(!showAddCameraModal)}
        />
      </Col>

      <AddCameraModal
        show={showAddCameraModal}
        onCloseClick={() => setShowAddCameraModal(!showAddCameraModal)}
      />
    </>
  );
};

EmptyObservationsList.defaultProps = {
  className: '',
};

export default EmptyObservationsList;
