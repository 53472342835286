import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back-icon.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';

interface Props {
  title: string;
  fromUrl: string;
}
export function Breadcrumb({ title, fromUrl }: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();

  return (
    <Button
      className="btn icon"
      variant={theme === 'dark' ? 'transparent' : ''}
      onClick={() => {
        navigate(fromUrl);
      }}
      style={{
        textDecoration: 'none !important',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 0,
        width: 'fit-content',
        fontWeight: 500,
      }}
    >
      <BackIcon />
      <span className="ms-2">{title}</span>
    </Button>
  );
}
