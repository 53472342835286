/* eslint-disable no-plusplus */
import React, { FC, useContext } from 'react';
import { Pagination } from 'react-bootstrap';
import { ReactComponent as Next } from '../../assets/icons/next.svg';
import { ReactComponent as Prev } from '../../assets/icons/prev.svg';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { ThemeContextType } from '../../typescript/context/theme';
import i18n from '../../utils/i18n';

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

const CustomPagination: FC<Props> = function CustomPagination({
  currentPage,
  totalPages,
  onPageChange,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const range = 5;
  let start = Math.max(1, currentPage - Math.floor(range / 2));
  const end = Math.min(totalPages, start + range - 1);

  if (end - start + 1 < range) {
    start = Math.max(1, end - range + 1);
  }

  const pageNumbers: (number | '...')[] = [];

  // Precompute page numbers without ellipsis dots
  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination
      className={`custom-pagination custom-pagination-${theme} pagination-${theme}`}
    >
      <Pagination.Prev
        className="page-item-prev icon"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <Prev className="me-12" />
        {i18n.t('button.previous')}
      </Pagination.Prev>
      {start > 1 && (
        <>
          <Pagination.Item onClick={() => onPageChange(1)}>1</Pagination.Item>
          {start > 2 && <Pagination.Ellipsis />}
        </>
      )}
      {pageNumbers.map((pageNumber, index) => (
        <Pagination.Item
          key={pageNumber}
          active={currentPage === pageNumber}
          disabled={pageNumber === '...'}
          onClick={() => {
            if (typeof pageNumber === 'number') {
              onPageChange(pageNumber);
            }
          }}
        >
          {pageNumber}
        </Pagination.Item>
      ))}
      {end < totalPages && (
        <>
          {end < totalPages - 1 && <Pagination.Ellipsis />}
          <Pagination.Item onClick={() => onPageChange(totalPages)}>
            {totalPages}
          </Pagination.Item>
        </>
      )}
      <Pagination.Next
        className="page-item-next icon"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        {i18n.t('button.next')}
        <Next className="ms-12" />
      </Pagination.Next>
    </Pagination>
  );
};

export default CustomPagination;
