import Mixpanel from './Mixpanel';
import Posthog from './Posthog';
import { useAuthContext } from '../contextapi/AuthProvider';

type TrackButtonClickData = {
  title: string;
};
type TrackSwtichClickData = {
  title: string;
  value: boolean;
};

type TrackPageData = {
  url: string;
  theme: string;
};

type TrackAlertsData = {
  alertId: number;
  numCameras: number;
  scenario?: string;
  numObservations: number;
  timeframe: number;
  channels: Array<string | undefined>;
};

type TrackDeleteAlertsData = {
  alertId: number;
};

type TrackScenarioTagClickData = {
  title: string;
};

type TrackBarClickData = {
  timeRange: {
    title: string;
    value: number;
    end: number;
  };
  scenarios?: Array<string> | string;
};

type WithCustomerId<T extends object> = T & { customerId: string };

const Tracker = {
  buttonClick: (data: WithCustomerId<TrackButtonClickData>) => {
    Mixpanel.track('Button Click', data);
    Posthog.capture('button_click', data);
  },
  switchClick: (title: string, data: WithCustomerId<TrackSwtichClickData>) => {
    Mixpanel.track(title, data);
    Posthog.capture(title, data);
  },
  page: (data: WithCustomerId<TrackPageData>) => {
    Mixpanel.track('Page View', data);
    Posthog.capture('$pageview', data);
  },
  alerts: (title: string, data: WithCustomerId<TrackAlertsData>) => {
    Mixpanel.track(title, data);
    Posthog.capture(title, data);
  },
  deleteAlerts: (
    title: string,
    data: WithCustomerId<TrackDeleteAlertsData>,
  ) => {
    Mixpanel.track(title, data);
    Posthog.capture(title, data);
  },
  scenarioTag: (
    title: string,
    data: WithCustomerId<TrackScenarioTagClickData>,
  ) => {
    Mixpanel.track(title, data);
    Posthog.capture(title, data);
  },

  barClick: (title: string, data: WithCustomerId<TrackBarClickData>) => {
    Mixpanel.track(title, data);
    Posthog.capture(title, data);
  },
};

export const useTracker = () => {
  const { user } = useAuthContext();

  return {
    trackButtonClick: (data: TrackButtonClickData) => {
      Tracker.buttonClick({
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
    trackSwitchClick: (title: string, data: TrackSwtichClickData) => {
      Tracker.switchClick(title, {
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
    trackPage: (data: TrackPageData) => {
      Tracker.page({
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
    trackAlerts: (title: string, data: TrackAlertsData) => {
      Tracker.alerts(title, {
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
    trackDeleteAlerts: (title: string, data: TrackDeleteAlertsData) => {
      Tracker.deleteAlerts(title, {
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
    trackScenarioTagClick: (title: string, data: TrackScenarioTagClickData) => {
      Tracker.scenarioTag(title, {
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
    trackBarClick: (title: string, data: TrackBarClickData) => {
      Tracker.barClick(title, {
        ...data,
        customerId: user?.customer.trackingId || '',
      });
    },
  };
};
