import moment from 'moment';
import React from 'react';
import { Col } from 'react-bootstrap';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import CustomLegend from './CustomLegend';
import CustomTooltip from './CustomTooltip';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import {
  CombinedDataItem,
  combineObservations,
  GroupingItemProp,
  updatedDate,
} from '../../typescript/grouping/grouping-observation';
import { convertToScenarioName } from '../../typescript/observation/scenario';
import i18n from '../../utils/i18n';

interface Props {
  groupData: Array<GroupingItemProp>;
}

export function ObservationStatistic({ groupData }: Props) {
  const { scenariosList } = useAuthContext();
  const { timeRange } = useTimeRangeContext();
  const allScenario = groupData.map((group) => group.scenario_names[0]);

  const convertedScenarios = allScenario.map(convertToScenarioName);

  const filteredScenario =
    convertedScenarios &&
    scenariosList.filter((element) =>
      convertedScenarios.includes(element.value),
    );

  const addMissingDays: Array<GroupingItemProp> = groupData.map((group) => ({
    ...group,
    timeseries: updatedDate(
      group.timeseries.map((item) => ({
        count: item.count,
        date: moment(item.range).format('DD MMM'),
        range: item.range,
      })),
      timeRange.text,
      timeRange.value,
      timeRange.end,
      timeRange.isHourly,
    ),
  }));
  const formated: Array<CombinedDataItem> = combineObservations(
    addMissingDays,
  ).map((item) => ({
    ...item,
    date: moment(item.range).format('DD MMM'),
  }));

  const graphTitle =
    timeRange.value === 1 || timeRange.value === 7
      ? i18n.t('group_observations.hourly_statistic')
      : i18n.t('group_observations.daily_statistic');
  return (
    <Col lg={12}>
      <h6 className="mb-20">{graphTitle}</h6>
      <ResponsiveContainer width="100%" height={200} className="ph-no-capture">
        <LineChart data={formated}>
          <CartesianGrid
            strokeOpacity={0.5}
            strokeDasharray="0"
            vertical={false}
          />

          <YAxis tickMargin={10} />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CustomTooltip />}
          />
          {filteredScenario.map((scenario) => (
            <Line
              key={scenario.value}
              type="monotone"
              dataKey={scenario.value}
              stroke={scenario.color}
              dot={false}
            />
          ))}
          <Legend
            content={<CustomLegend />}
            // wrapperStyle={{ bottom: -10 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Col>
  );
}
