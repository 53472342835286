import { gql } from '@apollo/client';

export const GET_GROUPDATA = gql`
  query GET_GROUPS(
    $from: Int!
    $until: Int!
    $customer_scenario_label_ids: [Int]
  ) {
    observation_group_statistics_list(
      from: $from
      until: $until
      customer_scenario_label_ids: $customer_scenario_label_ids
    ) {
      id
      camera_names
      cameras {
        count
        id
        name
      }
      default_assigned
      count
      default_priority
      name
      previous_count
      scenario_names
      trend
      timeseries {
        count
        range
      }
    }

    observations_aggregate {
      aggregate {
        count
      }
    }
  }
`;
