import React, { useState } from 'react';
import { Col, Image } from 'react-bootstrap';
import emptylist from '../../assets/empty-list.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-dark.svg';
import { useCameraUpdateContext } from '../../contextapi/CameraUpdateProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';
import { CameraSettingsModal } from '../modal/CameraSettingsModal';

export function EmptyCameraObservations() {
  // Context
  const { theme } = useThemeContext();
  const { camera } = useCameraUpdateContext();

  // Modal
  const [showCameraSettingsModal, setShowCameraSettingsModal] = useState(false);

  return (
    <>
      <Col
        md={6}
        style={{
          textAlign: 'center',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Image className="mb-3" src={emptylist} />
        <h6>{i18n.t('page.camera_details.no_observations.title')}</h6>
        <p>{i18n.t('page.camera_details.no_observations.body')}</p>

        <CustomButton
          className="button-with-loader"
          icon={SettingsIcon}
          variant={`outline-${theme}`}
          title={i18n.t('page.camera_details.settings')}
          onClick={() => setShowCameraSettingsModal(!showCameraSettingsModal)}
        />
      </Col>

      {camera && (
        <CameraSettingsModal
          cameraLegacyId={camera.id}
          show={showCameraSettingsModal}
          onCloseClick={() => {
            setShowCameraSettingsModal(!showCameraSettingsModal);
          }}
        />
      )}
    </>
  );
}
