/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */

import moment from 'moment-timezone';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import AlertNameFilter from './filterModal/AlertNameFilter';
import CameraFilter from './filterModal/CameraFilter';
import DateTimeFilter from './filterModal/DateTimeFilter';
import ScenarioFilter from './filterModal/ScenarioFilter';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { AuthContext } from '../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { WhereQueryProps } from '../../graphql/queries/alerts';
import { CameraProps } from '../../typescript/camera/camera';
import { AuthContextType } from '../../typescript/context/auth';
import { CameraContextType } from '../../typescript/context/camera';
import { ThemeContextType } from '../../typescript/context/theme';
import { Scenario } from '../../typescript/observation/scenario';
import { AlertProps } from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';

interface Props {
  data: WhereQueryProps | null;
  show: boolean;
  alertRules: Array<AlertProps> | [];

  isCamera?: boolean;
  isScenario?: boolean;

  onApplyClick: (obj: WhereQueryProps | null) => void;
  onCloseClick: () => void;
}

const FilterModal: FC<Props> = function FilterModal({
  data,
  show,
  alertRules,
  onApplyClick,
  onCloseClick,
}) {
  const { userCookie, clientUsersList, scenariosList } = useContext(
    AuthContext,
  ) as AuthContextType;
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { cameraList } = useContext(CameraUpdateContext) as CameraContextType;

  const [selectedAlertList, setSelectedAlertList] = useState<Array<AlertProps>>(
    [],
  );
  const [selectedCameraList, setSelectedCameraList] = useState<
    Array<CameraProps>
  >([]);
  const [selectedScenarioList, setSelectedScenarioList] = useState<Scenario[]>(
    [],
  );

  const [filterData, setFilterData] = useState<WhereQueryProps | null>(data);

  const isRemoveButton =
    filterData ||
    selectedCameraList.length !== 0 ||
    selectedScenarioList.length !== 0;

  useEffect(() => {
    const subscribe = async () => {
      const allCamerasList = cameraList;
      const allScenariosList = scenariosList;
      if (data?.alert_incident_observations) {
        const filterArray = allCamerasList.filter(
          (item) =>
            data?.alert_incident_observations?.observation?.camera_id?._in.indexOf(
              item.sql_id,
            ) !== -1,
        );
        setSelectedCameraList(filterArray);
      }
      if (data?.alert_rule?.id) {
        const filterAlertArray = alertRules.filter(
          (item) => data?.alert_rule?.id?._in.indexOf(item.id) !== -1,
        );
        setSelectedAlertList(filterAlertArray);
      }
      const filterScenarioArray = allScenariosList.map((item) => ({
        ...item,
        active: data?.alert_rule?.customer_scenario_label_id
          ? data?.alert_rule.customer_scenario_label_id?._in.indexOf(
              item.id || 0,
            ) !== -1
          : false,
      }));

      setSelectedScenarioList(filterScenarioArray);
    };
    subscribe();
  }, [userCookie, cameraList, clientUsersList, scenariosList, data]);

  // const filteredStatus = statusList.filter(
  //   (status) => status.value === filterData?.status?._eq,
  // );

  // const filteredPriority = priorityList.filter(
  //   (priority) => priority.value === filterData?.priority?._eq,
  // );

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={show}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.filter.title')}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          // variant="no-background"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0">
          <Col md={12}>
            {filterData?.created_at !== undefined && [
              <p className="mb-1 weight-600 " key="title">
                {i18n.t('filter.date_and_time.title')}
              </p>,
              <CustomButton
                key="button"
                title={`
                  ${moment(filterData?.created_at._gte).format('YYYY-MM-DD, HH:mm:ss')}
                  ~
                  ${moment(filterData?.created_at._lte).format('YYYY-MM-DD, HH:mm:ss')}
                `}
                className="button-with-loader mb-12 ph-no-capture"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...filterData };
                  updateFilter.created_at = undefined;
                  setFilterData(updateFilter);
                }}
              />,
            ]}

            {selectedAlertList.length !== 0 && [
              <p className="mb-1 weight-600" key="title">
                {i18n.t('filter.alert.title')}
              </p>,
              <div key="value">
                {selectedAlertList.map((item) => (
                  <CustomButton
                    key="button"
                    title={item.name}
                    className="mb-2 me-2 ph-no-capture"
                    variant={`${theme}`}
                    type="button"
                    rightIcon={CancelIcon}
                    onClick={() => {
                      const updateFilter = { ...filterData };
                      const activeAlertList = selectedAlertList.filter(
                        (filterItem) => filterItem.id !== item.id,
                      );
                      updateFilter.alert_rule =
                        activeAlertList.length !== 0
                          ? {
                              id: {
                                _in: activeAlertList.map((alert) => alert.id),
                              },
                            }
                          : undefined;

                      setFilterData(updateFilter);
                      setSelectedAlertList(activeAlertList);
                    }}
                  />
                ))}
              </div>,
            ]}

            {selectedCameraList.length !== 0 && [
              <p className="mb-1 weight-600" key="title">
                {i18n.t('filter.camera.title')}
              </p>,
              <div key="value">
                {selectedCameraList.map((item) => (
                  <CustomButton
                    key="button"
                    title={item.name}
                    className="mb-2 me-2 ph-no-capture"
                    variant={`${theme}`}
                    type="button"
                    rightIcon={CancelIcon}
                    onClick={() => {
                      const updateFilter = { ...filterData };
                      const activeCameraList = selectedCameraList.filter(
                        (filterItem) => filterItem.sql_id !== item.sql_id,
                      );
                      updateFilter.alert_incident_observations =
                        activeCameraList.length !== 0
                          ? {
                              observation: {
                                camera_id: {
                                  _in: activeCameraList.map(
                                    (camera) => camera.sql_id,
                                  ),
                                },
                              },
                            }
                          : undefined;
                      setFilterData(updateFilter);
                      setSelectedCameraList(activeCameraList);
                    }}
                  />
                ))}
              </div>,
            ]}

            {selectedScenarioList.filter((item) => item.active).length !==
              0 && [
              <p className="mb-1 weight-600" key="title">
                {i18n.t('filter.scenarios.title')}
              </p>,
              <div key="value">
                {selectedScenarioList
                  .filter((item) => item.active)
                  .map((item) => (
                    <CustomButton
                      key={item.value}
                      title={i18n.t(item.title)}
                      className="mb-2 me-2"
                      variant={`${theme}`}
                      type="button"
                      rightIcon={CancelIcon}
                      onClick={() => {
                        const updateScenarioList = selectedScenarioList.map(
                          (scenario) =>
                            scenario.id === item.id
                              ? { ...scenario, active: false }
                              : scenario,
                        );
                        const activeScenarioList = updateScenarioList.filter(
                          (scenario) => scenario.active,
                        );
                        const updateFilter = { ...filterData };

                        updateFilter.alert_rule =
                          activeScenarioList.length !== 0
                            ? {
                                customer_scenario_label_id: {
                                  _in: activeScenarioList.map(
                                    (scenario) => scenario.id!,
                                  ),
                                },
                              }
                            : undefined;
                        setFilterData(updateFilter);
                        setSelectedScenarioList(updateScenarioList);
                      }}
                    />
                  ))}
              </div>,
            ]}

            {/* {filterData?.status !== undefined && [
              <p className="mb-1 weight-600 " key="title">{i18n.t('filter.status.title')}</p>,
              <CustomButton
                key="button"
                title={i18n.t(filteredStatus[0]?.title)}
                className="button-with-loader mb-12"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...filterData };
                  updateFilter.status = undefined;
                  setFilterData(updateFilter);
                }}
              />,
            ]} */}

            {/* {filterData?.priority !== undefined && [
              <p className="mb-1 weight-600 " key="title">{i18n.t('filter.priority.title')}</p>,
              <CustomButton
                key="button"
                title={i18n.t(filteredPriority[0].title)}
                className="button-with-loader mb-12"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...filterData };
                  updateFilter.priority = undefined;
                  setFilterData(updateFilter);
                }}
              />,
            ]} */}

            {isRemoveButton && (
              <CustomButton
                title={i18n.t('button.remove_filters')}
                className="button-with-loader ms-auto"
                variant={`outline-${theme}`}
                type="button"
                onClick={() => {
                  const filterScenarioArray = selectedScenarioList.map(
                    (item) => ({
                      ...item,
                      active: false,
                    }),
                  );

                  setSelectedScenarioList(filterScenarioArray);
                  setFilterData(null);
                  setSelectedAlertList([]);
                  setSelectedCameraList([]);
                }}
              />
            )}
          </Col>

          <DateTimeFilter
            onChange={(dates: any) => {
              const convertedDates = dates
                ? dates.map((date: any) => moment(date).unix())
                : [];
              const updateFilter = { ...filterData };
              const start = Number(convertedDates[0]);
              const end = Number(convertedDates[1]);
              const timestamp = {
                _gte: moment.unix(start).utc().format('YYYY-MM-DDTHH:mm:ss'),
                _lte: moment.unix(end).utc().format('YYYY-MM-DDTHH:mm:ss'),
              };

              updateFilter.created_at = timestamp;
              setFilterData(updateFilter);
            }}
          />

          <AlertNameFilter
            alertList={alertRules}
            selectedAlertList={selectedAlertList}
            onClickSelectedAlertList={(alert) => {
              const updateFilter = { ...filterData };
              const id = alert.map((item) => Number(item.value));
              updateFilter.alert_rule =
                alert.length !== 0
                  ? {
                      id: {
                        _in: id,
                      },
                    }
                  : undefined;
              setFilterData(updateFilter);
              setSelectedAlertList(
                alertRules.filter((o1) => id.some((o2) => o1.id === o2)),
              );
            }}
          />
          <CameraFilter
            cameraList={cameraList}
            selectedCameraList={selectedCameraList}
            onClickSelectedCameraList={(cameras) => {
              const updateFilter = { ...filterData };
              const id = cameras.map((item) => Number(item.value));
              updateFilter.alert_incident_observations =
                cameras.length !== 0
                  ? {
                      observation: {
                        camera_id: {
                          _in: id,
                        },
                      },
                    }
                  : undefined;

              setFilterData(updateFilter);
              setSelectedCameraList(
                cameraList.filter((o1) => id.some((o2) => o1.sql_id === o2)),
              );
            }}
          />

          <ScenarioFilter
            selectedScenarioList={selectedScenarioList}
            onClick={(sceanrios) => {
              const updateFilter = { ...filterData };
              const id = sceanrios
                .filter((item) => item.active)
                .map((sceanrio) => sceanrio.id!);
              updateFilter.alert_rule =
                sceanrios.length !== 0
                  ? {
                      customer_scenario_label_id: {
                        _in: id,
                      },
                    }
                  : undefined;
              setFilterData(updateFilter);
              setSelectedScenarioList(sceanrios);
            }}
          />

          {/* <StatusFilter
            statusOfObservations={filteredStatus[0]}
            onClick={(item: TitleAndValue) => {
              const updateFilter = { ...filterData };
              updateFilter.status = { _eq: String(item.value) };
              setFilterData(updateFilter);
            }}
          /> */}

          {/* <PriorityFilter
            selectedPriority={filteredPriority[0]}
            onClick={(item: TitleAndValue) => {
              const updateFilter = { ...filterData };
              updateFilter.priority = { _eq: String(item.value) };
              setFilterData(updateFilter);
            }}
          /> */}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          title={i18n.t('button.cancel')}
          className="button-with-loader"
          variant={`outline-${theme}`}
          type="button"
          onClick={() => onCloseClick()}
        />
        <CustomButton
          title={i18n.t('button.confirm')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={() => {
            onApplyClick(filterData);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

FilterModal.defaultProps = {
  isCamera: false,
  isScenario: false,
};
export default FilterModal;
