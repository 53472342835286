import React, { useContext } from 'react';
import { TooltipProps } from 'recharts';
import { AuthContext } from '../../../../contextapi/AuthProvider';
import { ThemeContext } from '../../../../contextapi/ThemeProvider';
import { AuthContextType } from '../../../../typescript/context/auth';
import { ThemeContextType } from '../../../../typescript/context/theme';
import i18n from '../../../../utils/i18n';

type CustomTooltipProps = TooltipProps<number, string> & {
  totalObservations?: number;
};

export function DonutTooltip({
  active,
  payload,
  totalObservations,
}: CustomTooltipProps) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    const percentage = ((value || 0) / (totalObservations || 0)) * 100;

    const scenario = scenariosList.find((item) => item.value === name);
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius d-flex align-items-center`}
      >
        <div
          className="me-1"
          style={{
            width: 10,
            height: 10,
            background: scenario?.color,
          }}
        />
        <p className="m-0 ">{`${i18n.t(scenario?.title || '')} — ${percentage.toFixed(2)}% (${value})`}</p>
      </div>
    );
  }

  return null;
}
