/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { FC, useContext } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { ThemeContextType } from '../../typescript/context/theme';

interface Props {
  active?: boolean;
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
  label?: any;
}
const CameraTooltip: FC<Props> = function CameraTooltip({
  active,
  payload,
  label,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;

  if (active && payload && payload.length) {
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius`}
      >
        <p className="m-0">{`${payload[0].payload.name}: ${payload[0].payload.count}`}</p>
      </div>
    );
  }
  return null;
};

CameraTooltip.defaultProps = {
  active: undefined,
  payload: undefined,
  label: undefined,
};

export default CameraTooltip;
