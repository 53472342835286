import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as EmptyChartIcon } from '../../assets/dashboard/empty-chart.svg';
import i18n from '../../utils/i18n';

export function EmptyChart() {
  return (
    <Row className="group-observations mt-32">
      <Col
        md={12}
        className="d-flex flex-column align-items-center justify-content-between"
      >
        <EmptyChartIcon className="mb-20" />
        <h6>{i18n.t('dashboard.empty.chart.title')}</h6>
        <p>{i18n.t('dashboard.empty.chart.body')}</p>
      </Col>
    </Row>
  );
}
