import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import i18n from '../../../utils/i18n';

const shouldDisableDate = (date: any) => {
  const currentDate = new Date();
  return date > currentDate;
};

interface Props {
  onChange:
    | ((
        value: DateRange | null,
        event: React.SyntheticEvent<Element, Event>,
      ) => void)
    | undefined;
}

const DateTimeFilter: FC<Props> = function dateTimeFilter({ onChange }) {
  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('filter.date_and_time.title')}</p>
      <p className="sub-color">{i18n.t('filter.date_and_time.body')}</p>

      <DateRangePicker
        format="yyyy-MM-dd, HH:mm:ss"
        shouldDisableDate={shouldDisableDate}
        onChange={onChange}
      />
    </Col>
  );
};

export default DateTimeFilter;
