import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as CameraIcon } from '../assets/icons/camera-dark.svg';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import CustomDropdownWithCheckbox, {
  DropdownProps,
} from '../components/elements/CustomDropdownWithCheckbox';
import { Loader } from '../components/elements/Loader';
import { ScenarioChart } from '../components/group-observation/ScenarioChart';
import { ScenarioObservationsList } from '../components/group-observation/ScenarioObservationsList';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { AuthContext } from '../contextapi/AuthProvider';
import { ThemeContext } from '../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { WhereQueryProps } from '../graphql/queries/observation';
import { GET_SCENARIO_DATA } from '../graphql/queries/scenarios';
import { AuthContextType } from '../typescript/context/auth';
import { ThemeContextType } from '../typescript/context/theme';
import {
  DaysListProps,
  GroupingItemProp,
} from '../typescript/grouping/grouping-observation';
import { convertToScenarioName } from '../typescript/observation/scenario';
import i18n from '../utils/i18n';

type VariableProps = {
  from: number;
  until: number;
  group_id: string | undefined;
  offset: number;
  camera_ids?: Array<number>;
  camera_ids_string?: string;
  order_by: { created: string };
  where: WhereQueryProps;
  limit: number;
};

export const GroupDetailPage = () => {
  const navigate = useNavigate();
  const { groupId } = useParams<{
    groupId: string;
  }>();
  const [searchParams] = useSearchParams();

  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { onTokenSave, scenariosList } = useContext(
    AuthContext,
  ) as AuthContextType;

  const limit = searchParams.get('limit')
    ? Number(searchParams.get('limit'))
    : 10;
  const pageNumber = searchParams.get('pageNo')
    ? Number(searchParams.get('pageNo'))
    : 1;
  const decode: string = searchParams.get('filter')
    ? decodeURI(String(searchParams.get('filter')))
    : '{}';
  const decodeCameraIds: string = searchParams.get('cameraIds')
    ? decodeURI(String(searchParams.get('cameraIds')))
    : '[]';

  const selectedCameraIds: Array<DropdownProps> = JSON.parse(decodeCameraIds);

  const { timeRange, generateTimeRangeUrlParam } = useTimeRangeContext();

  const filter: WhereQueryProps = JSON.parse(decode);

  const [queryVar, setQueryVar] = useState<WhereQueryProps>(filter);

  const defaultOffset = pageNumber === 1 && 0;
  const offset = defaultOffset ? 0 : (pageNumber - 1) * limit;
  const [isOrder, setIsOrder] = useState(true);

  const [totalPages, setTotalPage] = useState(0);

  // camera
  const [allCameraList, setAllCameraList] = useState<Array<DropdownProps>>([]);
  const [selectAllCameras, setSelectAllCameras] = useState<boolean>(true);

  const filterCameraIds =
    selectedCameraIds.length === 0
      ? undefined
      : selectedCameraIds
          .filter((camera) => camera.isChecked)
          .map((item) => Number(item.value));

  const [variables] = useState<VariableProps>({
    from: timeRange.value,
    until: timeRange.end,
    group_id: groupId,
    camera_ids: filterCameraIds,
    camera_ids_string: filterCameraIds
      ? `{${filterCameraIds.join(', ')}}`
      : undefined,
    offset,
    order_by: { created: !isOrder ? 'asc' : 'desc' },
    limit,
    where: {
      is_false_positive: { _eq: false },
      ...queryVar,
    },
  });

  const { loading, error, data, refetch } = useQuery(GET_SCENARIO_DATA, {
    variables,
    fetchPolicy: 'network-only',
  });

  const emptyList = data ? data.observation_group_statistics.length === 0 : [];
  const groups: GroupingItemProp = data
    ? data.observation_group_statistics[0]
    : {};
  const observations = data ? data.fetch_observations_by_group_id : [];

  useEffect(() => {
    if (groups) {
      if (groups.cameras) {
        if (selectedCameraIds.length !== 0) {
          setSelectAllCameras(false);
          const defaultCameras = groups.cameras
            .filter((camera) =>
              selectedCameraIds.some(
                (innerCamera) => camera.id === innerCamera.value,
              ),
            )
            .map((item) => ({
              title: item.name,
              value: item.id,
              isChecked: true,
            }));
          setAllCameraList(defaultCameras);
        } else {
          const cameras =
            groups.cameras.length === 0
              ? []
              : groups.cameras.map((item) => ({
                  title: item.name,
                  value: item.id,
                  isChecked: true,
                }));
          setSelectAllCameras(true);
          setAllCameraList(cameras);
        }
      }
    }

    if (data) {
      setTotalPage(
        Math.ceil(
          data.fetch_observations_by_group_id_aggregate.aggregate.count / limit,
        ),
      );
    }
  }, [data, groups]);

  useEffect(() => {
    refetch();
  }, [refetch, variables]);

  useEffect(() => {
    if (error?.message === 'Authentication hook unauthorized this request') {
      onTokenSave('');
    }
  }, [error, onTokenSave]);

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  const convertedScenarios =
    !emptyList && groups.scenario_names.map(convertToScenarioName);

  const filteredScenario =
    convertedScenarios &&
    scenariosList.filter((element) =>
      convertedScenarios.includes(element.value),
    );

  const onFilterApplied = async (
    whereQuery: WhereQueryProps,
    listLimit: number,
    pageNo: number,
    cameraIds?: Array<DropdownProps> | null,
    updatedTimeRange?: DaysListProps,
  ) => {
    setQueryVar(whereQuery);
    const selectedCamera = cameraIds
      ? cameraIds?.filter((item) => item.isChecked).length ===
        allCameraList.length
      : allCameraList?.filter((item) => item.isChecked).length ===
        allCameraList.length;

    const filterValue = whereQuery ? JSON.stringify(whereQuery) : '{}';
    const cameraIdsValue = !selectedCamera
      ? JSON.stringify(cameraIds || allCameraList)
      : '[]';
    const timeRangeValue = generateTimeRangeUrlParam(
      updatedTimeRange || timeRange,
      true,
    );

    const urlParams = new URLSearchParams(searchParams);
    urlParams.set('limit', `${listLimit}`);
    urlParams.set('pageNo', `${pageNo}`);
    urlParams.set('filter', encodeURI(filterValue));
    urlParams.set('cameraIds', encodeURI(cameraIdsValue));
    urlParams.set('query', timeRangeValue);

    navigate({
      pathname: `/group-observations/${groupId}`,
      search: `?${urlParams.toString()}`,
    });
    window.location.reload();
  };

  return (
    <>
      <Breadcrumb
        title={`${i18n.t('dashboard.title')}`}
        fromUrl={`/dashboard?${generateTimeRangeUrlParam(timeRange)}`}
      />
      <div className="d-flex">
        <div className="mb-32 flex-1 d-flex align-items-center">
          <h5 className="m-0 me-24">{i18n.t('group_observations.title')}</h5>

          {!emptyList && (
            <p className="m-0 me-24">
              {`${i18n.t('td.scenario')}: `}
              {!filteredScenario ? (
                i18n.t('td.scenario_not_found')
              ) : (
                <span
                  className="scenario-list"
                  style={{
                    backgroundColor:
                      theme === 'light'
                        ? filteredScenario[0].colorLight || ''
                        : filteredScenario[0].color,
                    color:
                      theme === 'light' ? filteredScenario[0].color : '#fff',
                  }}
                >
                  {i18n.t(filteredScenario[0].title)}
                </span>
              )}
            </p>
          )}
        </div>
        <div className="mb-32 d-flex align-items-center justify-content-end">
          <TimeRangeDropdown
            className="me-12"
            onChange={(timeRange: DaysListProps) => {
              onFilterApplied({}, 10, 1, null, timeRange);
            }}
          />

          {allCameraList.length !== 0 && (
            <CustomDropdownWithCheckbox
              onSelectedAll={(value, fromChild) => {
                setSelectAllCameras(value);
                if (!fromChild) {
                  const selectAll = allCameraList.map((item) => ({
                    title: item.title,
                    value: item.value,
                    isChecked: value,
                  }));
                  setAllCameraList(selectAll);
                }
              }}
              selectedAll={selectAllCameras}
              className="rounded-pill"
              hideContextData="ph-no-capture"
              title={`${i18n.t('button.cameras')}`}
              selectAllTitle={`${i18n.t('button.select_all')}`}
              dropdownData={allCameraList}
              icon={CameraIcon}
              variant={`outline-${theme}`}
              bodyVariant={theme}
              onClick={(value) => {
                const selectAll = allCameraList.map((item) => {
                  if (item.value === value.value) {
                    return {
                      title: item.title,
                      value: item.value,
                      isChecked: !item.isChecked,
                    };
                  }
                  return item;
                });
                setAllCameraList(selectAll);
              }}
              confirmTitle={`${i18n.t('button.confirm')}`}
              isConfirm
              onConfirm={(cameraIds) => {
                onFilterApplied(queryVar, 10, 1, cameraIds);
              }}
            />
          )}
        </div>
      </div>

      {loading && (
        <div className="text-center empty-list">
          <Loader main />
        </div>
      )}
      {!loading && (
        <Row className="landing">
          {emptyList && (
            <Col className="mb-32">
              <h6>{`${i18n.t('group_detail.empty.no_observations_found_within')} ${i18n.t(timeRange.title).toLowerCase()}`}</h6>
            </Col>
          )}
          {!emptyList && <ScenarioChart groups={groups} />}

          {data.observation_group_statistics.length !== 0 && (
            <Col lg={12}>
              <h6 className="mb-16">{i18n.t('table.observations_overview')}</h6>
              <ScenarioObservationsList
                observationsList={observations}
                totalPages={totalPages}
                limit={limit}
                loading={loading}
                pageNumber={pageNumber}
                isOrder={isOrder}
                setIsOrder={setIsOrder}
                onFilterApplied={onFilterApplied}
                selectedScenariosIds={
                  filteredScenario
                    ? filteredScenario.map((item) => item.id || 0)
                    : []
                }
                selectedCameraIds={allCameraList
                  .filter((item) => item.isChecked)
                  .map((item) => item.value as number)}
                dayStart={timeRange.value}
                dayEnd={timeRange.end}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
