/* eslint-disable import/no-mutable-exports */
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Cookies } from 'react-cookie';
import { siteId } from '../apis/tokens';

export const GRAPHQL_API_URL = `${process.env.REACT_APP_GRAPH_QL_URL}/v1/graphql`;

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL,
});

const cookie = new Cookies();
const authLink = setContext((_, { headers }) => {
  const token = cookie.get('userToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
      ...(siteId && { 'X-Site-Id': `${siteId}` }),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { client };
