import React from 'react';
import { Button } from 'react-bootstrap';
import { Loader } from './Loader';
import { SpacingValue } from '../../scss/spacing';
import { useTracker } from '../../utils/Tracker';

type IconComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  variant?: string;
  title?: any;
  icon?: IconComponent;
  rightIcon?: IconComponent;
  iconSpacing?: SpacingValue;
  type?: 'button' | 'submit' | 'reset';
  isRounded?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function CustomButton({
  className = '',
  style,
  variant = '',
  icon: Icon,
  rightIcon: RightIcon,
  iconSpacing = 2,
  title = '',
  type = 'button',
  isRounded = false,
  loading = false,
  disabled = false,
  onClick,
}: Props) {
  const { trackButtonClick } = useTracker();
  return (
    <Button
      className={`${className} ${!isRounded && 'rounded-pill'} icon`}
      variant={variant}
      style={style}
      onClick={(e) => {
        trackButtonClick({
          title,
        });
        if (onClick) onClick(e);
      }}
      disabled={loading || disabled}
      type={type}
    >
      {Icon && <Icon className={`me-${iconSpacing}`} />}
      {title}
      {RightIcon && <RightIcon className={`ms-${iconSpacing}`} />}
      {loading && <Loader className="ms-12" />}
    </Button>
  );
}
