import React, { FC, useContext, useEffect, useState } from 'react';
import { Col, Dropdown, FormControl, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { ModalType } from '../../typescript/components/modal';
import { ThemeContextType } from '../../typescript/context/theme';
import {
  OBSERVATION_REPORT_REASONS,
  TitleAndValue,
} from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { CustomButton } from '../elements/CustomButton';

interface Props {
  modalValue: ModalType;
  onCloseClick: (isTrue: boolean) => void;
}
const ReportFalseModal: FC<Props> = function ReportFalseModal({
  modalValue,
  onCloseClick,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const [selectedReason, setSelectedReason] = useState<TitleAndValue | null>();

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onCloseClick(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={modalValue.show}
      onHide={() => onCloseClick(false)}
    >
      <Modal.Header>
        <Modal.Title>{modalValue.title}</Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          // variant="no-background"
          type="button"
          onClick={() => onCloseClick(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <p className="mb-1 weight-600 ">
              {i18n.t('modal.report.reason.title')}
            </p>
            <p className="sub-color">{i18n.t('modal.report.reason.body')}</p>

            <Dropdown>
              <Dropdown.Toggle
                className="rounded-pill"
                variant={`outline-${theme}`}
                id="dropdown-basic"
              >
                {!selectedReason
                  ? i18n.t('button.select_reason')
                  : i18n.t(selectedReason.title)}
              </Dropdown.Toggle>

              <Dropdown.Menu variant={theme}>
                {OBSERVATION_REPORT_REASONS.map((item: TitleAndValue) => (
                  <Dropdown.Item
                    key={item.title}
                    onClick={() => {
                      setSelectedReason(item);
                      // onClick(item);
                    }}
                  >
                    {i18n.t(item.title)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <hr />

            <p className="mb-1 weight-600 ">
              {i18n.t('modal.report.comment.title')}
            </p>
            <p className="sub-color">{i18n.t('modal.report.comment.body')}</p>

            <FormControl
              placeholder={`${i18n.t('input.comment.placeholder')}`}
              as="textarea"
              rows={5}
              className={`form-control-${theme}`}
            />

            <hr />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <CustomButton
          title={modalValue.cancelText}
          className="button-with-loader"
          variant={`outline-${theme}`}
          type="button"
          onClick={() => onCloseClick(false)}
        />
        <CustomButton
          title={modalValue.doneText}
          className="button-with-loader"
          variant={modalValue.buttonType}
          type="button"
          onClick={() => onCloseClick(true)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ReportFalseModal;
