import React, { useContext, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { CustomButton } from '../components/elements/CustomButton';
import AlertList from '../components/smart-alerts/AlertList';
import ManageTable from '../components/smart-alerts/ManageTable';
import { ThemeContext } from '../contextapi/ThemeProvider';
import { ThemeContextType } from '../typescript/context/theme';
import i18n from '../utils/i18n';

export const SmartAlertsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const type = searchParams.get('action') ? searchParams.get('action') : '';

  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const [key, setKey] = useState<number>(type === 'manage' ? 1 : 0);

  return (
    <>
      <Breadcrumb title={`${i18n.t('dashboard.title')}`} fromUrl="/" />
      <div className="d-flex mb-32 ">
        <h5 className="flex-1 m-0" style={{ height: 40 }}>
          {i18n.t('smart_notifications.title')}
        </h5>

        {key !== 0 && (
          <div className="d-flex align-items-center justify-content-end">
            <CustomButton
              className="button-with-loader"
              variant="primary"
              title={i18n.t('button.new_notifications_rule')}
              type="button"
              onClick={() => {
                navigate('/alerts/create');
              }}
            />
          </div>
        )}
      </div>

      <Row className="smart-alerts">
        <Col lg={12} className="d-flex flex-column">
          <Tabs
            defaultActiveKey={key}
            className={`mb-32 nav-tabs-${theme}`}
            onSelect={(k) => {
              setKey(Number(k));
              navigate({
                pathname: '/alerts',
                search: `${k === '1' ? '?action=manage' : ''}`,
              });
            }}
          >
            <Tab
              tabClassName="me-12"
              eventKey={0}
              title={i18n.t('alert.tab.alerts')}
            >
              {key === 0 && <AlertList />}
            </Tab>
            <Tab
              tabClassName="d-flex flex-column"
              eventKey={1}
              title={i18n.t('alert.tab.manage')}
            >
              {key === 1 && <ManageTable />}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
