/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import moment from 'moment';
import React, { FC, useContext } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { AuthContext } from '../../contextapi/AuthProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { ThemeContextType } from '../../typescript/context/theme';
import i18n from '../../utils/i18n';

interface Props {
  active?: boolean;
  payload?:
    | Payload<string | number | (string | number)[], string | number>[]
    | undefined;
  label?: any;
}
const CustomTooltip: FC<Props> = function CustomTooltip({
  active,
  payload,
  label,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;

  const scenarioList = payload && payload.map((item) => item.dataKey);

  const filteredScenario =
    scenarioList &&
    scenariosList.filter((element) => scenarioList.includes(element.value));

  if (active && payload && payload.length) {
    return (
      <div
        className={`h-100 p-3 border border-${theme} ${theme} border-radius`}
      >
        <p className="m-0 ph-no-capture">{`Date: ${moment(payload[0].payload.range).format('DD MMM, HH:mm')}`}</p>
        {filteredScenario?.map((item, index) => (
          <span
            key={item.value}
            className="scenario-list d-flex mt-12 ph-no-capture"
            style={{
              backgroundColor: theme === 'light' ? item.colorLight : item.color,
              color: theme === 'light' ? item.color : '#fff',
            }}
          >
            {`${i18n.t(item.title)}: ${payload[index].value}`}
          </span>
        ))}
      </div>
    );
  }
  return null;
};

CustomTooltip.defaultProps = {
  active: undefined,
  payload: undefined,
  label: undefined,
};

export default CustomTooltip;
