/* eslint-disable max-len */
import React, { FC, useContext, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import AlertItem from './AlertItem';
import FilterModal from './FilterModal';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as Arrow } from '../../assets/icons/up-arrow.svg';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { ObservationsSetInput } from '../../graphql/mutations';
import { WhereQueryProps } from '../../graphql/queries/alerts';
import { numberOfIncident } from '../../typescript/camera/camera';
import {
  defaultModalValue,
  ModalType,
} from '../../typescript/components/modal';
import { ThemeContextType } from '../../typescript/context/theme';
import {
  AlertIncidents,
  AlertProps,
} from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';
import EmptyAlertsList from '../default-component/EmptyAlertsList';
import { CustomButton } from '../elements/CustomButton';
import CustomDropdown from '../elements/CustomDropdown';
import CustomPagination from '../elements/CustomPagination';
import ConfirmationModal from '../modal/ConfirmationModal';
import ReportFalseModal from '../modal/ReportFalseModal';

interface Props {
  alerts: Array<AlertIncidents> | [];
  alertRules: Array<AlertProps> | [];
  filter: WhereQueryProps;
  limit: number;
  pageNumber: number;
  totalPages: number;

  isOrder: boolean;
  setIsOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setQueryVar: React.Dispatch<React.SetStateAction<WhereQueryProps | null>>;

  onSensitiveAlert: (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => void;
  onFilterApplied: (
    obj: WhereQueryProps | null,
    listLimit: number,
    pageNo: number,
  ) => void;
  onUpdateAlert: (
    observationIds: Array<number>,
    value: ObservationsSetInput,
  ) => void;
}
const AlertsTable: FC<Props> = function AlertsTable({
  alerts,
  alertRules,
  filter,
  limit,
  pageNumber,
  totalPages,
  isOrder,
  setIsOrder,
  setQueryVar,
  onSensitiveAlert,
  onFilterApplied,
  onUpdateAlert,
}) {
  // Context
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const [checkedAlerts, setCheckedAlerts] = useState<
    Array<{ id: number; alert_rule: AlertProps }>
  >([]);

  // Confirmation Modal
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);
  const [reportFalseModal, setReportFalseModal] =
    useState<ModalType>(defaultModalValue);
  const [buttonName, setButtonName] = useState<string | null>(null);

  // Filter Modal
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const observationIds = checkedAlerts.map((alert) => alert.id);

  const handleShowConfirmation = (isTrue: boolean) => {
    if (isTrue) {
      switch (buttonName) {
        case 'report':
          onUpdateAlert(observationIds, { is_false_positive: true });
          break;
        case 'acknowledged':
          onUpdateAlert(observationIds, { status: 'acknowledged' });
          break;
        case 'resolve':
          onUpdateAlert(observationIds, { status: 'resolve' });
          break;
        default:
          break;
      }
    }
    setReportFalseModal(defaultModalValue);
    setConfirmationModal(defaultModalValue);
  };

  const dropdownActionList = [
    {
      title: 'button.view_details',
      value: 'view_details',
    },
  ];

  return (
    <>
      <div className={`d-flex mb-24 action-header ${theme}`}>
        <div className="filter-button ms-auto">
          <CustomButton
            title={i18n.t('button.filters')}
            className="button-with-loader icon"
            variant={`outline-${theme}`}
            type="button"
            icon={FilterIcon}
            onClick={() => setShowFilter(!showFilter)}
          />
          {Object.keys(filter).length !== 0 && (
            <Badge className="rounded-circle" bg="danger">
              {Object.keys(filter).length}
            </Badge>
          )}
        </div>
      </div>
      {alerts.length === 0 && (
        <EmptyAlertsList key="empty-list" className="pt-32" />
      )}
      {alerts.length !== 0 && (
        <>
          <Table
            responsive={`sm alerts-table overflow-hidden border border-${theme} border-radius`}
            hover
            variant={theme}
          >
            <thead className="weight-500">
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    {/* <FormCheck
                      checked={checked}
                      className="me-12"
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setCheckedAlerts(alerts);
                        } else {
                          setCheckedAlerts([]);
                        }
                        setChecked(e.currentTarget.checked);
                      }}
                    /> */}
                    <div
                      onClick={() => {
                        setIsOrder(!isOrder);
                      }}
                      aria-hidden
                      className="d-flex align-items-center add-cursor"
                    >
                      {i18n.t('td.date')}
                      <Arrow
                        className={`ms-1 icon ${!isOrder && 'rotate-180'}`}
                      />
                    </div>
                  </div>
                </td>
                <td>{i18n.t('td.alert')}</td>
                <td>{i18n.t('td.scenario')}</td>
                <td>{i18n.t('td.camera_name')}</td>
                {/* <td>{i18n.t('td.assignee')}</td> */}
                {/* <td>{i18n.t('td.status')}</td> */}
                {/* <td>{i18n.t('td.priority')}</td> */}
                <td>{i18n.t('td.action')}</td>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert) => (
                <AlertItem
                  selectedAlerts={
                    checkedAlerts.filter((item) => item.id === alert.id)
                      .length !== 0
                  }
                  isAlertSelected={(selected: boolean, id: number) => {
                    if (selected) {
                      setCheckedAlerts([...checkedAlerts, alert]);
                    } else {
                      const withoutEl = checkedAlerts?.filter(
                        (elemet) => elemet.id !== id,
                      );
                      setCheckedAlerts(withoutEl);
                    }
                  }}
                  key={alert.id}
                  alert={alert}
                  dropdownActionList={dropdownActionList}
                  onSensitiveAlertAction={(id, actionType, value) => {
                    onSensitiveAlert([id], actionType, value);
                  }}
                  onReportFalseModal={(id) => {
                    setCheckedAlerts(alerts.filter((item) => item.id === id));
                    setButtonName('report');
                    setReportFalseModal({
                      title: i18n.t('button.report_false'),
                      body: '',
                      buttonType: 'primary',
                      doneText: i18n.t('button.send'),
                      cancelText: i18n.t('button.cancel'),
                      show: true,
                    });
                  }}
                />
              ))}
            </tbody>
          </Table>
          <div className="table-bottom-actions">
            <div />
            {/* <CustomDropdownWithSubMenu
              disabled={observationIds.length === 0}
              dropdownData={dropdownList}
              mainClassName="position-absolute"
              variant={`outline-${theme}`}
              title={`${i18n.t('button.actions')}`}
              onClick={(selectValue) => {
                const { value, subMenu } = selectValue;
                onSensitiveAlert(observationIds, String(value), String(subMenu ? subMenu[0].value : ''));
              }}
            /> */}
            <CustomPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={(pageNo: number) => {
                onFilterApplied(filter, limit, pageNo);
              }}
            />

            <CustomDropdown
              title={`${i18n.t('button.show_number_of_items').replace('{value}', String(limit))}`}
              // title={`Show ${limit} items`}
              dropdownData={numberOfIncident.map((item: number) => ({
                title: item,
                value: item,
              }))}
              variant={`outline-${theme}`}
              bodyVariant={theme}
              onClick={(item) => {
                onFilterApplied(filter, Number(item.value), 1);
              }}
            />
          </div>
        </>
      )}

      <FilterModal
        show={showFilter}
        alertRules={alertRules}
        data={filter}
        onCloseClick={() => setShowFilter(!showFilter)}
        onApplyClick={(obj) => {
          let whereQuery: WhereQueryProps | null = { ...filter };
          whereQuery = obj;

          setQueryVar(whereQuery);
          onFilterApplied(whereQuery, limit, 1);
        }}
      />

      <ReportFalseModal
        modalValue={reportFalseModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />

      <ConfirmationModal
        modalValue={confirmationModal}
        onCloseClick={(e) => handleShowConfirmation(e)}
      />
    </>
  );
};

export default AlertsTable;
