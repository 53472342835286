import moment from 'moment';
import React, { FC, useContext } from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AuthContext } from '../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../contextapi/ThemeProvider';
import { AuthContextType } from '../../typescript/context/auth';
import { CameraContextType } from '../../typescript/context/camera';
import { ThemeContextType } from '../../typescript/context/theme';
import { AlertIncidents } from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';

interface Props {
  alert: AlertIncidents;
}

const AlertOverview: FC<Props> = function AlertOverview({ alert }) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { cameraList } = useContext(CameraUpdateContext) as CameraContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;

  const filteredCamera = alert.alert_rule_state.triggering_camera_ids
    ? cameraList.filter((o1) =>
        alert.alert_rule_state.triggering_camera_ids.some(
          (o2) => o1.sql_id === o2,
        ),
      )
    : [];

  const filteredScenario = scenariosList.filter(
    (scenario) =>
      scenario.id === Number(alert.alert_rule_state.customer_scenario_label_id),
  );
  const date = moment(alert.created_at).unix();

  return (
    <Col md={12} className="mb-20">
      <h6 className="mb-20">{i18n.t('alert_detail.alert_overview')}</h6>

      <div>
        <p>
          <span className="fw-bold">
            {i18n.t('alert_overview.alert_name')}:{' '}
          </span>
          {alert.alert_rule_state.name}
        </p>
        <p>
          <span className="fw-bold">{i18n.t('alert_overview.cameras')}: </span>
          {filteredCamera.slice(0, 4).map((item, index) => (
            <span
              key={`${index + 1}`}
              className="camera-name rounded-pill ph-no-capture"
            >
              {item ? item.name : i18n.t('td.camera_no_longer_exists')}
            </span>
          ))}

          {filteredCamera.length > 4 && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">
                  <span className="ph-no-capture">
                    {filteredCamera
                      .slice(4, filteredCamera.length - 1)
                      .map((item) => item.name)
                      .toString()
                      .replaceAll(',', ', ')}
                  </span>
                </Tooltip>
              }
            >
              <span className="show-more-cameras ph-no-capture">
                {filteredCamera.slice(4, filteredCamera.length - 1).length}{' '}
                {i18n.t('list.others')}
              </span>
            </OverlayTrigger>
          )}
        </p>
        <p>
          <span className="fw-bold">
            {i18n.t('alert_overview.scenarios')}:{' '}
          </span>
          {!filteredScenario[0] ? (
            <span>{i18n.t('td.scenario_not_found')}</span>
          ) : (
            <span
              className="scenario-list"
              style={{
                backgroundColor:
                  theme === 'light'
                    ? filteredScenario[0].colorLight
                    : filteredScenario[0].color,
                color: theme === 'light' ? filteredScenario[0].color : '#fff',
              }}
            >
              {i18n.t(filteredScenario[0].title)}
            </span>
          )}
        </p>
        <p>
          <span className="fw-bold">
            {i18n.t('alert_overview.triggered_on')}:{' '}
          </span>
          {moment.unix(date).format('DD MMM, HH:mm:ss')}
        </p>
      </div>
    </Col>
  );
};

AlertOverview.defaultProps = {};

export default AlertOverview;
