/* eslint-disable max-len */
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { CameraCanvasFilter } from './CameraCanvasFilter';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash-can.svg';
import { AuthContext } from '../../../../contextapi/AuthProvider';
import { CameraUpdateContext } from '../../../../contextapi/CameraUpdateProvider';
import { ThemeContext } from '../../../../contextapi/ThemeProvider';
import {
  DangerZoneResult,
  Perimeter,
} from '../../../../typescript/camera/dangerzone';
import { AuthContextType } from '../../../../typescript/context/auth';
import { CameraContextType } from '../../../../typescript/context/camera';
import { ThemeContextType } from '../../../../typescript/context/theme';
import { ScenarioProps } from '../../../../typescript/observation/scenario';
import i18n from '../../../../utils/i18n';
import { CustomButton } from '../../../elements/CustomButton';

interface Props {
  show: boolean;
  onButtonClick: () => void;
}

const DangerZoneModal: FC<Props> = function DangerZoneModal({
  show,
  onButtonClick,
}) {
  const { theme } = useContext(ThemeContext) as ThemeContextType;
  const { scenariosList } = useContext(AuthContext) as AuthContextType;
  const { camera, getCameraImage, onCameraUpdate, loading, setLoading } =
    useContext(CameraUpdateContext) as CameraContextType;

  const [onImageLoading, setOnImageLoading] = useState<boolean>(true);

  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioProps | null>(null);

  const imageRef = useRef<HTMLImageElement>(null);
  const [dangerZones, setDangerZones] = useState<Array<DangerZoneResult>>([]);
  const [removeZone, setRemoveZone] = useState<number | null>(null);

  const [loadOnce, setLoadOnce] = useState<boolean>(true);
  const [perimeterArray, setPerimeterArray] = useState<Array<Perimeter>>([]);
  //   const handleShow = () => setShow(true);

  function filteredArray() {
    const filter = scenariosList.map((arr1Item) => {
      const { title, value, description, isDangerZone, color, colorLight } =
        arr1Item;

      const isScenatio = camera.scenarios.includes(value);

      const updateValue = {
        title,
        value,
        description,
        isDangerZone,
        active: isScenatio,
        color,
        colorLight,
      };
      return updateValue;
    });

    return filter;
  }

  const updateAllZone = async () => {
    setRemoveZone(null);
    if (perimeterArray.length !== 0) {
      const arrayIndex = await perimeterArray.findIndex(
        (item) => item.scenario === selectedScenario?.value,
      );
      const zones = dangerZones.map((item) => item.zonePoints);
      const newArr = [...perimeterArray];
      if (newArr[arrayIndex]!) {
        newArr[arrayIndex].position = zones;
      }

      setPerimeterArray(newArr);
    }
  };

  useEffect(() => {
    const scenario = filteredArray().filter(
      (isScenario) => isScenario.isDangerZone && isScenario.active,
    )[0];
    const { perimeters } = camera;
    const isPerimeters = perimeters === '[]';
    if (!perimeters) {
      console.warn('perimeters null');
    }
    if (perimeterArray.length === 0 && scenario) {
      setPerimeterArray([{ scenario: scenario.value, position: [] }]);
      setSelectedScenario(scenario);
    } else {
      updateAllZone();
    }
    if (!isPerimeters && loadOnce) {
      const perimeter: Array<Perimeter> = JSON.parse(perimeters).filter(
        (item: Perimeter) => item.scenario === scenario.value,
      );
      if (perimeter.length === 0) {
        const result = [{ id: 0, completed: true, zonePoints: [] }];
        setDangerZones(result);
      } else {
        const result = perimeter[0].position.map((item: any, index: any) => ({
          id: index,
          completed: true,
          zonePoints: item,
        }));
        setDangerZones(result);
      }
      setPerimeterArray(JSON.parse(perimeters));
      setLoadOnce(false);

      // setPerimeterArray(JSON.parse(perimeters));
      // const result = JSON.parse(perimeters)[0].position.map((item: any, index: any) => (
      //   { id: index, completed: true, zonePoints: item }));
      // setDangerZones(result);
      // setLoadOnce(false);
    } else {
      updateAllZone();
    }
  }, [dangerZones]);

  const onScenarioClick = (onSelectedScenario: ScenarioProps) => {
    setSelectedScenario(onSelectedScenario);
    setRemoveZone(null);

    const isScenario = perimeterArray.filter(
      (perimeter) => perimeter.scenario === onSelectedScenario.value,
    );
    if (isScenario.length !== 0) {
      const zone = isScenario[0].position.map((item: any, index: any) => ({
        id: index,
        completed: true,
        zonePoints: item,
      }));
      setDangerZones(zone);
    } else {
      setPerimeterArray((oldArray) => [
        ...oldArray,
        { scenario: onSelectedScenario.value, position: [] },
      ]);
      setDangerZones([]);
    }
  };

  const onCameraUpdateDangerZone = async () => {
    const updateCamera = camera;
    const updatePositions = (items: Perimeter[]): Perimeter[] =>
      items.map((item) => ({
        ...item,
        position:
          item.position.length > 0 &&
          item.position.every((pos) => Array.isArray(pos) && pos.length === 0)
            ? []
            : item.position,
      }));

    updateCamera.perimeters = JSON.stringify(updatePositions(perimeterArray));
    setLoading(true);
    const update = await onCameraUpdate(updateCamera);
    if (update) {
      onButtonClick();
      setRemoveZone(null);
    }
    setLoading(false);
  };

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal observation-image danger-zone-modal"
      show={show}
      onHide={() => {
        setRemoveZone(null);
        onButtonClick();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {i18n.t('modal.camera.edit_dangerzone.title')}
        </Modal.Title>
        <CustomButton
          icon={CancelIcon}
          className="border-0"
          type="button"
          onClick={() => {
            setRemoveZone(null);
            onButtonClick();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h3>
              {selectedScenario !== null && i18n.t(selectedScenario.title)}
            </h3>
            <p>
              {selectedScenario !== null &&
                i18n.t(selectedScenario.description)}
            </p>
          </Col>
          <Col md={12} className="text-center canvas-container">
            <Image
              onLoad={() => setOnImageLoading(false)}
              onError={async () => setOnImageLoading(await getCameraImage())}
              width="100%"
              src={camera.imageUrl}
              ref={imageRef}
            />
            {imageRef.current && !onImageLoading && (
              <CameraCanvasFilter
                selectedScenario={selectedScenario!}
                canvasPerimeter={dangerZones}
                onClear={() => setRemoveZone(null)}
                removeSeletedZone={(selectedZoneId) =>
                  setRemoveZone(selectedZoneId)
                }
                removeZone={removeZone}
                canvasSize={{
                  width: imageRef.current?.width || 400,
                  height: imageRef.current?.height || 400,
                }}
                imageRef={imageRef}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="action-container">
          <div className="scenario-tag">
            <ul>
              {filteredArray()
                .filter(
                  (isScenario) => isScenario.isDangerZone && isScenario.active,
                )
                .map((scenario) => (
                  <li
                    className="add-cursor scenario-list ms-12 mb-12"
                    key={scenario.value}
                    aria-hidden
                    style={{
                      backgroundColor:
                        theme === 'light'
                          ? scenario.colorLight
                          : scenario.color,
                      color: theme === 'light' ? scenario.color : '#fff',
                      opacity:
                        selectedScenario?.value === scenario.value ? 1.0 : 0.5,
                    }}
                    onClick={() => onScenarioClick(scenario)}
                  >
                    {i18n.t(scenario.title)}
                  </li>
                ))}
            </ul>
          </div>

          <div className="action-div">
            {/* <CustomButton
            variant={`outline-${theme}`}
            type="button"
            icon={UndoIcon}
            title={i18n.t('button.undo')}
          /> */}

            <CustomButton
              variant={`outline-${theme}`}
              type="button"
              title={i18n.t('button.draw')}
              onClick={() => {
                setRemoveZone(null);
                setDangerZones((oldArray) => [
                  ...oldArray,
                  {
                    id: dangerZones.length,
                    completed: false,
                    zonePoints: [],
                  },
                ]);
              }}
            />

            <CustomButton
              variant={`outline-${theme}`}
              type="button"
              title={i18n.t('button.erase')}
              icon={DeleteIcon}
              disabled={removeZone === null}
              onClick={() => {
                setDangerZones(
                  dangerZones.filter((item) => item.id !== removeZone),
                );
                setRemoveZone(null);
              }}
            />
          </div>
        </div>

        <CustomButton
          title={i18n.t('button.back_to_settings')}
          variant={`outline-${theme}`}
          type="button"
          onClick={onButtonClick}
        />

        <CustomButton
          variant="primary"
          type="button"
          title={i18n.t('button.save_changes')}
          className="button-with-loader"
          disabled={loading}
          loading={loading}
          onClick={onCameraUpdateDangerZone}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DangerZoneModal;
