/* eslint-disable react/display-name */
import Konva from 'konva';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Circle, Group, Line } from 'react-konva';

export interface CanvasRef {
  getStartPoint(): void;
}

type DangerZoneResult = {
  id: number;
  completed: boolean;
  zonePoints: any;
};

interface Props {
  selectedZoneColor: string;
  dangerZones: DangerZoneResult;
  zoneSize: any;
  curMousePos: any;
  selectedZone: any;

  onDblTapGroup?: (id: number) => void;
  onDragEnd: (evt: Konva.KonvaEventObject<DragEvent>, index: number) => void;
}

const CameraMultipleCanvasFilter = forwardRef<CanvasRef, Props>(
  (
    {
      selectedZoneColor,
      dangerZones,
      zoneSize,
      curMousePos,
      onDblTapGroup,
      selectedZone,
      onDragEnd,
    }: Props,
    ref,
  ) => {
    // state
    const [points, setPoints] = useState([]);
    const [isMouseOverStartPoint, setIsMouseOverStartPoint] = useState(false);
    const [opacity, setOpacity] = useState(1);

    function getMultipliedOfPostions(partialValue: number, totalValue: number) {
      return partialValue * totalValue;
    }

    useEffect(() => {
      if (dangerZones) {
        setPoints(dangerZones.zonePoints);
      }
    }, [selectedZoneColor]);

    useEffect(() => {
      if (selectedZone === dangerZones.id) {
        const animation = setInterval(() => {
          setOpacity((prevOpacity) => {
            // Reset opacity to 1 if it reaches a threshold (e.g., 0.1)
            if (prevOpacity <= 0.1) {
              return 1;
            }
            return prevOpacity - 0.1;
          });
        }, 100);

        // Cleanup the animation interval
        return () => clearInterval(animation);
      }
      return () => false;
    }, [selectedZone]);

    useImperativeHandle(ref, () => ({
      getStartPoint() {
        return isMouseOverStartPoint;
      },
    }));

    // actions
    // const handleDragEndPoint = (event: Konva.KonvaEventObject<MouseEvent>) => {
    //   const index = event.target.index - 1;
    //   const { x, y } = event.target.attrs;
    //   const posX = checkXPointPostion(x);
    //   const posY = checkYPointPostion(y);

    //   const pos = [posX, posY];
    //   const newPoints: any = [...points];
    //   newPoints[index] = pos;
    //   setPoints(newPoints);
    // };

    const handleMouseOverStartPoint = (
      event: Konva.KonvaEventObject<MouseEvent>,
    ) => {
      if (dangerZones.completed || points.length < 3) return;
      event.target.scale({ x: 2, y: 2 });
      setIsMouseOverStartPoint(true);
    };

    const handleMouseOutStartPoint = (
      event: Konva.KonvaEventObject<MouseEvent>,
    ) => {
      event.target.scale({ x: 1, y: 1 });
      setIsMouseOverStartPoint(false);
    };

    const flattenedPoints = points
      .concat(dangerZones.completed ? [] : curMousePos)
      .reduce((a, b) => a.concat(b), []);

    const newArray = flattenedPoints.map((value, index) => {
      if (index % 2) {
        return getMultipliedOfPostions(value, zoneSize.canvasHeight);
      }
      return getMultipliedOfPostions(value, zoneSize.canvasWidth);
    });

    return (
      <Group
        onClick={(e) => {
          e.cancelBubble = true;
          onDblTapGroup!(dangerZones.id);
        }}
      >
        <Line
          points={newArray}
          fill={`${selectedZone === dangerZones.id ? '#29AD5950' : `${selectedZoneColor}50`}`}
          stroke={`${selectedZone === dangerZones.id ? '#29AD59' : `${selectedZoneColor}50`}`}
          strokeWidth={1}
          closed={dangerZones.completed}
        />
        {points.map((point) => {
          const width = 10;
          const x =
            getMultipliedOfPostions(point[0], zoneSize.canvasWidth) -
            width / 15;
          const y =
            getMultipliedOfPostions(point[1], zoneSize.canvasHeight) -
            width / 15;

          return (
            <Circle
              key={point}
              x={x}
              y={y}
              width={width}
              height={width}
              fill={`${selectedZone === dangerZones.id ? '#29AD5980' : `${selectedZoneColor}`}`}
              stroke={`${selectedZone === dangerZones.id ? '#29AD59' : `${selectedZoneColor}`}`}
              strokeWidth={1}
              draggable={
                selectedZone === dangerZones.id || !dangerZones.completed
              }
              onDragEnd={(event) => onDragEnd(event, dangerZones.id)}
              onMouseOver={handleMouseOverStartPoint}
              onMouseOut={handleMouseOutStartPoint}
              opacity={selectedZone === dangerZones.id ? opacity : 1}
            />
          );
        })}
      </Group>
    );
  },
);

CameraMultipleCanvasFilter.defaultProps = {
  onDblTapGroup: undefined,
};

export default CameraMultipleCanvasFilter;
